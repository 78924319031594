import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product, ProductStatus } from 'src/app/models/product.schema';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-product-status-multiple',
  templateUrl: './product-status-multiple.component.html',
  styleUrls: ['./product-status-multiple.component.scss']
})
export class ProductStatusMultipleComponent implements OnInit {

  products: Product[] = [];
  productStatus = ProductStatus;
  disableBtn: boolean = false;
  updatedStatusArray: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { productData: Product[] },
    private dialogRef: MatDialogRef<ProductStatusMultipleComponent>,
    private productService: ProductService,
    private _snackBar: MatSnackBar
  ) { 
    this.products = this.data.productData;
  }

  async ngOnInit() {
    try {
      this.updatedStatusArray = this.products.map((product: Product) => {
        return { id: product.id, productStatus: product.productStatus };
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  async changeProductStatus(productId: number, status: string){

    this.products = this.products.map((product: Product) => {
      if (product.id === productId) {
        product.productStatus = status === "1" ? 1 : 0;
      }
      return product;
    });

    this.updatedStatusArray = this.products.map((product: Product) => {
      return { id: product.id, productStatus: product.productStatus };
    });

  }

  async saveUpdatedInfo(){
    try {
      let promise = await this.productService.updateMultipleProductStatus(this.updatedStatusArray);
      if(promise === true){
        this.dialogRef.close({ status: true });
      } else {
        this.showSnackBar("Something went wrong");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  showSnackBar(message: string){
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}
