import { SpinnerService } from './../../services/spinner.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryService } from '../../services/delivery.service';
import { Delivery, DeliveryStatusAux, DeliveryStatus } from '../../models/delivery.schema';
import { AuthStatusService } from '../../services/auth-status.service';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryLinkDialogComponent } from '../delivery-link-dialog/delivery-link-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user.schema';
import { UserService } from 'src/app/services/user.service';
import { ServiceModel } from 'src/app/models/service.schems';
import { Service } from 'src/app/services/service.service';
import { ShowImageComponent } from '../show-image/show-image.component';
import { ExpertService } from 'src/app/services/expert.service';
import { Expert } from 'src/app/models/expert.schema';
import { DeliveryExpertFormComponent } from '../delivery-expert-form/delivery-expert-form.component';
import { PartialUpdateDeliveryComponent } from '../partial-update-delivery/partial-update-delivery.component';



@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {
  public user: any;
  public deliveries: Delivery[] = [];
  public groups: string[];
  public delivery: Delivery;
  public deliveryStatusAux = DeliveryStatusAux;
  public deliveryStatus = DeliveryStatus;
  public selected:any='';
  public serviceDetails:any;
  public services :ServiceModel[] = [];
  public loggedInExpert: Expert;
  public deliveryId: string;

  constructor(
    private _snackBar: MatSnackBar,
    private authStatusService: AuthStatusService,
    private route: ActivatedRoute,
    private router: Router,
    private deliveryService: DeliveryService,
    public dialog: MatDialog,
    private userService: UserService,
    private service: Service,
    private spinnerService: SpinnerService,
    private expertService: ExpertService,
  ) {
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {

    /* getting the current logged in user information */
    // this.user = await this.userService.getMyInformation();
    // this.spinnerService.show();
    try {
      this.user = await this.userService.getMyInformation();
      this.deliveryId = this.route.snapshot.paramMap.get('id');
      await this.getDelivery();
      this.services = await this.service.getAllServices();
      this.serviceDetailsFunction();
      // console.log('this.delivery', this.delivery)

      //if partner or expert, set readDelivery = true
      //DG
      // if (this.groups.includes('partner') && this.delivery['deluxyDelivery'] && (!this.delivery['readDelivery'])) 

      /* 
      Old code for delivery seen by 
      if ((this.groups.includes('partner') || this.groups.includes('expert')) &&  (!this.delivery['readDelivery'])) 
      {
          let deliveryReadBy = this.groups.includes('partner') ? 'partner' : 'expert';
          let promiseResult: any;
          promiseResult = this.deliveryService.update(this.delivery['id'], {
            id: this.delivery['id'],
            readDelivery: true,
            deliveryReadBy: deliveryReadBy
          });      
      }
      Old code for delivery seen by  
      */

      if(this.groups.includes('partner') && (this.delivery['deliveryReadByPartner']) == null ){
        let promiseResult: any;
        promiseResult = this.deliveryService.update(this.delivery['id'], {
          id: this.delivery['id'],
          readDelivery: true,
          deliveryReadByPartner: this.user.id
        });
      }

      if(this.groups.includes('expert') && (this.delivery['deliveryReadByExpert']) == null ){
        let promiseResult: any;
        promiseResult = this.deliveryService.update(this.delivery['id'], {
          id: this.delivery['id'],
          readDelivery: true,
          deliveryReadByExpert: this.user.id
        });
      }


    }
    catch {
      this.router.navigateByUrl("/404", { skipLocationChange: true });
    }
    // this.spinnerService.hide();
  }

  async getDelivery(){
    this.delivery = await this.deliveryService.getOne(this.deliveryId);
  }


  public groupWhitelisted(groups: string | string[]): boolean {
    if (!Array.isArray(groups))
      return this.groups.includes(groups);
    for (let group of groups)
      if (this.groups.includes(group))
        return true;
    return false;
  }

  print(event: Event) {
    event.preventDefault();
    window.print();
  }

  async showLink(event: Event) {
    event.preventDefault();
    if (!this.delivery.identifier)
      this.delivery = await this.deliveryService.generateIdentifier(this.delivery.id);
    this.dialog.open(DeliveryLinkDialogComponent, { width: '80vw', data: { delivery: this.delivery } });
  }

  async showDdt() {
    let res = await this.deliveryService.findOneDeliveryDdt(this.delivery.id);
    let anchor = document.createElement('a');
    anchor.download = this.delivery.ddtFile.replace(/-.{4}(\..{3,4})$/, '$1');
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this.delivery.address}`, '_blank');
  }

  public async accepting(id: number) {
    if (!confirm("Accetti la nuova consegna?"))
      return;
    return this.deliveryService.setDeliveryAccepting(id).then(data => {
      // Change delivery status
      //this.deliveries.find(delivery => delivery.id == data.id).status = DeliveryStatus.accepted;
      this.snack(`Consegna aggiornata con successo`);
      window.location.reload();
    }).catch(err => {
      //alert(err);
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
  }

  public async refusing(id: number) {
    if (!confirm("Rifiuti la nuova consegna?"))
      return;
    return  this.deliveryService.setDeliveryRefusing(id).then(data => {
      // Change delivery status
      //this.deliveries.find(delivery => delivery.id == data.id).status = DeliveryStatus.notAccepted;
      this.snack(`Consegna aggiornata con successo`);
      window.location.reload();
    }).catch(err => {
      //alert('');
      //alert(err);
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
  }  

  snack(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }
  
  delivering(arg){}

  serviceDetailsFunction(){
    this.serviceDetails = this.services.find((service) => service.id == this.delivery?.service);
  }

  showServiceDetails(serviceId: number){
    if(serviceId){
      const service = this.delivery?.partner?.partnerServices.find((item) => {
        return item?.service?.id == serviceId;
      });
      return service;
    }
  }

  showImage(product: any){
    let ref = this.dialog.open(ShowImageComponent, { panelClass: 'partner-time-dialog', width: '50vw', data: { product } });
    ref.afterClosed().subscribe(result => {});
  }

  public async expertModal(delivery?: Delivery) {
    if(this.groupWhitelisted(['admin', 'operation']) || (this.groups.includes('expert') && this?.user?.expert?.isTeamLeader) ){
      let ref = this.dialog.open(DeliveryExpertFormComponent, { width: '80vw', data: { delivery } });
      ref.afterClosed().subscribe(async (result) => {
        if (result?.delivery){
          let updatedDelivery = result?.delivery;
          await this.getDelivery();
          if(this.groups.includes('admin') || this.groups.includes('operation')){
            let partialUpdateModalRef = this.dialog.open(PartialUpdateDeliveryComponent, { width: '80vw', data: { updatedDelivery } });
            partialUpdateModalRef.afterClosed().subscribe(async (res) => {
              if (res?.delivery){
                await this.getDelivery();
                this.snack(`Price Updated`);
              }
              if (res?.delivery === null){
                this.snack(`Something went wrong`);
              }
            })
          }
        }
      });
    } else {
      return false;
    }
  }

}
