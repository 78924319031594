import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({
  providedIn: 'root'
})
export class ProvincesService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/provinces`;

  async getAllProvinces(): Promise<any>{
    return this.request(() => this.http.get(`${this.url}`).toPromise() );
  }

  async getAllProvinceCities(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
    return this.getAll(page, limit, order, filter);
  }
  
  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any>{
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get(`${this.url}/cities`, { params: params }).toPromise() );
  }

  async getProvinceCitiesById(provinceId: number) : Promise<any>{
    return this.request(() => this.http.get(`${this.url}/${provinceId}`).toPromise() );
  }

}
