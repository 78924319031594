import { Injectable } from '@angular/core';
import { AdminValetActivityService } from '../services/admin-valet-activity.service';
import { Delivery } from '../models/delivery.schema';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AdminValetActivityHelpherService {

  constructor(
    private adminValetActivityService: AdminValetActivityService,
    private _snackBar: MatSnackBar,
  ) {}

  async createOrUpdateActivities(delivery: Delivery){
    try {

      let filtered = {};
      const deliveryDate = new Date(delivery.deliveryDate);
      const normalizedDeliveryDate = deliveryDate.getFullYear() + '-' + ('0' + (deliveryDate.getMonth() + 1)).slice(-2) + '-' + ('0' + deliveryDate.getDate()).slice(-2);
      let maxOrder = 0;
      let data = await this.adminValetActivityService.findMaxOrder(delivery.expert.id, normalizedDeliveryDate);
      maxOrder = data.maxOrder;

      if(delivery.expert?.id){  
        if(delivery.serviceType.toLowerCase() === "sales" && delivery.ddtNumber){
          filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [normalizedDeliveryDate, normalizedDeliveryDate] };
          filtered['AdminValetActivity.expertId'] = Number(delivery.expert?.id);
          filtered['AdminValetActivity.ddtNumber'] = Number(delivery.ddtNumber);
          let activitiesWithSameDdtNumber = await this.adminValetActivityService.getAdminValetActivity(filtered);
          let activityArray: any[] = [];
          if(activitiesWithSameDdtNumber.length > 0){
            let deliveryActivities = activitiesWithSameDdtNumber.filter((activity) => activity.activityType.toLowerCase() === 'delivery');
            for(let deliveryActivity of deliveryActivities){
              await this.adminValetActivityService.deleteActivity(deliveryActivity.id);
            }
            activityArray = await this.buildActivityArray(delivery, maxOrder);
          } else {
            activityArray = await this.buildActivityArray(delivery, maxOrder);
          }
          await this.adminValetActivityService.create(activityArray);
        }

        filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [normalizedDeliveryDate, normalizedDeliveryDate] };
        filtered['AdminValetActivity.expertId'] = Number(delivery.expert?.id);
        filtered['Delivery.id'] = Number(delivery.id);
        let activitiesWithDeliveryId = await this.adminValetActivityService.getAdminValetActivity(filtered);

        if(!activitiesWithDeliveryId.length) {
          let activityArray: any[] = await this.buildActivityArray(delivery, maxOrder);
          await this.adminValetActivityService.create(activityArray);
        }

        return;

        delete filtered['Delivery.id'];
        let activities = await this.adminValetActivityService.getAdminValetActivity(filtered);
        console.log("From createOrUpdateActivities function", activities);
        // return
        let newActivityArray = [];

        if (activities.length) {
          activities.forEach((activity: any) => {
            if (activity.activityType.toLowerCase() === "pickup") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery?.pickUpTime.split('-')[0]
              });
            }
            if (activity.activityType.toLowerCase() === "delivery") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery?.fromTime
              });
            }
            if (activity.activityType.toLowerCase() === "hourlyservice") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery.startTime ? activity.delivery.startTime : activity.delivery.fromTime ? activity.delivery.fromTime : ''
              });
            }
          });
        }

        newActivityArray.sort((a, b) => {
          const timeA = a.timing.split(':').map(Number); // Convert "HH:MM" to [HH, MM]
          const timeB = b.timing.split(':').map(Number); // Convert "HH:MM" to [HH, MM]
          // Compare hours first, then minutes
          return timeA[0] - timeB[0] || timeA[1] - timeB[1];
        });
        // console.log("Before set order", newActivityArray);
        newActivityArray.forEach((activity, i) => {
          activity.order = i + 1;
        });
        // console.log("After set order", newActivityArray);
        const resultArray = newActivityArray.map(activity => ({
          id: activity.id,
          order: activity.order
        }));
        
        await this.adminValetActivityService.updateActivityOrder(resultArray);
      }
    } catch (error) {
      this.showSnackBar("Something went wrong");
      console.log(error);
    }
  }

  async createOrUpdateActivitiesNewFunction(previousDelivery: Delivery, delivery: Delivery){
    try {
      let filtered = {};
      const deliveryDate = new Date(delivery.deliveryDate);
      const normalizedDeliveryDate = deliveryDate.getFullYear() + '-' + ('0' + (deliveryDate.getMonth() + 1)).slice(-2) + '-' + ('0' + deliveryDate.getDate()).slice(-2);
      const expertChanged = delivery?.expert?.id !== previousDelivery?.expert?.id;
      const expertAssigned = delivery?.expert?.id && (previousDelivery?.expert?.id == null || delivery?.expert.id === previousDelivery?.expert?.id);
      let maxOrder = 0;
      let data = await this.adminValetActivityService.findMaxOrder(delivery?.expert?.id, normalizedDeliveryDate);
      maxOrder = data.maxOrder;

      if(expertAssigned){  
        console.log("Expert Assignment");
        if(delivery.serviceType.toLowerCase() === "sales" && delivery.ddtNumber){
          filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [normalizedDeliveryDate, normalizedDeliveryDate] };
          filtered['AdminValetActivity.expertId'] = Number(delivery.expert?.id);
          filtered['AdminValetActivity.ddtNumber'] = Number(delivery.ddtNumber);
          let activitiesWithSameDdtNumber = await this.adminValetActivityService.getAdminValetActivity(filtered);
          let activityArray: any[] = [];
          if(activitiesWithSameDdtNumber.length > 0){
            let deliveryActivities = activitiesWithSameDdtNumber.filter((activity) => activity.activityType.toLowerCase() === 'delivery');
            for(let deliveryActivity of deliveryActivities){
              await this.adminValetActivityService.deleteActivity(deliveryActivity.id);
            }
            activityArray = await this.buildActivityArray(delivery, maxOrder);
          } else {
            activityArray = await this.buildActivityArray(delivery, maxOrder);
          }
          await this.adminValetActivityService.create(activityArray);
        }

        filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [normalizedDeliveryDate, normalizedDeliveryDate] };
        filtered['AdminValetActivity.expertId'] = Number(delivery.expert?.id);
        filtered['Delivery.id'] = Number(delivery.id);
        let activitiesWithDeliveryId = await this.adminValetActivityService.getAdminValetActivity(filtered);

        if(!activitiesWithDeliveryId.length) {
          let activityArray: any[] = await this.buildActivityArray(delivery, maxOrder);
          await this.adminValetActivityService.create(activityArray);
        }
        return;

        delete filtered['Delivery.id'];
        let activities = await this.adminValetActivityService.getAdminValetActivity(filtered);
        console.log("From createOrUpdateActivitiesNewFunction function", activities);
        let newActivityArray = [];
        // return

        if (activities.length) {
          activities.forEach((activity: any) => {
            if (activity.activityType.toLowerCase() === "pickup") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery?.pickUpTime.split('-')[0]
              });
            }
            if (activity.activityType.toLowerCase() === "delivery") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery?.fromTime
              });
            }
            if (activity.activityType.toLowerCase() === "hourlyservice") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery.startTime ? activity.delivery.startTime : activity.delivery.fromTime ? activity.delivery.fromTime : ''
              });
            }
          });
        }

        newActivityArray.sort((a, b) => {
          const timeA = a.timing.split(':').map(Number); 
          const timeB = b.timing.split(':').map(Number); 
          return timeA[0] - timeB[0] || timeA[1] - timeB[1];
        });
        newActivityArray.forEach((activity, i) => {
          activity.order = i + 1;
        });
        const resultArray = newActivityArray.map(activity => ({
          id: activity.id,
          order: activity.order
        }));
        
        await this.adminValetActivityService.updateActivityOrder(resultArray);
      } else if(expertChanged) {
        console.log("EXPERT HAS BEEN CHANGED");
        filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [normalizedDeliveryDate, normalizedDeliveryDate] };
        filtered['AdminValetActivity.expertId'] = Number(previousDelivery.expert?.id);
        filtered['Delivery.id'] = Number(delivery.id);
        await this.adminValetActivityService.deleteAssignedActivities(filtered);

        let maxOrder = 0;
        let data = await this.adminValetActivityService.findMaxOrder(delivery.expert.id, normalizedDeliveryDate);
        maxOrder = data.maxOrder;

        filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [normalizedDeliveryDate, normalizedDeliveryDate] };
        filtered['AdminValetActivity.expertId'] = Number(delivery.expert?.id);
        filtered['Delivery.id'] = Number(delivery.id);
        let activitiesWithDeliveryId = await this.adminValetActivityService.getAdminValetActivity(filtered);

        if(!activitiesWithDeliveryId.length) {
          let activityArray: any[] = await this.buildActivityArray(delivery, maxOrder);
          await this.adminValetActivityService.create(activityArray);
        }

        return;

        delete filtered['Delivery.id'];
        let activities = await this.adminValetActivityService.getAdminValetActivity(filtered);
        let newActivityArray = [];

        if (activities.length) {
          activities.forEach((activity: any) => {
            if (activity.activityType.toLowerCase() === "pickup") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery?.pickUpTime.split('-')[0]
              });
            }
            if (activity.activityType.toLowerCase() === "delivery") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery?.fromTime
              });
            }
            if (activity.activityType.toLowerCase() === "hourlyservice") {
              newActivityArray.push({
                ...activity,
                timing: activity.delivery.startTime ? activity.delivery.startTime : activity.delivery.fromTime ? activity.delivery.fromTime : ''
              });
            }
          });
        }

        newActivityArray.sort((a, b) => {
          const timeA = a.timing.split(':').map(Number); 
          const timeB = b.timing.split(':').map(Number); 
          return timeA[0] - timeB[0] || timeA[1] - timeB[1];
        });
        newActivityArray.forEach((activity, i) => {
          activity.order = i + 1;
        });
        const resultArray = newActivityArray.map(activity => ({
          id: activity.id,
          order: activity.order
        }));
        
        await this.adminValetActivityService.updateActivityOrder(resultArray);
      }
    } catch (error) {
      this.showSnackBar("Something went wrong");
      console.log(error);
    }
  }

  async buildActivityArray(delivery, order: number){
    let activityArray: any[] = [];
    if (delivery.serviceType.toLowerCase() === "sales" || delivery.serviceType.toLowerCase() === "fixedprice") {
      if (delivery.startTime || delivery.fromTime) {
        activityArray.push({
          "expert": delivery.expert.id,
          "activityType": "pickup",
          "delivery": delivery.id,
          "deliveryDate": delivery.deliveryDate,
          "ddtNumber": delivery.ddtNumber,
          "order": order + 1,
          "status": delivery.status,
        });
      }
      if (delivery.pickUpTime) {
        activityArray.push({
          "expert": delivery.expert.id,
          "activityType": "delivery",
          "delivery": delivery.id,
          "deliveryDate": delivery.deliveryDate,
          "ddtNumber": delivery.ddtNumber,
          "order": order + 2,
          "status": delivery.status,
        });
      }
    }

    if (delivery.serviceType.toLowerCase() === "hourlyrate") {
      activityArray.push({
        "expert": delivery.expert.id,
        "activityType": "hourlyservice",
        "delivery": delivery.id,
        "deliveryDate": delivery.deliveryDate,
        "ddtNumber": delivery.ddtNumber,
        "order": order + 1,
        "status": delivery.status,
      });
    }

    return activityArray;
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }
}
