import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Partner, PartnerProvinces, PartnerServices } from 'src/app/models/partner.schema';
import { ServiceModel } from 'src/app/models/service.schems';
import { User } from 'src/app/models/user.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { PartnerService } from 'src/app/services/partner.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { ProvincesService } from 'src/app/services/provinces.service';
import { Service } from 'src/app/services/service.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-duplicate-partner',
  templateUrl: './duplicate-partner.component.html',
  styleUrls: ['./duplicate-partner.component.scss']
})

export class DuplicatePartnerComponent implements OnInit {
  @Input('profile') public profile: boolean = false;
  public readonly formValidationMsgs;
  public _partnerForm: any;
  public partner: Partner;
  private checkedVatCode: boolean;
  private checkedFiscalCode: boolean;
  public tmpBtnDisabled: boolean;

  private checkedPassword1: boolean;
  private checkedPassword2: boolean;
  showExtraKmInput: boolean[] = [];
  showSalesTypeLabel: boolean[] = [];
  public sendSms: boolean = false;
  public groups: string[];
  public services: ServiceModel[] = [];
  public partnerId: string|number = '';
  provinces: any[] = [];
  productCategories: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private partnerService: PartnerService,
    private userService: UserService,
    private authStatusService: AuthStatusService,
    private service: Service,
    private provincesService: ProvincesService,
    private productCategoryService: ProductCategoryService,
    private spinnerService: SpinnerService,
  ) {
    this.formValidationMsgs = Partner.validationMessages;
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    // this.spinnerService.show();
    try {
      this.services = await this.service.getAllServices();
      this.productCategories = await this.productCategoryService.getAllProductCategories();
      if(this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader')){
        this.provinces = await this.provincesService.getAllProvinces();
      }
      await this.initialize();
    }
    catch { }
    // this.spinnerService.hide();
  }

  async initialize() {
    this._partnerForm = new FormGroup(Partner.validation(this.profile));
    const partnerId = this.profile ?
      (await this.partnerService.getOne(this.authStatusService.getExtra()['id'])).id.toString() :
      this.route.snapshot.paramMap.get('id');
    this.partnerId = partnerId;
    if (partnerId) {
      try {
        this.partner = await this.partnerService.getOne(partnerId);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (!this.partner) {
      this.partner = new Partner();
      this.partner.user = this.route.snapshot.paramMap.get('userId') ?
        await this.userService.getOne(this.route.snapshot.paramMap.get('userId')) :
        new User();
    }
    this.patch();
    if(this.partnerId && this.partner.partnerServices.length > 0){
      this.patchPartnerServices();
    }
    if(this.partnerId && this.partner?.partnerProvinces.length > 0){
      this.patchPartnerProvinces();
    }
    this.setCustomValidators();
    if (this.profile)
      this.setProfileValidators();
  }

  setCustomValidators() {
    this._partnerForm.controls.fiscalCode.valueChanges.subscribe((next: any) => {
      if (!this.checkedFiscalCode) {
        this.checkedFiscalCode = true;
        this._partnerForm.controls.vatCode.updateValueAndValidity();
      }
      else
        this.checkedFiscalCode = false;
    });

    this._partnerForm.controls.vatCode.valueChanges.subscribe((next: any) => {
      if (!this.checkedVatCode) {
        this.checkedVatCode = true;
        this._partnerForm.controls.fiscalCode.updateValueAndValidity();
      }
      else
        this.checkedVatCode = false;
    });
  }

  patch() {
    ['businessName', 'vatCode', 'fiscalCode', 'phone', 'address', 'notes', 'longitude', 'latitude', 'sendSms', 'kmIncluded', 'receiveEmailMsg', 'receiveWhatsappMsg'].forEach(param => {
      this._partnerForm.controls[param].setValue(this.partner[param]);
    });
    ['surname', 'name', 'email'].forEach(param => {
      this._partnerForm.controls.user.controls[param].setValue(this.partner.user[param]);
    });
    if(this.partner?.partnerSoldProductCategories?.length > 0){
      this._partnerForm.patchValue({
        partnerSoldProductCategories: this.partner?.partnerSoldProductCategories.map((data) => {
          return data.productCategory.id
        })
      });
    }
  }

  patchPartnerServices(){
    const control = <FormArray>this._partnerForm.get('partnerServices');
    control.clear();
    if (!this.partner.partnerServices || Array.isArray(this.partner.partnerServices) && this.partner.partnerServices.length == 0){
      this.partner.partnerServices = [new PartnerServices()];
    }
    this.partner.partnerServices.forEach((f, index) => {
      control.push(this.patchValues(f, index));
    });
  }

  patchValues(partnerServices: PartnerServices, index: number) {
    this.showExtraKmInput[index] = partnerServices?.service?.pricingModel.toLowerCase() === 'fixedprice';
    let serviceForm = Partner.serviceFormGroup();
    serviceForm.controls.service.setValue(
      { 
        id: partnerServices?.service?.id, 
        price: partnerServices?.price,
        extraKmPrice: partnerServices?.extraKmPrice 
      }
    );
        
    return serviceForm;
  }

  patchPartnerProvinces(){
    const control = <FormArray>this._partnerForm.get('partnerProvinces');
    control.clear();
    if (!this.partner.partnerProvinces || Array.isArray(this.partner.partnerProvinces) && this.partner.partnerProvinces.length == 0){
      this.partner.partnerProvinces = [new PartnerProvinces()];
    }
    this.partner.partnerProvinces.forEach((f, index) => {
      control.push(this.patchPartnerProvinceValues(f, index));
    });
  }

  patchPartnerProvinceValues(partnerProvinces: PartnerProvinces, index: number) {
    let provinceForm = Partner.provinceFormGroup();
    provinceForm.controls.province.setValue(
      { 
        id: partnerProvinces?.province?.id, 
      }
    );
        
    return provinceForm;
  }

  setProfileValidators() {
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this._partnerForm.controls.user.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this._partnerForm.controls.user.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this._partnerForm.controls.user.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this._partnerForm.controls.user.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  getAddress(place: any) {
    if (!this._partnerForm) return;
    this._partnerForm.controls.address.touched = true;
    this._partnerForm.controls.address.setValue(place.formatted_address || place.name || '');
    this._partnerForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this._partnerForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this._partnerForm.controls.address.value}`, '_blank');
  }

  get partnerSoldProductCategories(): FormArray {
    return this._partnerForm.get('partnerSoldProductCategories') as FormArray;
  }

  onDuplicate(){
    this.tmpBtnDisabled = true;
    let partner: any = (new FormGroupToObject()).transform(this._partnerForm);

    const selectedSoldProductCategories = this.partnerSoldProductCategories.value.map((id: number) => {
      const soldProductCategory = this.productCategories.find(p => p.id === id);
      return { productCategory: { id } };
    });
    partner.partnerSoldProductCategories = selectedSoldProductCategories;
    if(this.groups.includes('partner')){
      delete partner?.partnerSoldProductCategories;
      delete partner?.partnerProvinces
      delete partner?.partnerServices
    }
    if(this.groups.includes('admin') || this.groups.includes('operation')){
      if(partner?.partnerProvinces[0]?.province?.id === null || partner?.partnerProvinces[0]?.province?.id === undefined){
        delete partner.partnerProvinces;
      }
      if(partner?.partnerServices[0]?.service?.id === null || partner?.partnerServices[0]?.service?.id === undefined){
        delete partner.partnerServices;
      }
      if(partner?.partnerSoldProductCategories[0]?.productCategory?.id === null || partner?.partnerSoldProductCategories[0]?.productCategory?.id === undefined){
        delete partner.partnerSoldProductCategories;
      }
    }

    let promiseResult: any;
    promiseResult = this.partnerService.create(partner);
    promiseResult.then(async(data: Partner) => {
      this.partner = data;
      const message: string = `Partner duplicata con successo. Nuovo id creato : '${this.partner.id}'`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      }).afterDismissed().subscribe(result => {
        this.authStatusService.isAuthenticated().then(valid => {
          if (!valid)
            this.router.navigate(['/']);
        });
        this.router.navigate(['/partner', this.partner.id, 'edit']);
        this.patch();
        this.tmpBtnDisabled = false;
      })
    }).catch((err: any) => {
      this._snackBar.open(`Errore Something went wrong`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      this.tmpBtnDisabled = false;
      console.log(err);
    });
  }

  addService() {
    const control = <FormArray>this._partnerForm.get('partnerServices');
    control.push(Partner.serviceFormGroup());
  }

  addProvince() {
    const control = <FormArray>this._partnerForm.get('partnerProvinces');
    control.push(Partner.provinceFormGroup());
  }

  isOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const services of this._partnerForm.value.partnerServices) {
      if (services.service.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId; // assuming your item has a 'disabled' property
  }

  isProvinceOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const provinces of this._partnerForm.value.partnerProvinces) {
      if (provinces.province.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId;
  }
  
  removeService(index: number) {
    const control = <FormArray>this._partnerForm.get('partnerServices');
    control.removeAt(index);
  }

  removeProvince(index: number) {
    const control = <FormArray>this._partnerForm.get('partnerProvinces');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
  }

  handleServiceChange(event: any, index: number) {
    const selectedService = this.services.find(service => service.id == event);
    this.showExtraKmInput[index] = selectedService.pricingModel.toLowerCase() === 'fixedprice';
    this.showSalesTypeLabel[index] = selectedService.pricingModel.toLowerCase() === 'sales';
  }
  
}
