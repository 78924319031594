import { Component, OnInit } from '@angular/core';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '../../services/product.service';
import { Product, ProductPartner } from '../../models/product.schema';
import { PartnerService } from '../../services/partner.service';
import { ExpertService } from '../../services/expert.service';
import { AuthStatusService } from '../../services/auth-status.service';
import { plainToClass } from 'class-transformer';
import { ProductCategory } from 'src/app/models/product-category.schema';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {
  public groups: string[];
  public partners: any=[];
  public readonly formValidationMsgs;
  public _productForm: any;
  public product: Product;
  public removeFile: boolean;
  public user_extra: {};
  public tmpBtnDisabled: boolean;
  productCategories: ProductCategory[] = [];
  imagePath: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private productService: ProductService,
    private partnerService: PartnerService,
    private productCategoryService: ProductCategoryService,
    private spinnerService: SpinnerService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.formValidationMsgs = Product.validationMessages;
    this.user_extra = this.authStatusService.getExtra();
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
      if(this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader')){
        let partners = await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' });
        this.partners = partners.partners;
        // console.log("ALL ACTIVE PARTNERS",this.partners);
      }
      try {
        this.productCategories = await this.productCategoryService.getAllProductCategories();
      } catch (error) {}
      await this.initialize();
      this.spinnerService.hide();
    }
    catch {
      this.groups = [];
    }
  }

  async initialize() {
    this.tmpBtnDisabled = false;
    this._productForm = new FormGroup(Product.validation());
    const productId: string = this.route.snapshot.paramMap.get('id');
    if (productId) {
      try {
        this.product = await this.productService.getOne(Number(productId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    // if(this.groups.includes('partner')){
    //   this._productForm.controls['productCategory'].setValidators([]);
    //   this._productForm.controls['productCategory'].updateValueAndValidity();
    // }
    if (this.product)
      this.patch();
    else
      this.product = new Product();
  }

  async delete() {
    if (!confirm("Sei sicuro di voler cancellare questo prodotto?"))
      return;
    let product: { id: number | string, success: boolean } = await this.productService.delete(this.product.id);
    let message = "Cancellazione del prodotto fallita.";
    if (product.success)
      message = "Cancellazione del prodotto avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    }).afterDismissed().subscribe(result => {
      if (product.success)
        this.router.navigate(['/prodotti']);
    });
  }

  patch() {
    this.removeFile = false;
    ['name', 'description', 'price', 'sku','quantity', 'unEditable', 'uniqueProduct', 'visibleToOtherPartners'].forEach(param => {
      this._productForm.controls[param].setValue(this.product[param]);
    });

    if (this.product['partner'])
      this._productForm.controls.partner.setValue(this.product['partner'].id);
    if (this.product.category)
      this._productForm.controls.category.setValue(this.product.category.join('; '));
    if (this.product.stock)
      this._productForm.controls.stock.setValue(this.product.stock.join('; '));
    if (this.product.line)
      this._productForm.controls.line.setValue(this.product.line.join('; '));
    if (this.product.productCategory)
      this._productForm.controls.productCategory.setValue(this.product?.productCategory?.id);
    if(this.product.image){
      this.imagePath = this.product.image;
    }
    if(this.product.productPartners.length > 0){
      this.patchProductsPartner();
    }
  } 

  patchProductsPartner(){
    const control = <FormArray>this._productForm.get('productPartners');
    control.clear();
    if (!this.product.productPartners || Array.isArray(this.product.productPartners) && this.product.productPartners.length == 0){
      this.product.productPartners = [new ProductPartner()];
    }
    this.product.productPartners.forEach((f, index) => {
      control.push(this.patchValues(f, index));
    });
  }

  patchValues(productPartners: ProductPartner, index: number) {
    let productPartner = Product.productPartnerFormGroup();
    productPartner.controls.partner.setValue(
      { 
        id: productPartners?.partner?.id, 
      }
    );
        
    return productPartner;
  }

  onSubmit() {
    try {
      this.tmpBtnDisabled = true;
      let product: Product = new FormGroupToObject().transform(this._productForm);
      let image = product.image;
      if(this.groups.includes('partner')){
        product['partner'] = this.user_extra['id'];
        
      }
      if (!this.removeFile) delete product.image;
      delete product.image;

      product = plainToClass(Product, product);
      let promiseResult: any;
      let createType: boolean = true;
      // delete product.image;
      if(!this._productForm.get('partner').value){
        delete product.partner
      }

      if(this.groups.includes('partner')){
        product.partner = this.user_extra['id'];
        delete product?.productPartners;
      }
  
      // console.log("product", product);
      // return
      if(this.groups.includes('admin') || this.groups.includes('operation')){
        if(product?.productPartners[0]?.partner?.id === null || product?.productPartners[0]?.partner?.id === undefined || product?.productPartners[0]?.partner?.id === ''){
          delete product?.productPartners;
        }
      }

      if (this.product['id']) {
        promiseResult = this.productService.update(this.product['id'], product);
        createType = false;
      }
      else
        promiseResult = this.productService.create(product);
      promiseResult
      .then(async (data: Product) => {
        let allGood = true;
        this.product = await this.productService.getOne(data?.id);
        if (image){
          this.product = await this.uploadImage(this.product.id).catch((err) => {
            this.errorMessage(createType, err);
            allGood = false;
          });
        } if(this.removeFile) {
          this.product = await this.productService.updateProductPhoto(this.product.id, null).catch((err) => {
            this.errorMessage(createType, err);
            allGood = false;
          });
        }
        if (allGood) {
          const message: string = `Prodotto ${createType ? 'creato' : 'aggiornato'} con successo`;
          this._snackBar.open(message, 'Chiudi', {
            direction: 'ltr',
            duration: 2000,
            horizontalPosition: 'center',
            politeness: 'assertive',
            verticalPosition: 'top',
          }).afterDismissed().subscribe(result => {
            if (createType)
              this.router.navigate(['/prodotti', this.product.id]);
          });
          this.tmpBtnDisabled = false;
          this.patch();
        }
      })
      .catch((error) => {
        this.tmpBtnDisabled = false;
        this.errorMessage(createType, error);
      });
    } catch (error) {
      console.log("ERROR", error?.message)
    }
  }

  errorMessage(createType, error) {
    let message = `Errore ${createType ? 'nella creazione' : "nell'aggiornamento"} del prodotto`;
    if (error.error && error.error.message === 'DUPLICATE.PRODUCT')
      message = `Errore: esiste già un prodotto con questo nome!`;
    if (error.error && error.error.message === 'DUPLICATE.PRODUCT_SKU')
      message = `Errore: esiste già un prodotto con questo sku!`;
    if(error.error && error.error.message === 'PRODUCT.ONLY_EDITABLE_ADMINISTRATION_SIDE'){
      message = `PRODOTTO SOLO LATO AMMINISTRAZIONE MODIFICABILE`
    }
    this._snackBar.open(message,
      'Chiudi',
      {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }
    );
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.removeFile = false;
      const file = event.target.files[0];
      this._productForm.get('image').setValue(file);
    }
  }

  uploadImage(productId: number) {
    return this.productService.updateProductPhoto(
      productId,
      this._productForm.get('image').value
    );
  }

  deleteFile() {
    this.removeFile = true;
  }

  openImage(imageUrl: string) {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }

  addPartner() {
    const control = <FormArray>this._productForm.get('productPartners');
    control.push(Product.productPartnerFormGroup());
  }

  removePartner(index: number) {
    const control = <FormArray>this._productForm.get('productPartners');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
  }

  isPartnerOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const partners of this._productForm.value.productPartners) {
      if (partners.partner.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId;
  }

}
