import { Component, Inject, OnInit } from '@angular/core';
import { CommanModalComponent } from '../comman-modal/comman-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-start-end-time-edit',
  templateUrl: './start-end-time-edit.component.html',
  styleUrls: ['./start-end-time-edit.component.scss']
})
export class StartEndTimeEditComponent implements OnInit {

  delivery: any;
  startEndTimeForm: any;
  btnDisabled: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CommanModalComponent>,
    private _snackBar: MatSnackBar,
    private deliveryService: DeliveryService,
  ) { 
    this.delivery = data.delivery;
    this.manageStartEndTimeForm();
  }

  async ngOnInit() {
    try {
      this.patch();
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  manageStartEndTimeForm(){
    this.startEndTimeForm = new FormGroup({
      'startTime': new FormControl(''),
      'endTime': new FormControl(''),
    });
  }

  patch(){
    this.startEndTimeForm.patchValue({
      startTime: this.delivery.startTime,
      endTime: this.delivery.endTime,
    });
  }

  async onSubmit(){
    try {
      let promiseResult = await this.deliveryService.updateStartTimings(this.delivery.id, this.startEndTimeForm.value);
      if(promiseResult){
        this.dialogRef.close({
          success: true
        });
      }
    } catch (error) {
      console.log("ERROR", error);
      this.showSnackBar('Something went wrong');
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}
