import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Expert } from '../models/expert.schema';
import { ToTypeOrm } from '../models/_to-type-orm.schema';
import { Vehicle } from '../models/vehicle.schema';

@Injectable({ providedIn: 'root' })
export class ExpertService extends ApiAuthService {
    private readonly url = `${this.baseUrl}/experts`;

    async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ experts: Expert[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ experts: Expert[], count: number }>(`${this.url}`, { params: params }).toPromise());
    }

    async getAllWithoutTimeAva(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ experts: Expert[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ experts: Expert[], count: number }>(`${this.url}/without-time-ava`, { params: params }).toPromise());
    }

    async getAllActiveExperts(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ experts: Expert[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ experts: Expert[], count: number }>(`${this.url}/active-experts`, { params: params }).toPromise());
    }

    async getAllActiveExpertsWithTimeAvailabilities(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ experts: Expert[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ experts: Expert[], count: number }>(`${this.url}/active-experts-with-time-ava`, { params: params }).toPromise());
    }

    async getOne(id: string|number): Promise<Expert> {
        return this.request(() => this.http.get<Expert>(`${this.url}/${id}`).toPromise());
    }

    async getOneWithTimeAvailability(id: string|number): Promise<Expert> {
        return this.request(() => this.http.get<Expert>(`${this.url}/${id}/with-time-ava`).toPromise());
    }

    async create(expertParams: Expert): Promise<Expert> {
        let body = expertParams;
        return this.request(() => this.http.post<Expert>(`${this.url}`, body).toPromise());
    }

    async update(id: number, expertParams: Expert): Promise<Expert> {
        let body = expertParams;
        return this.request(() => this.http.put<Expert>(`${this.url}/${id}`, body).toPromise());
    }

    async delete(id: number): Promise<{ id: number, success: boolean }> {
        return this.request(() => this.http.delete<{ id: number, success: boolean }>(`${this.url}/${id}`).toPromise());
    }

    async getAllVehicles(): Promise<Vehicle[]> {
        return this.request(() => this.http.get<Vehicle[]>(`${this.url}/vehicles`).toPromise());
    }

    async updateMe(expertParams: Expert): Promise<Expert> {
        let body = expertParams;
        return this.request(() => this.http.put<Expert>(`${this.url}`, body).toPromise());
    }


    async setTimeAvailability(formData: any): Promise<any>{
        return this.request(() => this.http.post(`${this.url}/time/availability`, formData).toPromise());
    }

    async setTimeAvailabilityInBulk(formData: any): Promise<any>{
        return this.request(() => this.http.post(`${this.url}/time/availability/bulkadd`, formData).toPromise());
    }

    async getExpertAvailabilities(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
        return this.getAllAvailabilities(page, limit, order, filter);
    }

    async getAllAvailabilities(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any>{
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get(`${this.url}/availability/list`, { params: params }).toPromise());
    }

    async getTimeAvailavialityWithinDateRange(object: any, expertId: string|number){
        return this.request(() => this.http.get(`${this.url}/time-ava-date-range/${expertId}`, { params: object }).toPromise());
    }

    async UpdateTimeAvailabilityInBulk(formData: any): Promise<any>{
        return this.request(() => this.http.post(`${this.url}/time/availability/bulkUpdate`, formData).toPromise());
    }
}
