import { AbstractControl, FormControl, Validators, FormGroup } from '@angular/forms';
import { User } from './user.schema';
import { phoneValid } from '../helpers/validators/phone.validator';
import { Transform } from 'class-transformer';

export class Customer {
    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            phone: new FormControl('', Validators.compose([Validators.required, phoneValid()])),
            address: new FormControl('', Validators.required),
            longitude: new FormControl(''),
            latitude: new FormControl(''),
            notes: new FormControl(''),
            dob: new FormControl('', Validators.required),
            intercom: new FormControl('', Validators.required),
        };
        validator['user'] = profile ?
            new FormGroup({ ...User.specificValidation(), ...User.editLoginValidation() }) :
            new FormGroup(User.specificValidation());
        return validator;
    }

    public static readonly validationMessages = {
        'phone': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }
        ],
        'address': [{ type: 'required', message: 'Obbligatorio' }],
        'dob': [{ type: 'required', message: 'Obbligatorio' }],
        'intercom': [{ type: 'required', message: 'Obbligatorio' }],
        'user': User.validationMessages
    };

    constructor(
        public id?: number,
        public phone?: string,
        public address?: string,
        public longitude?: string,
        public latitude?: string,
        public notes?: string,
        public user?: User,
        dob?: Date,
        public intercom?: string,
        public partner?: any,
    ) { 
        this.dob = dob;
    }
    @Transform(toDate, { toClassOnly: true }) public dob?: Date;
}

export function toDate({ value, key, obj, type }) {
    return value ? new Date(value) : undefined;
}