import { Component, OnInit } from '@angular/core';
import { DeluxyFlowersSaleFormDialogComponent } from './../deluxy-flowers-sale-form-dialog/deluxy-flowers-sale-form-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AllSalesService } from 'src/app/services/all-sales.service';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { BusinessSaleService } from 'src/app/services/business-sale.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ShopifySaleFormDialogComponent } from '../shopify-sale-form-dialog/shopify-sale-form-dialog.component';
import { CakeSaleFormDialogComponent } from '../cake-sale-form-dialog/cake-sale-form-dialog.component';
import { BusinessSaleFormDialogComponent } from '../business-sale-form-dialog/business-sale-form-dialog.component';
import { ShopifySaleService } from 'src/app/services/shopify-sale.service';
import { CakeSaleService } from 'src/app/services/cake-sale.service';
import { FlowersSaleService } from 'src/app/services/flowers-sale.service';
import { CakeOrderService } from 'src/app/services/cake-order.service';
import { AiCakeOrderInfoModalComponent } from '../ai-cake-order-info-modal/ai-cake-order-info-modal.component';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-all-sales',
  templateUrl: './all-sales.component.html',
  styleUrls: ['./all-sales.component.scss']
})

export class AllSalesComponent implements OnInit {

  limit: number;
  page: number;
  count: number;
  maxPage: number;
  filtered: {};
  orders: string[];
  allSaleForm: any;
  order: {};
  myjson:any=JSON;
  groups: any;
  selected: string = 'active';
  settings = {
    active: {
      title: "All Vendita",
    },
    inactive: {
      title: "Storico All Vendita",
    }
  };
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'actualOrderId': 'DESC' };
  btnDisable: boolean = false;
  cakeOrders: any[] = [];

  constructor(
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private deliveryService: DeliveryService,
    private allSalesService: AllSalesService,
    private shopifySaleService: ShopifySaleService,
    private businessSaleService: BusinessSaleService,
    private cakeSaleService: CakeSaleService,
    private flowersSaleService: FlowersSaleService,
    private cakeOrderService: CakeOrderService,
    private spinnerService: SpinnerService,
  ) {
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    } catch (error) {
      this.groups = [];
    }
  }
  
  async ngOnInit() { 
    try {
      this.spinnerService.show();
      this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
      this.cakeOrders = await this.cakeOrderService.getAll();
      this.manageAllSalesFilterForm();
      await this.filter();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong on this page");
    }
  }

  manageAllSalesFilterForm() {
    this.allSaleForm = new FormGroup({
      'orderId': new FormControl(''),
      'platform': new FormControl(''),
      'actualOrderId': new FormControl(''),
      'firstName': new FormControl(''), 
      'deliveryDate_from': new FormControl(''), 
      'deliveryDate_to': new FormControl(''),   
      'lastName': new FormControl(''),  
      'channel': new FormControl(''),
      'total': new FormControl(''),
      'paymentStatus': new FormControl(''),
      'fullFillmentStatus': new FormControl(''),
      'deliveryMethod': new FormControl(''),
      'address': new FormControl(''),
      'pickUpTime': new FormControl(''),
      'phone': new FormControl(''),
      'deliveryDate': new FormControl(''),
      'vendor': new FormControl(''),
      'status': new FormControl(''),
    });
  }
  
  async filter() {
    let filterForm = (new FormGroupToObject()).transform(this.allSaleForm);
    this.filtered = {};
    if (filterForm['orderId'])
      this.filtered['orderId'] = filterForm['orderId'];    
    if (filterForm['actualOrderId'])
      this.filtered['actualOrderId'] = filterForm['actualOrderId'];    
    if (filterForm['firstName'])
      this.filtered['firstName'] = { operator: 'like', values: [`%${filterForm['firstName']}%`] };
    if (filterForm['channel'])
      this.filtered['channel'] = { operator: 'like', values: [`%${filterForm['channel']}%`] };
    if (filterForm['total'])
      this.filtered['total'] = { operator: 'like', values: [`%${filterForm['total']}%`] };
    if (filterForm['paymentStatus'])
      this.filtered['paymentStatus'] = { operator: 'like', values: [`%${filterForm['paymentStatus']}%`] };
    if (filterForm['fullFillmentStatus'])
      this.filtered['fullFillmentStatus'] = { operator: 'like', values: [`%${filterForm['fullFillmentStatus']}%`] };
    if (filterForm['deliveryMethod'])
      this.filtered['deliveryMethod'] = { operator: 'like', values: [`%${filterForm['deliveryMethod']}%`] };
    if (filterForm['address'])
      this.filtered['address'] = { operator: 'like', values: [`%${filterForm['address']}%`] };
    if (filterForm['pickUpTime'])
      this.filtered['pickUpTime'] = { operator: 'like', values: [`%${filterForm['pickUpTime']}%`] };
    if (filterForm['phone'])
      this.filtered['phone'] = { operator: 'like', values: [`%${filterForm['phone']}%`] };
    if (filterForm['deliveryDate_from'])
      this.filtered['deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['deliveryDate_from']] };
    if (filterForm['deliveryDate_to'])
      this.filtered['deliveryDate'] = { operator: 'lessThan', values: [filterForm['deliveryDate_to']] };
    if (filterForm['deliveryDate_from'] && filterForm['deliveryDate_to'])
      this.filtered['deliveryDate'] = { operator: 'between', values: [filterForm['deliveryDate_from'], filterForm['deliveryDate_to']] };
    if (filterForm['vendor'])
      this.filtered['vendor'] = { operator: 'like', values: [`%${filterForm['vendor']}%`] };
    if (filterForm['status'])
      this.filtered['status'] = { operator: 'like', values: [`%${filterForm['status']}%`] };
    if (filterForm['platform'])
      this.filtered['platform'] = { operator: 'like', values: [`%${filterForm['platform']}%`] };
  
    await this.getAllSalesOrders();
  }

  async getAllSalesOrders(limit?: number, page?: number, order?: {}){

    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let promiseResult = null;
    promiseResult = this.selected == 'active' ? 
      this.allSalesService.getAllSalesOrders(this.page, this.limit, this.order, this.filtered) :
      this.allSalesService.getAllSalesOrdersHistory(this.page, this.limit, this.order, this.filtered);
    promiseResult.then((res) => {
      this.orders = res.allSales;
      this.count = res.saleCount;
      this.maxPage = Math.ceil(this.count / this.limit);
    }).catch((err) => {
      console.log("err", err)
    })
  }

  async confirmSale(saleOrder: any, platform: string) {
    if(this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader')){
      let delivery = await this.deliveryService.getDeliveryWithOrderId(saleOrder?.orderId, saleOrder?.realOrderNumber);
      if(delivery){
        this._snackBar.open(`This is unique product sale. Delivery has been created. Partner is still accpet or reject the sale`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
        return false;
      }

      let ref: any;
      if(platform === 'cakesales'){
        ref = this.dialog.open(CakeSaleFormDialogComponent, { width: '80vw', data: { saleOrder } });
      }
      if(platform === 'flowerssales'){
        ref = this.dialog.open(DeluxyFlowersSaleFormDialogComponent, { width: '80vw', data: { saleOrder } });
      }
      if(platform === 'shopifysale'){
        ref = this.dialog.open(ShopifySaleFormDialogComponent, { width: '80vw', data: { saleOrder } });
      }
      if(platform === 'businesssales'){
        ref = this.dialog.open(BusinessSaleFormDialogComponent, { width: '80vw', data: { saleOrder } });
      }
      
      ref.afterClosed().subscribe(result => {
        if (result?.delivery){
          this.getAllSalesOrders();
        }
      });
    } else {
      return false;
    }
  }

  async refuseSale(id: number, platform: string){
    if(!confirm(`Sei sicuro di voler rifiutare l'ordine di Shopify ${platform?.toUpperCase()}?`))
      return false;

    let promiseResult: any;
    if(platform === 'cakesales'){
      promiseResult = await this.cakeSaleService.refuseSaleByAdmin(id);
    }
    if(platform === 'flowerssales'){
      promiseResult = await this.flowersSaleService.refuseSaleByAdmin(id);
    }
    if(platform === 'shopifysale'){
      promiseResult = await this.shopifySaleService.refuseSaleByAdmin(id);
    }
    if(platform === 'businesssales'){
      promiseResult = await this.businessSaleService.refuseSaleByAdmin(id);
    }

    if(promiseResult.success){
      this._snackBar.open(`L'ordine di Shopify è stato rifiutato. Puoi controllare nella cronologia`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      await this.getAllSalesOrders();
    } else {
      this._snackBar.open(`Qualcosa è andato storto`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }
  }

  async resetFilter() {
    await this.manageAllSalesFilterForm();
    await this.filter();
  }

  redirect(id: number, platform: string): any[] {
    let route: any;
    if(platform === 'cakesales'){
      route = ['/cakes/vendita'];
    }
    if(platform === 'flowerssales'){
      route = ['/flowers/vendita'];
    }
    if(platform === 'shopifysale'){
      route = ['/vendita'];
    }
    if(platform === 'businesssales'){
      route = ['/business/vendita'];
    }
    return route;
  }

  getRouterLink(order: any): string[] {
    const platform = order?.platform?.toLowerCase();
    switch (platform) {
      case 'businesssales':
        return ['/business/vendita/edit', order.id];
      case 'shopifysale':
        return ['/vendita/edit', order.id];
      case 'flowerssales':
        return ['/flowers/vendita/edit', order.id];
      case 'cakesales':
        return ['/cakes/vendita/edit', order.id];
      default:
        return ['/vendita']; // Default route if no match is found
    }
  }

  async stopSaleAutomation(sale: any){
    if(!confirm(`Are You Sure to Stop Sale Automation ${sale?.platform?.toLowerCase()}?`))
      return false;
    try {
      this.btnDisable = true;
      let id: number = sale?.id;
      let salePlatform = sale?.platform?.toLowerCase();
      let promiseResult: any;

      if(salePlatform === 'cakesales'){
        promiseResult = await this.cakeSaleService.stopSaleAutomation(id, sale);
      }
      if(salePlatform === 'flowerssales'){
        promiseResult = await this.flowersSaleService.stopSaleAutomation(id, sale);
      }
      if(salePlatform === 'shopifysale'){
        promiseResult = await this.shopifySaleService.stopSaleAutomation(id, sale);
      }
      if(salePlatform === 'businesssales'){
        promiseResult = await this.businessSaleService.stopSaleAutomation(id, sale);
      }

      if(promiseResult.success){
        this._snackBar.open(`Sale Automation has been Stopped.`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
      } else {
        this._snackBar.open(`Something Went Wrong`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
      }
      await this.getAllSalesOrders();
    } catch (error) {
      this.btnDisable = false;
      this._snackBar.open(`Something Went Wrong ${error?.error?.message}`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }
  }

  async showAiInfo(order: any){
    const lineItems = JSON.parse(order?.lineItems || '[]');
    lineItems.some((item: any) =>
      item.properties?.some((property: any) =>
        property.name.toLowerCase() === 'ai_cake_image_url' && property.value
          ? this.dialog.open(AiCakeOrderInfoModalComponent, { width: '80vw', data: { order } })
          : false
      )
    );
  }

  
  showBtnAiBtn(order: any): boolean {
    return this.cakeOrders.some((cakeOrder) => {
      return cakeOrder.realOrderNumber === order?.realOrderNumber && cakeOrder.orderId === order?.orderId;
    });
  }

  async navigate(event) {
    await this.getAllSalesOrders(event.limit, event.page, this.order);
  }

  public async navigateOrder(attribute: string | string[]) {
    delete this.order['BusinessSales.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    await this.navigatePage(this.limit, this.page, { order: this.order });
  }

  async navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(async (data) => {
      await this.getAllSalesOrders();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  showSnackBar(message: string){
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async removeDeliveryForUniqueSale(order: any){
    try {
      let data = {
        "id": order.id,
        "shop": order.platform
      }

      let promise = this.deliveryService.removeDeliveryForUniqueSale(data);
      promise.then(async (res: any) => {
        // if(res){
          await this.getAllSalesOrders();
          this.showSnackBar("Sale has been canceled. Now admin can handle this sale");
        // }
      }).catch(async (err: any) => {
        this.showSnackBar("Something went wrong");
      })
    } catch (error) {
      console.log("Error", error?.message);
    }
  }

}