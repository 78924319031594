import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-notes',
  templateUrl: './show-notes.component.html',
  styleUrls: ['./show-notes.component.scss']
})
export class ShowNotesComponent implements OnInit {

  delivery: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.delivery = data.delivery;
  }

  async ngOnInit() {

  }

}
