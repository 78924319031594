import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({
  providedIn: 'root'
})
export class AdminValetActivityService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/admin-valet-activity`;

  async getAdminValetActivity(filter: any = {}): Promise<any> {
    return this.getAll(filter);
  }
  
  async getAll(filter?: { [key: string]: ToTypeOrm }): Promise<any>{
    let params = {};
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get(`${this.url}`, { params: params }).toPromise() );
  }

  async getAllWithDeliveryDateOrder(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }) {
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    // return this.request(() => this.http.get<{ deliveries: Delivery[], count: number }>(`${this.url}`, { params: params }).toPromise());
    return this.request(() => this.http.get(`${this.url}`, { params: params }).toPromise() );
  }

  async create(partnerParams: any): Promise<any> {
    let body = partnerParams;
    return this.request(() => this.http.post(`${this.url}`, body).toPromise());
  }

  async updateActivityOrder(reorderedActivities: any): Promise<any> {
    let body = reorderedActivities;
    return this.request(() => this.http.put(`${this.url}`, body).toPromise());
  }

  async updateValetActivity(id: number, bodyParams?: any): Promise<any> {
    return this.request(() => this.http.put(`${this.url}/${id}/status`, bodyParams).toPromise());
  }
  async deleteAssignedActivities(filter: any = {}): Promise<any> {
    let params = {};
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.delete(`${this.url}`, { params: params }).toPromise() );
  }

  async deleteActivity(id: number): Promise<any>{
    return this.request(() => this.http.delete(`${this.url}/${id}`).toPromise() );
  }

  async findMaxOrder(expertId: number, date: string): Promise<any>{
    return this.request(() => this.http.get(`${this.url}/max/order/${expertId}/${date}`).toPromise() );
  }

}
