import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Partner } from '../models/partner.schema';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({ providedIn: 'root' })
export class PartnerService extends ApiAuthService {
    private readonly url = `${this.baseUrl}/partners`;

    async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ partners: Partner[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ partners: Partner[], count: number }>(`${this.url}`, { params: params }).toPromise());
    }

    async getAllWithoutTimeAva(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ partners: Partner[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ partners: Partner[], count: number }>(`${this.url}/without-time-ava`, { params: params }).toPromise());
    }

    async getAllActivePartners(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ partners: Partner[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ partners: Partner[], count: number }>(`${this.url}/active-partners`, { params: params }).toPromise());
    }

    async getAllActivePartnersWithTimeAvailabilities(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ partners: Partner[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ partners: Partner[], count: number }>(`${this.url}/active-partners-with-time-ava`, { params: params }).toPromise());
    }

    async getOne(id: string | number): Promise<Partner> {
        return this.request(() => this.http.get<Partner>(`${this.url}/${id}`).toPromise());
    }

    async getOneWithTimeAvailability(id: string | number): Promise<Partner> {
        return this.request(() => this.http.get<Partner>(`${this.url}/${id}/with-time-ava`).toPromise());
    }

    async create(partnerParams: Partner): Promise<Partner> {
        let body = partnerParams;
        return this.request(() => this.http.post<Partner>(`${this.url}`, body).toPromise());
    }

    async update(id: number, partnerParams: Partner): Promise<Partner> {
        let body = partnerParams;
        return this.request(() => this.http.put<Partner>(`${this.url}/${id}`, body).toPromise());
    }

    async delete(id: number): Promise<{ id: number, success: boolean }> {
        return this.request(() => this.http.delete<{ id: number, success: boolean }>(`${this.url}/${id}`).toPromise());
    }

    async updateMe(partnerParams: Partner): Promise<Partner> {
        let body = partnerParams;
        return this.request(() => this.http.put<Partner>(`${this.url}`, body).toPromise());
    }

    async setTimeAvailability(formData: any): Promise<any>{
        return this.request(() => this.http.post(`${this.url}/time/availability`, formData).toPromise());
    }

    async setTimeAvailabilityInBulk(formData: any): Promise<any>{
        return this.request(() => this.http.post(`${this.url}/time/availability/bulkadd`, formData).toPromise());
    }
    
    async getPartnerAvailabilities(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
        return this.getAllAvailabilities(page, limit, order, filter);
    }

    async getAllAvailabilities(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any>{
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get(`${this.url}/availability/list`, { params: params }).toPromise());
    }

    async getAllActivePartnersByProvinceId(provincedId: number|string): Promise<any>{
        return this.request(() => this.http.get(`${this.url}/byprovince/${provincedId}`).toPromise());
    }

    async getTimeAvailavialityWithinDateRange(object: any, partnerId: string|number){
        return this.request(() => this.http.get(`${this.url}/time-ava-date-range/${partnerId}`, { params: object }).toPromise());
    }
}
