import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({
  providedIn: 'root'
})
export class ProvinceCitiesService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/province-cities`;

  async createOrUpdateProvinceCityRecord(formValues: any): Promise<any>{
    return this.request(() => this.http.post(`${this.url}`, formValues ).toPromise() );
  }

  importProvinceCities(provinceCities: any): Promise<any>{
    return this.request(() =>
      this.http.post<any>(`${this.url}/import`, provinceCities).toPromise()
    );
  }
  
  /*
  async getAllProvinceCities(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
    return this.getAll(page, limit, order, filter);
  }
  
  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any>{
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get(`${this.url}`, { params: params }).toPromise() );
  }

  async updateProvinceCityRecord(provinceCityId: number, formValues: any): Promise<any>{
    return this.request(() => this.http.put(`${this.url}/${provinceCityId}`, formValues ).toPromise() );
  }

  async getProvinceCityRecord(provinceCityId: number): Promise<any>{
    return this.request(() => this.http.get(`${this.url}/${provinceCityId}` ).toPromise() );
  }

  async deleteProvinceCityRecord(provinceCityId: number): Promise<any>{
    return this.request(() => this.http.delete(`${this.url}/${provinceCityId}` ).toPromise() );
  }
  */

}
