import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Transform } from 'class-transformer';
import { fileType } from 'src/app/helpers/validators/file-type.validator';
import { Partner } from './partner.schema';

export const ProductStatus = [
    { value: '1', ln: { it: 'Attivo' } },
    { value: '0', ln: { it: 'Disattivo' } }
]
export class Product {
    public static validation(): { [key: string]: AbstractControl } {
        let numberPattern = /^[0-9]+(\.[0-9]*)?$/;
        let validator: { [key: string]: AbstractControl } = {
            name: new FormControl('', Validators.required),
            sku: new FormControl(''),
            partner: new FormControl(''),
            description: new FormControl(''),
            category: new FormControl(''),
            stock: new FormControl(''),
            line: new FormControl(''),
            price: new FormControl('', Validators.compose([Validators.min(0)])),
            notes: new FormControl(''),
            quantity: new FormControl('', Validators.pattern(numberPattern)),
            image: new FormControl(
                undefined,
                fileType(['png', 'jpg', 'gif', 'jpeg'])
            ),
            unEditable: new FormControl(false),
            productCategory: new FormControl('', Validators.required),
            uniqueProduct: new FormControl(''),
            visibleToOtherPartners: new FormControl(false),
            productPartners: new FormArray([new FormGroup(ProductPartner.validation())]),
        };
        return validator;
    }

    public static readonly validationMessages = {
        name: [{ type: 'required', message: 'Obbligatorio' }],
        price: [{ type: 'min', message: 'Deve essere positivo' }],
        image: [
            { type: 'fileType', message: 'Formati accettati: png, jpg, gif, jpeg' },
        ],
        quantity: [{ type: 'pattern', message: 'La quantità può essere solo numero e float' }],
        productCategory: [{ type: 'required', message: 'Obbligatorio' }]
    };

    public static productPartnerFormGroup() {
        return new FormGroup(ProductPartner.validation());
    }

    constructor(
        public id?: number,
        public name?: string,
        public sku?: string,
        public description?: string,
        price?: number,
        category?: string[],
        line?: string[],
        stock?: string[],
        public image?: any,
        public partner?: any,
        public updatedAt?: Date,
        public createdAt?: Date,
        public quantity?:string,
        public unEditable?: boolean,
        public productCategory?: any,
        public uniqueProduct?: boolean,
        public productStatus?: number,
        public productPartners?: any,
    ) {
        this.price = price;
        this.category = category;
        this.line = line;
        this.stock = stock;

    }

    @Transform(toNumber, { toClassOnly: true }) public price?: number;
    @Transform(toArray, { toClassOnly: true }) public category?: string[];
    @Transform(toArray, { toClassOnly: true }) public line?: string[];
    @Transform(toArray, { toClassOnly: true }) public stock?: string[];
}

export function toArray({ value, key, obj, type }) {
    return value ? value.split(';').map(el => el.trim()) : [];
}

function toNumber({ value, key, obj, type }) {
    if ([undefined, null, ''].includes(value))
        return null;
    return Number(value);
}

export class ProductPartner {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            partner: this.productFormGroup(),
        };
        return validator;
    }

    public static productFormGroup() {
        return new FormGroup({
            id: new FormControl(''),
        });
    }

    constructor(
        public id?: number,
        public partner?: Partner,
    ) { }
}
