import { Component, OnInit, ViewChild } from '@angular/core';
import { User, UserActiveStatus } from '../../models/user.schema';
import { PaginationComponent } from '../_pagination/pagination.component';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Group } from '../../models/group.schema';
import { AuthStatusService } from '../../services/auth-status.service';
import { GroupService } from '../../services/group.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public users: User[];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  public mineId: number;
  public groups: Group[];
  public readonly activeStatus = UserActiveStatus;
  public _userFilterForm;
  public filtered = {};

  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private authStatusService: AuthStatusService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) {
    this.mineId = this.authStatusService.getUserId();
  }

  async ngOnInit() {
    this._userFilterForm = new FormGroup({
      'User.surname': new FormControl(''),
      'User.name': new FormControl(''),
      'User.email': new FormControl(this.route.snapshot.queryParamMap.get('email') || ''),
      'Group.id': new FormControl(''),
      'User.active': new FormControl(''),
    });
    this.groups = (await this.groupService.getAll()).groups;
    this.groups = this.groups.filter(group => group.name !== 'teamleader');
    // console.log("this.groups", this.groups)
    if (this.route.snapshot.queryParamMap.get('email'))
      this.filter();
    else
      this.loadUsers();
  }

  async filter() {
    let filterForm = (new FormGroupToObject()).transform(this._userFilterForm);
    this.filtered = {};
    if (filterForm['User.surname'])
      this.filtered['User.surname'] = { operator: 'like', values: [`%${filterForm['User.surname']}%`] };
    if (filterForm['User.name'])
      this.filtered['User.name'] = { operator: 'like', values: [`%${filterForm['User.name']}%`] };
    if (filterForm['User.email'])
      this.filtered['User.email'] = { operator: 'like', values: [`%${filterForm['User.email']}%`] };
    if (filterForm['Group.id'])
      this.filtered['Group.id'] = filterForm['Group.id'];
    if (filterForm['User.active'])
      this.filtered['User.active'] = filterForm['User.active'];
    this.loadUsers();
  }

  navigate(event) {
    this.loadUsers(event.limit, event.page, this.order);
  }

  async loadUsers(limit?: number, page?: number, order?: {}) {
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || 1;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || 200;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || { 'User.surname': 'ASC', 'User.name': 'ASC' };
    let users = await this.userService.getAll(this.page, this.limit, this.order, this.filtered);
    this.users = users.users;
    this.count = users.count;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  public orderIcon(attribute: string | string[]): string {
    if (!this.order)
      return 'fa-sort';
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order[attr] = 'ASC';
          break;
      }
    }
    this.paginationComponent.navigate(this.limit, this.page, { order: this.order });
  }

  public async changeGroup(user: User, groupId: number) {
    if (user.group && user.group.id == groupId)
      return;
    let group = this.groups.find(g => g.id == groupId);
    switch (group.name.toLowerCase()) {
      case 'expert':
        this.router.navigate(['/expert', 'nuovo', user.id]);
        break;
      case 'partner':
        this.router.navigate(['/partner', 'nuovo', user.id]);
        break;
      case 'teamleader':
        this.router.navigate(['/team/leader', 'nuovo', user.id]);
        break;
      case 'operation':
        this.router.navigate(['/operation', 'nuovo', user.id]);
        break;
      default:
        let promiseResult = this.userService.changeGroup(user.id, groupId);
        promiseResult.then((data: User) => {
          this.users[this.users.findIndex(u => u.id == user.id)] = data;
          const message: string = `Gruppo dell'utente aggiornato con successo`;
          this._snackBar.open(message, 'Chiudi', {
            direction: "ltr",
            duration: 2000,
            horizontalPosition: "center",
            politeness: "assertive",
            verticalPosition: "top"
          });
        }).catch(error => {
          this._snackBar.open("Errore nell'aggiornamento del gruppo dell'utente", 'Chiudi', {
            direction: "ltr",
            duration: 2000,
            horizontalPosition: "center",
            politeness: "assertive",
            verticalPosition: "top"
          });
          console.log(error);
        });
        break;
    }
  }

  public async changeStatus(userId: number, status: number) {
    let promiseResult = this.userService.changeStatus(userId, status);
    promiseResult.then((data: User) => {
      this.users[this.users.findIndex(u => u.id == userId)] = data;
      const message: string = `Stato dell'utente aggiornato con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }).catch(error => {
      this._snackBar.open("Errore nell'aggiornamento dello stato dell'utente", 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      console.log(error);
    });
  }

  translateActive(status) {
    return UserActiveStatus.find(as => as.value == status).ln.it;
  }

  public async delete(userId: number){
    console.log('Id', userId)
    if (!confirm("Sei sicuro di voler cancellare questo utent?"))
      return;
    let deleteResult: { id: number | string, success: boolean } = await this.userService.delete(userId);
    let message = "Cancellazione dell'utent fallita.";
    if (deleteResult.success) {
      this.users = this.users.filter(user => user.id != deleteResult.id);
      message = "Cancellazione dell'utent avvenuta con successo.";
    }
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }
}
