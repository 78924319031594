import { Component, OnInit } from '@angular/core';
import { Expert } from 'src/app/models/expert.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ExpertService } from 'src/app/services/expert.service';

@Component({
  selector: 'app-actvities',
  templateUrl: './actvities.component.html',
  styleUrls: ['./actvities.component.scss']
})
export class ActvitiesComponent implements OnInit {

  groups: any;
  currentExpert: Expert;
  
  constructor(
    private authStatusService: AuthStatusService,
    private expertService: ExpertService,
  ) { 
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
  }

  async ngOnInit() {
    const loggedInUserInfo = await this.authStatusService.getTokenInfo();
    if(this.groups.includes('expert')){
      this.currentExpert = await this.expertService.getOne(loggedInUserInfo['extraId']);
    }
  }


}
