import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'saleName'
})
export class SaleNamePipe implements PipeTransform {

  transform(orderId: string | undefined, shop: string | undefined): string {
    if (!orderId || !shop) return '---';

    switch (shop.toLowerCase()) {
      case 'businesssales':
        return `B${orderId}`;
      case 'shopifysale':
        return `D${orderId}`;
      case 'flowerssales':
        return `F${orderId}`;
      case 'cakesales':
        return `C${orderId}`;
      default:
        return '---';
    }
  }

}
