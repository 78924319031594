import { SpinnerService } from './../../services/spinner.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { Delivery, DeliveryStatus } from '../../models/delivery.schema';
import { DeliveryService } from '../../services/delivery.service';
import { Expert, ExpertProvinces, ExpertTeamLeaderProvinces } from '../../models/expert.schema';
import { ExpertService } from '../../services/expert.service';
import { DeliveryObserverService } from '../../services/observers/delivery-observer.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { TeamLeaderService } from 'src/app/services/team-leader.service';
import { AdminValetActivityService } from 'src/app/services/admin-valet-activity.service';
import { PartnerDeliveryRulesService } from 'src/app/services/partner-delivery-rules.service';
import { AdminValetActivityHelpherService } from 'src/app/helpers/admin-valet-activity-helpher.service';

@Component({
  selector: 'app-delivery-expert-form',
  templateUrl: './delivery-expert-form.component.html',
  styleUrls: ['./delivery-expert-form.component.scss']
})
export class DeliveryExpertFormComponent implements OnInit {
  public _deliveryForm: any;
  public delivery: Delivery;
  public experts: Expert[];
  disableBtn: boolean = false;
  selectedService: any;
  selectedServiceId: any;
  selectedExpert: Expert;
  public groups: any;
  teamLeader: TeamLeader;
  loggedInExpert: Expert;
  isTeamLeader: boolean = false;
  oldDelivery: Delivery;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { delivery: Delivery },
    private deliveryService: DeliveryService,
    private expertService: ExpertService,
    public deliveryObserverService: DeliveryObserverService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<DeliveryExpertFormComponent>,
    private authStatusService: AuthStatusService,
    public jwtHelper: JwtHelperService,
    public teamLeaderService: TeamLeaderService,
    private spinnerService: SpinnerService,
    private adminValetActivityService: AdminValetActivityService,
    private partnerDeliveryRulesService: PartnerDeliveryRulesService,
    private adminValetActivityHelpherService: AdminValetActivityHelpherService,
  ) {
    this.delivery = this.data.delivery;
    this.oldDelivery = JSON.parse(JSON.stringify(this.delivery));
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    } catch (error) {
      
    }
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      const loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('teamleader') && loggedInUserInfo){
        this.teamLeader = await this.teamLeaderService.getOne(loggedInUserInfo['extraId']);
        this.isTeamLeader = true;
      }
      
      if(this.groups.includes('expert') && loggedInUserInfo){
        this.loggedInExpert = await this.expertService.getOne(loggedInUserInfo['extraId']);
        this.isTeamLeader = this.loggedInExpert?.isTeamLeader;
      }
      
      this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
      if(this.groups.includes('teamleader') && this.teamLeader ){
        if (this.teamLeader?.teamLeaderProvinces.length > 0) {
          this.experts = this.experts?.filter((expert) => {
            return expert?.expertProvinces.some((eProvince) => {
              return this.teamLeader.teamLeaderProvinces.some((teamLeaderProvince: TeamLeaderProvinces) => {
                return eProvince.province.provinceCode.toLowerCase() === teamLeaderProvince.province.provinceCode.toLowerCase()
              });
            });
          });
        }
      }
      if(this.groups.includes('expert') && this.loggedInExpert.isTeamLeader && this.loggedInExpert){
        if (this.loggedInExpert.expertTeamLeaderProvinces.length > 0) {
          this.experts = this.experts.filter((expert: Expert) => {
            if(expert.expertProvinces.length > 0){
              return expert.expertProvinces.some((eProvince: ExpertProvinces) => {
                if(eProvince.province != null){
                  return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
                    return eProvince.province.provinceCode.toLowerCase() === expertTeamLeaderProvince.province.provinceCode.toLowerCase()
                  });
                }
              });
            }
          });
        } else {
          this.experts = [];
        }
      }
    }
    catch (error){ 
      console.log("Error", error)
    }
    this.initialize();
    this.spinnerService.hide();
  }

  async initialize() {
    // console.log("this.experts", this.experts)
    this._deliveryForm = new FormGroup(Delivery.validation());
    if (this.delivery && this.delivery.expert){
      this.selectedExpert = this.experts.find(expert => expert.id == this.delivery.expert.id);
      this.selectedService = this.selectedExpert?.expertServices.find(expertService => expertService?.id == this.delivery.expertServiceId );
      this._deliveryForm.controls.expert.controls.id.setValue(this.selectedExpert?.id);
      this._deliveryForm.controls.expertServiceId.setValue(this.selectedService?.id);
      this.selectedServiceId = this.selectedService?.id;
    }
  }

  async onSubmit() {
    if(!this._deliveryForm.controls.expertServiceId.value || this._deliveryForm.controls.expertServiceId.value === undefined || this._deliveryForm.controls.expertServiceId.value === null || this._deliveryForm.controls.expertServiceId.value === ''){
      alert("Qualcosa è andato storto! Impossibile assegnare l'ID del servizio"); // Something went wrong! Unable to assign service ID
      return;
    }
    this.disableBtn = true;
    this.delivery.expert = this.experts.find(expert => expert.id == this._deliveryForm.controls.expert.controls.id.value);
    let promiseResult: any;
    /* Set Valet Salary According to Delivery Rule Id If Delivery is Created with Rule Id*/
    if(this.delivery['id'] && this.delivery['deliveryRuleId'] && (this.delivery['withDailyDeliveryRule'] || this.delivery['withTotalDeliveryRule'])){
      let partnerDeliveryRule = await this.partnerDeliveryRulesService.getOne(Number(this.delivery['deliveryRuleId']));
      if(!partnerDeliveryRule.payable){
        this._deliveryForm.controls.expertSalary.value = 0;
      }
    }
    /* Set Valet Salary According to Delivery Rule Id If Delivery is Created with Rule Id*/
    promiseResult = this.deliveryService.update(this.delivery['id'], {
      id: this.delivery.id,
      expert: { id: this.delivery.expert.id },
      status: DeliveryStatus.assigned,
      expertSalary: this._deliveryForm.controls.expertSalary.value,
      expertServiceId: this._deliveryForm.controls.expertServiceId.value,
      valetStartTime: this.delivery.valetStartTime,
      valetEndTime: this.delivery.valetEndTime,
      // payable: this.isTeamLeader ? false : this.delivery.payable
    });
    promiseResult.then(async (data: Delivery) => {
      await this.adminValetActivityHelpherService.createOrUpdateActivitiesNewFunction(this.oldDelivery, data);
      this.disableBtn = false;
      this.delivery = data;
      const message: string = `Expert assegnato con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      this.dialogRef.close({ delivery: this.delivery });
    }).catch(error => {
      this.disableBtn = false;
      this._snackBar.open("Errore nell'assegnazione dell'expert", 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      console.log(error);
    });
  }

  onServiceChange(valetServiceId: number, expert: Expert){
    this._deliveryForm.controls.expert.controls.id.setValue(expert.id);
    this.selectedServiceId = valetServiceId;
    this.selectedExpert = this.experts.find(expert => expert.id == this._deliveryForm.controls.expert.controls.id.value);
    this.selectedService = this.selectedExpert?.expertServices.find(expertService => expertService.id == valetServiceId);
    if(this.selectedService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }
    if(this.selectedService?.service?.serviceType?.toLowerCase() === 'hourlyratesalary'){
      this.calculateHourlyRateValetSalary();
    }
  }

  /* Old function 
  async calculateFixedPriceValetSalary(){
    try {
      this.delivery.valetStartTime = null;
      this.delivery.valetEndTime = null;
      let pickUpAddressResult: any[];
      if(this.delivery.pickUpAddress){
        pickUpAddressResult = await this.getLatLngByAddress(this.delivery.pickUpAddress ? this.delivery.pickUpAddress : '');
      }
      if(pickUpAddressResult && pickUpAddressResult.length > 0){
        let delveryAddLat = this.delivery.latitude || '';
        let delveryAddLng = this.delivery.longitude || '';
        let pickUpAddLat = pickUpAddressResult[0].geometry?.location?.lat() || '';
        let pickUpAddLng = pickUpAddressResult[0].geometry?.location?.lng() || '';
        const pickUpAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
        const deliveryAddressLatLng = new google.maps.LatLng(Number(delveryAddLat), Number(delveryAddLng));
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        distanceMatrixService.getDistanceMatrix({
          origins: [pickUpAddressLatLng],
          destinations: [deliveryAddressLatLng],
          travelMode: google.maps.TravelMode.DRIVING,
        }, async (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const distance = response.rows[0].elements[0].distance?.value;
            const distanceInKm = distance / 1000;
            let valetSalary: any = 0;
            if(this.selectedExpert?.minimumKmIncluded && distanceInKm > this.selectedExpert?.minimumKmIncluded){
              let extraKm = (distanceInKm - this.selectedExpert?.minimumKmIncluded);
              valetSalary = (this.selectedService?.salary) + (extraKm * Number(this.selectedService?.minimumKmPrice));
            } else {
              valetSalary = this.selectedService?.salary;
            }
            this._deliveryForm.controls.expertSalary?.setValue(parseFloat(valetSalary)?.toFixed(2));
            this._deliveryForm.controls.expertServiceId.setValue(this.selectedService?.id);
            console.log('distanceInKm', distanceInKm);
            console.log('valetSalary', this._deliveryForm.controls.expertSalary?.value)
            console.log('this.selectedService?.service?.serviceType', this.selectedService?.service?.serviceType);
          } else {
            console.error('Error calculating distance:', status);
            alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
            return;
          }
        });
      }
    } catch (error) {
      alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
      return;
    }
  }
  Old function */

  async calculateFixedPriceValetSalary(){
    try {
      this.disableBtn = true;
      this.delivery.valetStartTime = null;
      this.delivery.valetEndTime = null;

      let pickUpAddLat: any;
      let pickUpAddLng: any;
      let deliveryAddLat: any;
      let deliveryAddLng: any;

      if (this.delivery.pickUpAddress) {
        const pickUpAddressResult = await this.getLatLngByAddress(this.delivery.pickUpAddress);
        pickUpAddLat = pickUpAddressResult[0]?.geometry?.location?.lat() || '';
        pickUpAddLng = pickUpAddressResult[0]?.geometry?.location?.lng() || '';
      }

      if (this.delivery.address) {
        const deliveryAddressResult = await this.getLatLngByAddress(this.delivery.address);
        deliveryAddLat = deliveryAddressResult[0]?.geometry?.location?.lat() || '';
        deliveryAddLng = deliveryAddressResult[0]?.geometry?.location?.lng() || '';
      }

      if (pickUpAddLat && pickUpAddLng && deliveryAddLat && deliveryAddLng) {

        const pickUpAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
        const deliveryAddressLatLng = new google.maps.LatLng(deliveryAddLat, deliveryAddLng);
        const distanceMatrixService = new google.maps.DistanceMatrixService();

        const response: any = await new Promise((resolve, reject) => {
          distanceMatrixService.getDistanceMatrix({
            origins: [pickUpAddressLatLng],
            destinations: [deliveryAddressLatLng],
            travelMode: google.maps.TravelMode.DRIVING,
          }, (response, status) => {
            if (status === google.maps.DistanceMatrixStatus.OK) {
              resolve(response);
            } else {
              reject(status);
            }
          });
        });

        const distance = response.rows[0].elements[0].distance?.value;
        const distanceInKm = distance / 1000;
        let valetSalary: any = 0;
        if(this.selectedExpert?.minimumKmIncluded && distanceInKm > this.selectedExpert?.minimumKmIncluded){
          let extraKm = (distanceInKm - this.selectedExpert?.minimumKmIncluded);
          valetSalary = (this.selectedService?.salary) + (extraKm * Number(this.selectedService?.minimumKmPrice));
        } else {
          valetSalary = this.selectedService?.salary;
        }
        this._deliveryForm.controls.expertSalary?.setValue(parseFloat(valetSalary)?.toFixed(2));
        this._deliveryForm.controls.expertServiceId.setValue(this.selectedService?.id);
        /*
        console.log('distanceInKm', distanceInKm);
        console.log('valetSalary', this._deliveryForm.controls.expertSalary?.value)
        console.log('this.selectedService?.service?.serviceType', this.selectedService?.service?.serviceType);
        */
        this.disableBtn = false;
      } else {
        alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
        return;
      }
    } catch (error) {
      alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
    }
  }

  calculateHourlyRateValetSalary(){

    let startTime: any;
    let endTime: any;
    let totalTime: any;
    if(this.delivery?.startTime != null && this.delivery.endTime != null){
      startTime = (this.delivery?.startTime?.slice(0, -3));
      endTime = (this.delivery.endTime?.slice(0, -3));
      startTime = new Date('1970-01-01T' + startTime + ':00');
      endTime = new Date('1970-01-01T' + endTime + ':00');
      const timeDiff = endTime.getTime() - startTime.getTime();
      const hours = Math.floor(timeDiff / 3600000);
      const minutes = Math.floor((timeDiff % 3600000) / 60000);
      // totalTime = `${hours}.${minutes}`;
      totalTime = hours + minutes / 60;
      this.delivery.valetStartTime = this.delivery?.startTime;
      this.delivery.valetEndTime = this.delivery.endTime;
    } else {
      startTime = '07:00:00';
      endTime = '08:00:00';
      totalTime = 1;
      this.delivery.valetStartTime = startTime;
      this.delivery.valetEndTime = endTime;
    }
    let valetSalary = (this.selectedService?.salary) * totalTime.toFixed(2);
    this._deliveryForm.controls.expertSalary?.setValue(valetSalary)?.toFixed(2);
    this._deliveryForm.controls.expertServiceId.setValue(this.selectedService?.id);
    /*
    console.log('totalTime', totalTime)
    console.log('valetSalary', this._deliveryForm.controls.expertSalary?.value)
    console.log('this.selectedService?.service?.serviceType', this.selectedService?.service?.serviceType);
    console.log('this.delivery.valetStartTime', this.delivery.valetStartTime)
    console.log('this.delivery.valetEndTime', this.delivery.valetEndTime)
    */
  }

  async getLatLngByAddress(address: string){
    const geocoder = new google.maps.Geocoder();
    const results = await new Promise<any>((resolve, reject) => {
      geocoder.geocode({ 'address': address }, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
    return results;
  }

  onExpertSelection(event: any, expert: Expert){
    try {
      this.selectedServiceId = null;
      this.selectedExpert = this.experts.find(expert => expert.id == this._deliveryForm.controls.expert.controls.id.value);
      if(this.delivery?.serviceType){
        if(["fixedprice", "sales"].includes(this.delivery?.serviceType?.toLowerCase())){
          this.selectedService = this.selectedExpert?.expertServices.find(expertService => expertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary');
          this.selectedServiceId = this.selectedService?.id;
          this.calculateFixedPriceValetSalary();
        }
        if(this.delivery?.serviceType?.toLowerCase() === "hourlyrate"){
          this.selectedService = this.selectedExpert?.expertServices.find(expertService => expertService?.service?.serviceType?.toLowerCase() === 'hourlyratesalary');
          this.selectedServiceId = this.selectedService?.id;
          this.calculateHourlyRateValetSalary();
        }
      }
    } catch (error) {
      console.log("Error", error)
    }
  }


  /*
  async createValetActivity(delivery: Delivery){
    try {

      let filtered = {};
      const today = new Date();
      const tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
      filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [tmptoday, tmptoday] };
      filtered['Expert.id'] = Number(delivery.expert.id);
      filtered['AdminValetActivity.completed'] = 0;
      filtered['Delivery.id'] = Number(delivery.id);
      let activitiesWithDeliveryId = await this.adminValetActivityService.getAdminValetActivity(filtered);

      if(!activitiesWithDeliveryId.length) {
        let activityArray: any[] = [];
        if (delivery.serviceType.toLowerCase() === "sales" || delivery.serviceType.toLowerCase() === "fixedprice") {
          if (delivery.startTime || delivery.fromTime) {
            activityArray.push({
              "expert": delivery.expert.id,
              "activityType": "pickup",
              "delivery": delivery.id,
              "deliveryDate": delivery.deliveryDate,
              "order": null,
              "status": delivery.status,
            });
          }
          if (delivery.pickUpTime) {
            activityArray.push({
              "expert": delivery.expert.id,
              "activityType": "delivery",
              "delivery": delivery.id,
              "deliveryDate": delivery.deliveryDate,
              "order": null,
              "status": delivery.status,
            });
          }
        }
  
        if (delivery.serviceType.toLowerCase() === "hourlyrate") {
          activityArray.push({
            "expert": delivery.expert.id,
            "activityType": "hourlyservice",
            "delivery": delivery.id,
            "deliveryDate": delivery.deliveryDate,
            "order": null,
            "status": delivery.status,
          });
        }
    
        await this.adminValetActivityService.create(activityArray);
      }

      delete filtered['Delivery.id'];
      let activities = await this.adminValetActivityService.getAdminValetActivity(filtered);
      let newActivityArray = [];

      if (activities.length) {
        activities.forEach((activity: any) => {
          if (activity.activityType.toLowerCase() === "pickup") {
            newActivityArray.push({
              ...activity,
              timing: activity.delivery?.pickUpTime.split('-')[0]
            });
          }
          if (activity.activityType.toLowerCase() === "delivery") {
            newActivityArray.push({
              ...activity,
              timing: activity.delivery?.fromTime
            });
          }
          if (activity.activityType.toLowerCase() === "hourlyservice") {
            newActivityArray.push({
              ...activity,
              timing: activity.delivery.startTime ? activity.delivery.startTime : activity.delivery.fromTime ? activity.delivery.fromTime : ''
            });
          }
        });
      }

      newActivityArray.sort((a, b) => {
        const timeA = a.timing.split(':').map(Number);
        const timeB = b.timing.split(':').map(Number);
        return timeA[0] - timeB[0] || timeA[1] - timeB[1];
      });
      newActivityArray.forEach((activity, i) => {
        activity.order = i + 1;
      });
      const resultArray = newActivityArray.map(activity => ({
        id: activity.id,
        order: activity.order
      }));
      
      console.log("Resulting Array", resultArray);
      await this.adminValetActivityService.updateActivityOrder(resultArray);

    } catch (error) {
      this.showSnackBar("Something went wrong");
      console.log(error);
    }
  }
  */

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}
