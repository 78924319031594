import { SpinnerService } from './../../services/spinner.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Expert, ExpertTeamLeaderProvinces } from '../../models/expert.schema';
import { PaginationComponent } from '../_pagination/pagination.component';
import { ExpertService } from '../../services/expert.service';
import { ActivatedRoute } from '@angular/router';
import { Vehicle } from '../../models/vehicle.schema';
import { orderByProperty } from '../../helpers/order-by-property.helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import {  UserActiveStatus } from '../../models/user.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { TeamLeaderService } from 'src/app/services/team-leader.service';

@Component({
  selector: 'app-experts',
  templateUrl: './experts.component.html',
  styleUrls: ['./experts.component.scss']
})
export class ExpertsComponent implements OnInit {
  public experts: Expert[];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  public _expertFilterForm;
  public filtered = {};
  public vehicles: Vehicle[];
  public readonly activeStatus = UserActiveStatus;
  public groups: string[];
  teamLeader: TeamLeader;
  loggedInUserInfo: any;
  loggedInExpert: Expert

  constructor(
    private expertService: ExpertService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private authStatusService: AuthStatusService,
    private teamLeaderService: TeamLeaderService,
    private spinnerService: SpinnerService
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert') && this.loggedInUserInfo){
        this.loggedInExpert = await this.expertService.getOne(this.loggedInUserInfo['extraId']);
      }
      this.vehicles = orderByProperty(await this.expertService.getAllVehicles(), 'name');
      this.manageExpertFilterForm();
      await this.loadExperts();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong on this page");
    }
  }

  manageExpertFilterForm(){
    this._expertFilterForm = new FormGroup({
      'Expert.id': new FormControl(''),
      'User.surname': new FormControl(''),
      'User.name': new FormControl(''),
      'User.email': new FormControl(''),
      'Expert.phone': new FormControl(''),
      'Expert.city': new FormControl(''),
      'Expert.address': new FormControl(''),
      'Vehicle.id': new FormControl(''),
      'Expert.isTeamLeader': new FormControl(''),
      'User.active': new FormControl('')
    });
  }

  async filter() {
    let filterForm = (new FormGroupToObject()).transform(this._expertFilterForm);
    this.filtered = {};
    if (filterForm['Expert.id'])
      this.filtered['Expert.id'] = filterForm['Expert.id'];
    if (filterForm['User.active'])
      this.filtered['User.active'] = filterForm['User.active'];
    if (filterForm['User.surname'])
      this.filtered['User.surname'] = { operator: 'like', values: [`%${filterForm['User.surname']}%`] };
    if (filterForm['User.name'])
      this.filtered['User.name'] = { operator: 'like', values: [`%${filterForm['User.name']}%`] };
    if (filterForm['User.email'])
      this.filtered['User.email'] = { operator: 'like', values: [`%${filterForm['User.email']}%`] };
    if (filterForm['Expert.phone'])
      this.filtered['Expert.phone'] = { operator: 'like', values: [`%${filterForm['Expert.phone']}%`] };
    if (filterForm['Expert.city'])
      this.filtered['Expert.city'] = { operator: 'like', values: [`%${filterForm['Expert.city']}%`] };
    if (filterForm['Expert.address'])
      this.filtered['Expert.address'] = { operator: 'like', values: [`%${filterForm['Expert.address']}%`] };
    if (filterForm['Vehicle.id'])
      this.filtered['Vehicle.id'] = filterForm['Vehicle.id'];
    if (filterForm['Expert.isTeamLeader'])
      this.filtered['Expert.isTeamLeader'] = { operator: 'like', values: [`%${filterForm['Expert.isTeamLeader']}%`]};
    this.loadExperts();
  }

  navigate(event) {
    this.loadExperts(event.limit, event.page, this.order);
  }

  async loadExperts(limit?: number, page?: number, order?: {}) {
    try {
      this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || 1;
      this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || 200;
      this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || { 'User.surname': 'ASC', 'User.name': 'ASC' };
      let experts = await this.expertService.getAllWithoutTimeAva(this.page, this.limit, this.order, this.filtered);
      this.experts = experts.experts;
      if(this.groups.includes('admin')){
        this.experts = experts.experts;
        this.count = this.experts.length;
        this.maxPage = Math.ceil(this.count / this.limit);
      }
      if(this.groups.includes('teamleader') && this.loggedInUserInfo){
        this.teamLeader = await this.teamLeaderService.getOne(this.loggedInUserInfo['extraId']);
        if (this.teamLeader.teamLeaderProvinces.length > 0) {
          this.experts = this.experts?.filter((expert) => {
            return expert?.expertProvinces.some((eProvince) => {
              if(eProvince.province != null){
                return this.teamLeader.teamLeaderProvinces.some((teamLeaderProvince: TeamLeaderProvinces) => {
                  return eProvince.province.provinceCode.toLowerCase() === teamLeaderProvince.province.provinceCode.toLowerCase()
                });
              }
            });
          });
          this.count = this.experts.length;
          this.maxPage = Math.ceil(this.count / this.limit);
        }
      }

      if(this.groups.includes('expert') && this.loggedInExpert){
        if (this.loggedInExpert.expertTeamLeaderProvinces.length > 0) {
          this.experts = this.experts?.filter((expert) => {
            return expert?.expertProvinces.some((eProvince) => {
              if(eProvince.province != null){
                return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
                  return eProvince.province.provinceCode.toLowerCase() === expertTeamLeaderProvince.province.provinceCode.toLowerCase()
                });
              }
            });
          });
          this.count = this.experts.length;
          this.maxPage = Math.ceil(this.count / this.limit);
        }
      }
    } catch (error) {
      
    }
  }

  async resetFilter(){
    this.manageExpertFilterForm();
    await this.filter();
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order[attr] = 'ASC';
          break;
      }
    }
    this.paginationComponent.navigate(this.limit, this.page, { order: this.order });
  }

  public getVehicleNames(vehicles: Vehicle[]) {
    return vehicles ? orderByProperty(vehicles, 'name').map(v => v.name) : [];
  }

  public async delete(expertId: number) {
    if (!confirm("Sei sicuro di voler cancellare questo expert?"))
      return;
    let deleteResult: { id: number | string, success: boolean } = await this.expertService.delete(expertId);
    let message = "Cancellazione dell'expert fallita.";
    if (deleteResult.success) {
      this.experts = this.experts.filter(expert => expert.id != deleteResult.id);
      message = "Cancellazione dell'expert avvenuta con successo.";
    }
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  translateActive(status) {
    return UserActiveStatus.find(as => as.value == status).ln.it;
  }

  showSnackBar(message: string){
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }
}
