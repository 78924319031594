import { Component, OnInit, ViewChild } from '@angular/core';
import { Delivery, DeliveryStatusAux, DeliveryStatus } from '../../models/delivery.schema';
import { DeliveryService } from '../../services/delivery.service';
import { PaginationComponent } from '../_pagination/pagination.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryObserverService } from '../../services/observers/delivery-observer.service';
import { AuthStatusService } from '../../services/auth-status.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { DeliveryNotDeliveredDialogComponent } from '../delivery-not-delivered-dialog/delivery-not-delivered-dialog.component';
import { DeliveryExpertFormComponent } from '../delivery-expert-form/delivery-expert-form.component';
import { Expert, ExpertProvinces, ExpertTeamLeaderProvinces } from '../../models/expert.schema';
import { ExpertService } from '../../services/expert.service';
import { PartnerService } from '../../services/partner.service';
import { read, utils, write, writeFile } from 'xlsx';
import { Partner } from 'src/app/models/partner.schema';
import { ProductService } from 'src/app/services/product.service';
import { Product } from 'src/app/models/product.schema';
import { ReceiptComponent } from '../receipt/receipt.component';
import { PartialUpdateDeliveryComponent } from '../partial-update-delivery/partial-update-delivery.component';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { TeamLeaderService } from 'src/app/services/team-leader.service';
import { SpinnerService } from './../../services/spinner.service';
import { CommanModalComponent } from '../comman-modal/comman-modal.component';
import { ApproveTimingsComponent } from '../approve-timings/approve-timings.component';
import { ApproveService22TimingsComponent } from '../approve-service22-timings/approve-service22-timings.component';
import { ReviewService22TimingsComponent } from '../review-service22-timings/review-service22-timings.component';
import { MapHelperService } from 'src/app/helpers/maps-service-helpher.service';
import { StartEndTimeEditComponent } from '../start-end-time-edit/start-end-time-edit.component';
//import { format } from 'path';



@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss']
})
export class DeliveriesComponent implements OnInit {
  public groups: any;
  public _deliveryFilterForm;
  public deliveryStatus = DeliveryStatus;
  public deliveryStatusAux = DeliveryStatusAux;
  public deliveries: Delivery[] = [];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  public experts: Expert[];
  public partners: Partner[];
  public partnersForPartner: Partner[];
  public expertDialogOpen: number;
  public import_deliveries: any = [];
  inActiveStatus: any[] = DeliveryStatusAux.inactive;
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  public selected = "active";
  public settings = {
    active: {
      title: "Consegne",
      subtitle: "In corso"
    },
    inactive: {
      title: "Storico consegne",
      subtitle: "Storico"
    }
  };
  private readonly tableSpacing = {
    admin: {
      id: 5,
      deliveryDate: 10,
      time: 10,
      partner: 15,
      expert: 10,
      address: 30,
      status: 5,
      detail: 5,
      edit: 5,
      assignExpert: 5,
      historyStatus: 5,
    },
    operation: {
      id: 5,
      deliveryDate: 10,
      time: 10,
      partner: 15,
      expert: 10,
      address: 30,
      status: 5,
      detail: 5,
      edit: 5,
      assignExpert: 5,
      historyStatus: 5,
    },
    teamLeader: {
      id: 5,
      deliveryDate: 10,
      time: 10,
      partner: 15,
      expert: 10,
      address: 30,
      status: 5,
      detail: 5,
      edit: 5,
      assignExpert: 5,
      historyStatus: 5,
    },
    partner: {
      id: 5,
      deliveryDate: 10,
      time: 10,
      address: 20,
      status: 5,
      detail: 10,
      edit: 10,
      historyStatus: 5,
    },
    expert: {
      id: 5,
      deliveryDate: 10,
      time: 15,
      partner: 10,
      address: 20,
      status: 10,
      edit: 20,
      detail: 10,
      historyStatus: 5,
    },
  };
  public defaultPage = 1;
  public defaultLimit = 200;
  //public defaultOrder = { 'Delivery.id': 'ASC', 'Delivery.deliveryDate': 'DESC', 'Delivery.fromTime': 'DESC', 'Delivery.toTime': 'DESC' };
  public defaultOrder = { 'Delivery.deliveryDate': 'DESC', 'Delivery.fromTime': 'ASC', 'Delivery.toTime': 'ASC' };


  public filtered = {};
  public today: any;
  public loggedInPartner: any;
  public loggedInUser: any;
  saleBtn: boolean = false;
  deliveryCancellationBtn: boolean = false;
  teamLeader: TeamLeader;
  loggedInExpert: Expert;
  private intervalId: any;
  isDeliveryMapAccordionOpen: boolean = false;
  optimized: boolean = false;

  public groupWhitelisted(groups: string | string[]): boolean {
    if (!Array.isArray(groups))
      return this.groups.includes(groups);
    for (let group of groups)
      if (this.groups.includes(group))
        return true;
    return false;
  }

  public getSpacing(property) {
    if (this.groups.includes('expert'))
      return this.tableSpacing['expert'][property] || 0;
    if (this.groups.includes('partner'))
      return this.tableSpacing['partner'][property] || 0;
    if (this.groups.includes(['admin']))
      return this.tableSpacing['admin'][property] || 0;
    if (this.groups.includes(['operation']))
      return this.tableSpacing['operation'][property] || 0;
    if (this.groups.includes(['teamLeader']))
      return this.tableSpacing['teamLeader'][property] || 0;
  }

  constructor(
    private authStatusService: AuthStatusService,
    private deliveryService: DeliveryService,
    public deliveryObserverService: DeliveryObserverService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public expertService: ExpertService,
    public partnerService: PartnerService,
    public productService: ProductService,
    private teamLeaderService: TeamLeaderService,
    private spinnerService: SpinnerService,
    private mapHelperService: MapHelperService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
  }


  async ngOnInit() {
    try {
      // this.spinnerService.show();
      this.startInterval();
      this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
      if(this.selected == 'active'){
        setTimeout(() => {this.mapHelperService.initializeMap("deliveriesMap", { lat: 41.8719, lng: 12.5674 }, 6);}, 0);
      }
      const loggedInUserInfo = await this.authStatusService.getTokenInfo();
      var today = new Date();
      var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2); //DATA CORRENTE
      this.today = tmptoday;

      if (this.groupWhitelisted(['partner'])){
        // this.loggedInPartner = await JSON.parse(sessionStorage.getItem("currentUser"));
        this.loggedInPartner = await this.partnerService.getOne(loggedInUserInfo['extraId']);
      }

      if(this.groups.includes('teamleader')){
        this.teamLeader = await this.teamLeaderService.getOne(loggedInUserInfo['extraId']);
      }

      if(this.groups.includes('expert')){
        this.loggedInExpert = await this.expertService.getOne(loggedInUserInfo['extraId']);
      }

      if (this.groupWhitelisted(['admin', 'operation', 'expert', 'teamleader'])){
        this._deliveryFilterForm = new FormGroup({
          'Delivery.id': new FormControl(''),
          'Delivery.deliveryDate_from': new FormControl(tmptoday), //Current Default Date
          'Delivery.deliveryDate_to': new FormControl(tmptoday),   //Current Default Date
          'Delivery.fromTime': new FormControl(''),
          'Delivery.toTime': new FormControl(''),
          'Partner.businessName': new FormControl(''),
          'UserExpert.surname': new FormControl(''),
          //'UserExpert.id': new FormControl(''),
          'Delivery.status': new FormControl(''),
          'Delivery.address': new FormControl(''),
          'Delivery.pickUpAddress': new FormControl(''),
          'Delivery.pickUpTime': new FormControl(''),
          'Delivery.serviceType': new FormControl(''),
          'Delivery.billable': new FormControl(''),
          'Delivery.payable': new FormControl(''),
          'Delivery.orderId': new FormControl(''),
          // 'User.surname': new FormControl(''),
          // 'User.name': new FormControl(''),
          // 'Delivery.approvedTimings': new FormControl(''),
          // 'Delivery.withDailyDeliveryRule': new FormControl(''),
          // 'Delivery.withTotalDeliveryRule': new FormControl(''),
        });
      } else {  // Partner
        this._deliveryFilterForm = new FormGroup({
          'Delivery.id': new FormControl(''),
          'Delivery.deliveryDate_from': new FormControl(''),
          'Delivery.deliveryDate_to': new FormControl(''),
          'Delivery.fromTime': new FormControl(''),
          'Delivery.toTime': new FormControl(''),
          'Partner.businessName': new FormControl(''),
          'UserExpert.surname': new FormControl(''),
          //'UserExpert.id': new FormControl(''),
          'Delivery.status': new FormControl(''),
          'Delivery.address': new FormControl(''),
          'Delivery.pickUpAddress': new FormControl(''),
          'Delivery.pickUpTime': new FormControl(''),
          'Delivery.serviceType': new FormControl(''),
          'Delivery.billable': new FormControl(''),
          'Delivery.payable': new FormControl(''),
          'Delivery.orderId': new FormControl(''),
          // 'User.surname': new FormControl(''),
          // 'User.name': new FormControl(''),
          // 'Delivery.approvedTimings': new FormControl(''),
          // 'Delivery.withDailyDeliveryRule': new FormControl(''),
          // 'Delivery.withTotalDeliveryRule': new FormControl(''),
        });
      }

      this.expertDialogOpen = 0;
      await this.filter();
      await this.initialize();
      // this.spinnerService.hide();
    } catch (error) {
      console.log(error);
      this.showSnackBar("Something Went Wrong On This Page");
    }
  }


  async initialize() {
    if (this.groupWhitelisted(['admin', 'operation', 'teamleader']) || ( this.groups.includes('expert') && this.loggedInExpert?.isTeamLeader )) {
      try {
        this.partners = (await this.partnerService.getAll(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
      }
      catch { }
      try {
        this.experts = (await this.expertService.getAll(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
      }
      catch { }
    }
  }


  async filter() {

    let filterForm = (new FormGroupToObject()).transform(this._deliveryFilterForm);
    this.filtered = {};

    if (filterForm['Delivery.id'])
      this.filtered['Delivery.id'] = filterForm['Delivery.id'];
    if (filterForm['Delivery.deliveryDate_from'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.deliveryDate_from']] };
    if (filterForm['Delivery.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'lessThan', values: [filterForm['Delivery.deliveryDate_to']] };
    if (filterForm['Delivery.deliveryDate_from'] && filterForm['Delivery.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'between', values: [filterForm['Delivery.deliveryDate_from'], filterForm['Delivery.deliveryDate_to']] };
    if (filterForm['Delivery.fromTime'])
      this.filtered['Delivery.fromTime'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.fromTime']] };
    if (filterForm['Delivery.toTime'])
      this.filtered['Delivery.toTime'] = { operator: 'lessThan', values: [filterForm['Delivery.toTime']] };
    if (filterForm['Partner.businessName'])
        this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
    if (filterForm['UserExpert.surname'])
      this.filtered['UserExpert.surname'] = { operator: 'like', values: [`%${filterForm['UserExpert.surname']}%`], concat: [{ string: ' ' }, { column: 'UserExpert.name' }] };
    if (filterForm['Delivery.pickUpAddress'])
      this.filtered['Delivery.pickUpAddress'] = { operator: 'like', values: [`%${filterForm['Delivery.pickUpAddress']}%`], concat: [{ string: ' ' }, { column: 'Delivery.partner.address' }] };
    if (filterForm['Delivery.pickUpTime'])
      this.filtered['Delivery.pickUpTime'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.pickUpTime']] };
    // if (filterForm['Delivery.approvedTimings'])
    //   this.filtered['Delivery.approvedTimings'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.approvedTimings']] };
    if (filterForm['Delivery.status'])
      this.filtered['Delivery.status'] = filterForm['Delivery.status'];
    if (filterForm['Delivery.address'])
       this.filtered['Delivery.address'] = { operator: 'like', values: [`%${filterForm['Delivery.address']}%`]};
    if (filterForm['Delivery.serviceType'])
       this.filtered['Delivery.serviceType'] = { operator: 'like', values: [`%${filterForm['Delivery.serviceType']}%`]};
    if (filterForm['Delivery.billable'])
       this.filtered['Delivery.billable'] = { operator: 'like', values: [`%${filterForm['Delivery.billable']}%`]};
    if (filterForm['Delivery.payable'])
       this.filtered['Delivery.payable'] = { operator: 'like', values: [`%${filterForm['Delivery.payable']}%`]};
    if (filterForm['Delivery.orderId'])
       this.filtered['Delivery.orderId'] = { operator: 'like', values: [`%${filterForm['Delivery.orderId']}%`]};
    // if (filterForm['User.surname'])
    //    this.filtered['User.surname'] = { operator: 'like', values: [`%${filterForm['User.surname']}%`]};
    // if (filterForm['User.name'])
    //    this.filtered['User.name'] = { operator: 'like', values: [`%${filterForm['User.name']}%`]};

    await this.loadDeliveries();
  }

  async fnReset() {
    this._deliveryFilterForm = new FormGroup({
      'Delivery.id': new FormControl(''),
      'Delivery.deliveryDate_from': new FormControl(),
      'Delivery.deliveryDate_to': new FormControl(),
      'Delivery.fromTime': new FormControl(''),
      'Delivery.toTime': new FormControl(''),
      'Partner.businessName': new FormControl(''),
      'UserExpert.surname': new FormControl(''),
      'Delivery.status': new FormControl(''),
      'Delivery.address': new FormControl(''),
      'Delivery.serviceType': new FormControl(''),
      'Delivery.billable': new FormControl(''),
      'Delivery.payable': new FormControl(''),
      'Delivery.pickUpAddress': new FormControl(''),
      'Delivery.orderId': new FormControl(''),
      // 'User.surname': new FormControl(''),
      // 'User.name': new FormControl(''),
      // 'Delivery.withDailyDeliveryRule': new FormControl(''),
      // 'Delivery.withTotalDeliveryRule': new FormControl(''),
    });
    this.expertDialogOpen = 0;
    this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
    //this.loadDeliveries();
    this.filter();
    await this.initialize();
  }

  navigate(event) {
    this.loadDeliveries(event.limit, event.page, this.order);
  }

  async loadDeliveries(limit?: number, page?: number, order?: {}) {
    let filterForm = (new FormGroupToObject()).transform(this._deliveryFilterForm);
    if(filterForm['Delivery.deliveryDate_to'] && filterForm['Delivery.deliveryDate_from']){
      if((this.today != filterForm['Delivery.deliveryDate_from'])){
        this.defaultOrder = { 'Delivery.deliveryDate': 'ASC', 'Delivery.fromTime': 'ASC', 'Delivery.toTime': 'ASC' };
      }
    }

    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;

    let deliveries = this.selected == 'active' ?
      await this.deliveryService.getAllActive(this.page, this.limit, this.order, this.filtered) :
      await this.deliveryService.getAllInactive(this.page, this.limit, this.order, this.filtered);
    this.deliveries = deliveries.deliveries;
    this.count = deliveries.count;
    this.maxPage = Math.ceil(this.count / this.limit);
    /*
    if (this.groups.includes('teamleader') && this.teamLeader) {
      this.deliveries = this.deliveries.filter(({ address }) => {
        const addressParts = address.split(', ');
        let shopifyProvince = addressParts[addressParts.length - 2];
        if (addressParts.length > 1) {
          const province = addressParts[addressParts.length - 2].split(' ').pop();
          return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
            return (province.length === 2 || shopifyProvince.length === 2 ) && (province.toLowerCase() === expertTeamLeaderProvince?.province?.provinceCode?.toLowerCase() || shopifyProvince.toLowerCase() === expertTeamLeaderProvince?.province?.provinceCode?.toLowerCase());
          });
        }
      });
      this.count = this.deliveries.length;
      this.maxPage = Math.ceil(this.count / this.limit);
    }
    */

    if(this.groups.includes('expert') && this.loggedInExpert && this.loggedInExpert.isTeamLeader){
      let assignedDeliveries: Delivery[] = this.deliveries.filter((delivery: Delivery) => {
        return delivery?.expert?.id === this.loggedInExpert.id;
      });
      this.deliveries = this.deliveries.filter(({ address }) => {
        const addressParts = address.split(', ');
        let shopifyProvince = addressParts[addressParts.length - 2];  /* This check for shopify sale because there were address pattern changed */

        const twoLetterMatch = address.match(/\b[a-zA-Z]{2}\b/);
        let twoLetterString = twoLetterMatch ? twoLetterMatch[0] : null;

        if (addressParts.length > 1) {
          const province = addressParts[addressParts.length - 2].split(' ').pop();
          return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
            return (province.toLowerCase() === expertTeamLeaderProvince?.province?.provinceCode?.toLowerCase() || shopifyProvince.toLowerCase() === expertTeamLeaderProvince?.province?.provinceCode?.toLowerCase() || province.toLowerCase() === expertTeamLeaderProvince?.province?.province?.toLowerCase() || (twoLetterString != null && twoLetterString.toLowerCase() === expertTeamLeaderProvince?.province?.province?.toLowerCase()));
          });
        }
        return false;
      });
      this.deliveries = [...this.deliveries, ...assignedDeliveries].filter((delivery, index, self) =>
        index === self.findIndex((d) => d.id === delivery.id)
      );
      this.count = this.deliveries.length;
      this.maxPage = Math.ceil(this.count / this.limit);
    }


  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['Delivery.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  public expertModal(delivery?: Delivery) {
    if(this.groupWhitelisted(['admin', 'operation', 'teamleader']) || (this.groups.includes('expert') && this.loggedInExpert?.isTeamLeader) ){
      let ref = this.dialog.open(DeliveryExpertFormComponent, { width: '80vw', data: { delivery } });
      ref.afterClosed().subscribe(async (result) => {
        if (result?.delivery){
          let updatedDelivery = result?.delivery;
          if(this.groups.includes('admin') || this.groups.includes('operation')){
            let partialUpdateModalRef = this.dialog.open(PartialUpdateDeliveryComponent, { width: '80vw', data: { updatedDelivery } });
            partialUpdateModalRef.afterClosed().subscribe(async (res) => {
              if (res?.delivery){
                this.snack(`Price Updated`);
              }
              if (res?.delivery === null){
                this.snack(`Something went wrong`);
              }
              await this.loadDeliveries();
            })
          }
          await this.loadDeliveries();
        }
          // delivery.expert = result.delivery.expert;
      });
    } else {
      return false;
    }
  }

  public async delivered(id: number) {
    let delivery = await this.deliveryService.getOne(id);
    let partner = await this.partnerService.getOne(delivery.partner.id);
    let sendSmsConfirmation: boolean = false;
    if (!confirm("Sei sicuro di voler impostare questa consegna come consegnata?"))
      return;
    if((partner.sendSms && delivery.smsPhoneNo)  || (delivery.createdUser == 1 && delivery.smsPhoneNo && delivery.deluxyDelivery)){
      if (confirm("Vuoi inviare sms?"))
        sendSmsConfirmation = true;
    }
    let openFrom = 'deliveries';
    let ref: any;
    // let ref = this.dialog.open(ReceiptComponent, { width: '80vw', data: { delivery, openFrom } });
    if(delivery.serviceType.toLowerCase() === 'hourlyrate' && delivery.service != 22){
      ref  = this.dialog.open(CommanModalComponent, {
        width: '80vw',
        data: {
          component: StartEndTimeEditComponent,
          delivery: delivery,
          title: "Start and End Time" 
        }
      });
    } else if(delivery.serviceType.toLowerCase() === 'hourlyrate' && delivery.service == 22){
      ref  = this.dialog.open(CommanModalComponent, {
        width: '80vw',
        data: {
          component: ApproveTimingsComponent,
          delivery: delivery,
          title: "Start and End Time" 
        }
      });
    } else {
      let openFrom = 'deliveries';
      ref = this.dialog.open(ReceiptComponent, { width: '80vw', data: { delivery, openFrom } });
    }
    ref.afterClosed().subscribe(result => {
      if(result?.success === true){
        return this.deliveryService.setDeliveryDelivered(id, sendSmsConfirmation).then(data => {
          this.deliveries.splice(this.deliveries.findIndex(delivery => delivery.id == data.id), 1);
          this.snack(`Consegna aggiornata con successo`);
        }).catch(err => {
          this.snack(`Errore nell'aggiornamento della consegna`);
          console.log(err);
        });
      }
      if(result?.success === false){
        this.snack(`Errore nell'aggiornamento della consegna`);
      }
    });
    /*
    return this.deliveryService.setDeliveryDelivered(id, sendSmsConfirmation).then(data => {
      this.deliveries.splice(this.deliveries.findIndex(delivery => delivery.id == data.id), 1);
      this.snack(`Consegna aggiornata con successo`);
    }).catch(err => {
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
    */
  }

  async addReceipt(delivery: Delivery){
    let ref = this.dialog.open(ReceiptComponent, { width: '80vw', data: { delivery } });
    ref.afterClosed().subscribe(result => {
      if(result?.success === true){
        this.snack(`Receipt Data Added Successfully`);
      }
      if(result?.success === false){
        this.snack(`Errore nell'aggiornamento della consegna`);
      }
      this.loadDeliveries();
    });
  }

  public async notDelivered(delivery: Delivery) {
    let openFrom = 'deliveries';
    let ref = this.dialog.open(DeliveryNotDeliveredDialogComponent, { width: '80vw', data: { delivery, openFrom }, disableClose: true  });
    ref.afterClosed().subscribe(result => {
      if (result.done)
        this.loadDeliveries();
    });
  }

  public async delivering(id: number) {
    let delivery = await this.deliveryService.getOne(id);
    let partner = await this.partnerService.getOne(delivery.partner.id);
    let sendSmsConfirmation: boolean = false;
    if (!confirm("Sei sicuro di voler impostare questa consegna come in consegna?"))
      return;
    if((partner.sendSms && delivery.smsPhoneNo) || (delivery.createdUser == 1 && delivery.smsPhoneNo && delivery.deluxyDelivery)){
      if (confirm("Vuoi inviare sms?"))
        sendSmsConfirmation = true;
    }

    return this.deliveryService.setDeliveryDelivering(id, sendSmsConfirmation).then(data => {
      // Change delivery status
      this.deliveries.find(delivery => delivery.id == data.id).status = DeliveryStatus.delivering;
      this.snack(`Consegna aggiornata con successo`);
    }).catch(err => {
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
  }

  snack(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    // this.loadDeliveries();
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadDeliveries();
    });
  }

  getDeliveryLink(id: number, deluxyDelivery: boolean): any[] {

    let route = ['/consegne', id];
    // if ((this.groups.includes('admin') || this.groups.includes('partner')) && this.selected == 'active'){
    //   route.push('edit');
    // }

     if (this.groups.includes('admin') && this.selected == 'active'){
         route.push('edit');
     }

     if (this.groups.includes('partner') && this.selected == 'active' && !deluxyDelivery)
     {
         route.push('edit');

     }

     if (this.groups.includes('partner') && this.selected == 'active' && deluxyDelivery)
     {
         return route;
     }


    return route;
  }

  async export() {
    let res;
    if (this.selected == 'active')
      res = await this.deliveryService.getAllActiveXlsx(this.order, this.filtered);
    else
      res = await this.deliveryService.getAllInactiveXlsx(this.order, this.filtered);
    let anchor = document.createElement('a');
    anchor.download = 'export_deluxy.xlsx';
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  import(){
    let el = document.getElementById("importcsv");
    el.click();
  }

  handleImport($event: any) {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        const wb = read(event.target.result, { type: 'array', cellDates: true });
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { raw: false });
          this.import_deliveries = rows;
          let importDeliveries = this.import_deliveries;
          let user = JSON.parse(sessionStorage.getItem("currentUser"));
          importDeliveries.forEach(e => {
            e.createdUser = user.id;
            e.partnerId ? (e.partner = {id: e.partnerId}) : e.partner = null;
            delete e.partnerId;
            e.expertId ? (e.expert = {id: e.expertId}) : e.expert = null;
            delete e.expertId;
            // e.deliveryProducts ? (e.deliveryProducts = e.deliveryProducts.split(",")) : e.deliveryProducts = [];
            e.deliveryProducts = e.deliveryProducts ? e.deliveryProducts.split("|").map(product => product.trim()) : [];
          });

          /* New Code for delivery import */
          const importPromises = importDeliveries.map(async (delivery: any, i: number) => {
            try {
              /* Comman Variables */
              let distanceInKm: any;
              let deliveryAddressLat: any = '';
              let deliveryAddressLng: any = '';
              /* Comman Variables */

              /* Partner Service And Invoice realated variabled */
              let partner: Partner;
              let partnerServices: any[] = [];
              let selectedPartnerService: any;
              let invoicePrice: any = 0;
              let totalTime: any = 1;
              /* Partner Service And Invoice realated variabled */

              /* Expert Service And Salary realated variabled */
              let expert: Expert;
              let expertServices: any[] = [];
              let selectedExpertService: any;
              let valetSalary: any = 0;
              /* Expert Service And Salary realated variabled */

              let dataPromise = this.getLatLngByAddress(delivery?.address);
              let data = await dataPromise;
              deliveryAddressLat = data.lat() || '';
              deliveryAddressLng = data.lng() || '';

              if (delivery?.partner?.id) {
                partner = this.partners.find((partner) => partner?.id == delivery?.partner?.id);
                partnerServices = partner?.partnerServices.length ? partner?.partnerServices : [];
                if (delivery?.service) {
                  selectedPartnerService = partnerServices.find((partnerService) => partnerService?.service?.id == delivery?.service);
                  if (selectedPartnerService?.service?.pricingModel?.toLowerCase() === "fixedprice") {
                    const deliveryAddressLatLngPromise = this.getLatLngByAddress(delivery?.address);
                    const pickUpAddressLatLngPromise = this.getLatLngByAddress(delivery?.pickUpAddress);
                    const [deliveryAddressLatLng, pickUpAddressLatLng] = await Promise.all([deliveryAddressLatLngPromise, pickUpAddressLatLngPromise]);
                    const distanceMatrixService = new google.maps.DistanceMatrixService();
                    await distanceMatrixService.getDistanceMatrix({
                      origins: [pickUpAddressLatLng],
                      destinations: [deliveryAddressLatLng],
                      travelMode: google.maps.TravelMode.DRIVING,
                    }, (response, status) => {
                      if (status === google.maps.DistanceMatrixStatus.OK) {
                        const distance = response.rows[0].elements[0].distance?.value;
                        distanceInKm = (distance / 1000);
                        distanceInKm = distanceInKm?.toFixed(2);
                        if(distanceInKm > partner?.kmIncluded && partner?.kmIncluded){
                          let extraKm: any = (distanceInKm - partner?.kmIncluded).toFixed(2);
                          invoicePrice = parseFloat(selectedPartnerService?.price) + (extraKm * Number(selectedPartnerService?.extraKmPrice));
                        } else {
                          invoicePrice = selectedPartnerService?.price;
                        }
                      } else {
                        console.error('Error calculating distance:', status);
                      }
                    });
                    delivery.serviceType = selectedPartnerService?.service?.pricingModel?.toLowerCase();
                    delivery.serviceName = selectedPartnerService?.service?.serviceName;
                    delivery.distance = distanceInKm;
                    delivery.price = invoicePrice;
                    delivery.startTime = null;
                    delivery.endTime = null;
                    delivery.hours = 0;
                    delivery.productValue = 0;
                  }
                  if (selectedPartnerService?.service?.pricingModel?.toLowerCase() === "hourlyrate") {
                    totalTime = await this.calculateTime(delivery?.startTime, delivery?.endTime);
                    invoicePrice = totalTime !== 0 ? Number(selectedPartnerService?.price) * Number(totalTime) : Number(selectedPartnerService?.price);
                    delivery.serviceType = selectedPartnerService?.service?.pricingModel?.toLowerCase();
                    delivery.serviceName = selectedPartnerService?.service?.serviceName;
                    delivery.price = invoicePrice;
                    delivery.startTime = delivery?.startTime;
                    delivery.endTime = delivery?.endTime;
                    // if(delivery?.startTime && delivery?.endTime){
                    //   delivery.pickUpTime = delivery?.startTime + "-" + delivery?.endTime;
                    // } else if(delivery?.valetStartTime && delivery?.valetEndTime){
                    //   delivery.pickUpTime = delivery?.valetStartTime + "-" + delivery?.valetEndTime;
                    // }
                    if(delivery?.pickUpTime){
                      delivery.pickUpTime = delivery?.pickUpTime
                    } else {
                      delivery.pickUpTime = delivery?.startTime + "-" + delivery?.endTime;
                    }
                    delivery.hours = totalTime;
                    delivery.distance = null;
                    delivery.productValue = 0;
                  }

                  if (selectedPartnerService?.service?.pricingModel?.toLowerCase() === "sales") {
                    let productPrice: number = 0;
                    await Promise.all(delivery?.deliveryProducts.map(async (deliveryProduct) => {
                      try {
                        const [productName, quantity] = deliveryProduct.split(",").map(part => part.trim());
                        const product: Product = await this.productService.findProductByName(productName?.trim());
                        if(product && product?.price){
                          let ProductQuantity = quantity ? quantity : 1;
                          let priceAndQuantity = (product.price * ProductQuantity);
                          productPrice += parseFloat(`${priceAndQuantity}`);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }));
                    delivery.serviceType = selectedPartnerService?.service?.pricingModel?.toLowerCase();
                    delivery.serviceName = selectedPartnerService?.service?.serviceName;
                    delivery.price = (productPrice * selectedPartnerService?.price)/100;
                    delivery.productValue = productPrice;
                    delivery.hours = 0;
                    delivery.startTime = null;
                    delivery.endTime = null;
                    delivery.distance = null;
                  }
                }
              }
              if (delivery?.expert?.id) {
                expert = this.experts.find((expert) => expert?.id == delivery?.expert?.id);
                expertServices = expert?.expertServices.length ? expert?.expertServices : [];
                if (delivery?.expertServiceId) {
                  selectedExpertService = expertServices.find((expertService) => expertService?.id == delivery?.expertServiceId);
                  if (selectedExpertService?.service?.serviceType?.toLowerCase() === "fixedpricesalary") {
                    if(expert?.minimumKmIncluded && distanceInKm > expert?.minimumKmIncluded){
                      let extraKm = (distanceInKm - expert?.minimumKmIncluded);
                      valetSalary = (selectedExpertService?.salary) + (extraKm * Number(selectedExpertService?.minimumKmPrice));
                    } else {
                      valetSalary = selectedExpertService?.salary;
                    }
                    delivery.expertSalary = valetSalary?.toFixed(2);
                    console.log('valetSalary', valetSalary)
                    console.log('delivery.expertSalary', delivery.expertSalary)
                  }
                  if (selectedExpertService?.service?.serviceType?.toLowerCase() === "hourlyratesalary") {
                    let time = await this.calculateTime(delivery?.valetStartTime, delivery?.valetEndTime);
                    valetSalary = (time * selectedExpertService?.salary)?.toFixed(2);
                    delivery.expertSalary = valetSalary;
                  }
                }
              }
              delivery.latitude = deliveryAddressLat;
              delivery.longitude = deliveryAddressLng;
              if(delivery.billable === '0' || delivery.billable === 0 || delivery.billable === false){
                delivery.price = 0;
              }
              if(delivery.payable === '0' || delivery.payable === 0 || delivery.payable === false){
                delivery.expertSalary = 0;
              }
              delivery.billable = delivery.billable;
              delivery.payable = delivery.payable;
              delivery.additionalPrice = delivery.additionalPrice;
              delivery.valetAdditionalPrice = delivery.valetAdditionalPrice;
              await this.deliveryService.import_product_via_csv(delivery);
              await this.loadDeliveries();
              return delivery;
            } catch (error) {
              console.error('Error processing delivery:', error);
              return null;
            }
          });

          const importedDeliveries = await Promise.all(importPromises);
          const successfulDeliveries = importedDeliveries.filter(delivery => delivery !== null);
          if (successfulDeliveries.length === importDeliveries.length) {
            this._snackBar.open("Consegne create aggiornate con successo.", 'Chiudi', {
              direction: "ltr",
              duration: 2000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            });
            await this.loadDeliveries();
          } else {
            this._snackBar.open("Qualcosa è andato storto", 'Chiudi', {
              direction: "ltr",
              duration: 2000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            });
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  importDeliveriesPartner(){
    let el = document.getElementById("importcsvpartner");
    el.click();
  }

  async handlePartnerImport($event: any){
    try {
      const files = $event.target.files;
      if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = async (event: any) => {
          const wb = read(event.target.result, { type: 'array', cellDates: true });
          const sheets = wb.SheetNames;

          if (sheets.length) {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { raw: false });
            this.import_deliveries = rows;
            let importDeliveries = this.import_deliveries;
            let user = JSON.parse(sessionStorage.getItem("currentUser"));
            importDeliveries.forEach(e => {
              e.createdUser = user.id;
              e.partnerId ? (e.partner = {id: e.partnerId}) : e.partner = null;
              delete e.partnerId;
              // e.deliveryProducts ? (e.deliveryProducts = e.deliveryProducts.split(",")) : e.deliveryProducts = [];
              e.deliveryProducts = e.deliveryProducts ? e.deliveryProducts.split("|").map(product => product.trim()) : [];
            });

            /* New Code for delivery import */
            const importPromises = importDeliveries.map(async (delivery: any, i: number) => {
              try {
                /* Comman Variables */
                let distanceInKm: any;
                let deliveryAddressLat: any = '';
                let deliveryAddressLng: any = '';
                /* Comman Variables */

                /* Partner Service And Invoice realated variabled */
                let partner: Partner;
                let partnerServices: any[] = [];
                let selectedPartnerService: any;
                let invoicePrice: any = 0;
                let totalTime: any = 1;
                /* Partner Service And Invoice realated variabled */

                /* Expert Service And Salary realated variabled */
                let expert: Expert;
                let expertServices: any[] = [];
                let selectedExpertService: any;
                let valetSalary: any = 0;
                /* Expert Service And Salary realated variabled */

                let dataPromise = this.getLatLngByAddress(delivery?.address);
                let data = await dataPromise;
                deliveryAddressLat = data.lat() || '';
                deliveryAddressLng = data.lng() || '';

                if (this.loggedInPartner) {
                  partnerServices = this.loggedInPartner?.partnerServices.length ? this.loggedInPartner?.partnerServices : [];
                  if (delivery?.service) {
                    selectedPartnerService = partnerServices.find((partnerService) => partnerService?.service?.id == delivery?.service);
                    if (selectedPartnerService?.service?.pricingModel?.toLowerCase() === "fixedprice") {
                      const deliveryAddressLatLngPromise = this.getLatLngByAddress(delivery?.address);
                      const pickUpAddressLatLngPromise = this.getLatLngByAddress(delivery?.pickUpAddress);
                      const [deliveryAddressLatLng, pickUpAddressLatLng] = await Promise.all([deliveryAddressLatLngPromise, pickUpAddressLatLngPromise]);
                      const distanceMatrixService = new google.maps.DistanceMatrixService();
                      await distanceMatrixService.getDistanceMatrix({
                        origins: [pickUpAddressLatLng],
                        destinations: [deliveryAddressLatLng],
                        travelMode: google.maps.TravelMode.DRIVING,
                      }, (response, status) => {
                        if (status === google.maps.DistanceMatrixStatus.OK) {
                          const distance = response.rows[0].elements[0].distance?.value;
                          distanceInKm = (distance / 1000);
                          distanceInKm = distanceInKm?.toFixed(2);
                          if(distanceInKm > partner?.kmIncluded && partner?.kmIncluded){
                            let extraKm: any = (distanceInKm - partner?.kmIncluded).toFixed(2);
                            invoicePrice = parseFloat(selectedPartnerService?.price) + (extraKm * Number(selectedPartnerService?.extraKmPrice));
                          } else {
                            invoicePrice = selectedPartnerService?.price;
                          }
                        } else {
                          console.error('Error calculating distance:', status);
                        }
                      });
                      delivery.serviceType = selectedPartnerService?.service?.pricingModel?.toLowerCase();
                      delivery.serviceName = selectedPartnerService?.service?.serviceName;
                      delivery.distance = distanceInKm;
                      delivery.price = invoicePrice;
                      delivery.startTime = null;
                      delivery.endTime = null;
                      delivery.hours = 0;
                      delivery.productValue = 0;
                    }
                    if (selectedPartnerService?.service?.pricingModel?.toLowerCase() === "hourlyrate") {
                      if(delivery?.fromTime && delivery?.toTime){
                        totalTime = await this.calculateTime(delivery?.fromTime, delivery?.toTime);
                      }
                      invoicePrice = totalTime !== 0 ? Number(selectedPartnerService?.price) * Number(totalTime) : Number(selectedPartnerService?.price);
                      delivery.serviceType = selectedPartnerService?.service?.pricingModel?.toLowerCase();
                      delivery.serviceName = selectedPartnerService?.service?.serviceName;
                      delivery.price = invoicePrice;
                      delivery.startTime = delivery?.startTime;
                      delivery.endTime = delivery?.endTime;
                      if(delivery?.pickUpTime){
                        delivery.pickUpTime = delivery?.pickUpTime
                      } else {
                        delivery.pickUpTime = delivery?.startTime + "-" + delivery?.endTime;
                      }
                      delivery.hours = totalTime;
                      delivery.distance = null;
                      delivery.productValue = 0;
                    }
                  }
                }
                delivery.latitude = deliveryAddressLat;
                delivery.longitude = deliveryAddressLng;
                await this.deliveryService.import_product_via_csv(delivery);
                await this.loadDeliveries();
                return delivery;
              } catch (error) {
                console.error('Error processing delivery:', error);
                return null;
              }
            });

            const importedDeliveries = await Promise.all(importPromises);
            const successfulDeliveries = importedDeliveries.filter(delivery => delivery !== null);
            if (successfulDeliveries.length === importDeliveries.length) {
              this._snackBar.open("Consegne create aggiornate con successo.", 'Chiudi', {
                direction: "ltr",
                duration: 2000,
                horizontalPosition: "center",
                politeness: "assertive",
                verticalPosition: "top"
              });
              await this.loadDeliveries();
            } else {
              this._snackBar.open("Qualcosa è andato storto", 'Chiudi', {
                direction: "ltr",
                duration: 2000,
                horizontalPosition: "center",
                politeness: "assertive",
                verticalPosition: "top"
              });
            }
          }
        }
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      alert("Something went wrong");
    }
  }

  async getLatLngByAddress(address: string) {
    try {
      let LatLng: any;
      const geocoder = new google.maps.Geocoder();
      const results = await new Promise<any>((resolve, reject) => {
        geocoder.geocode({ 'address': address }, (results, status) => {
          if (status === 'OK') {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });
      if (results && results.length > 0) {
        let lat = results[0].geometry?.location?.lat() || '';
        let lng = results[0].geometry?.location?.lng() || '';
        LatLng = new google.maps.LatLng(lat, lng);
      }
      return LatLng;
    } catch (error) {
      console.error('Geocoding error:', error);
    }
  }

  async calculateTime(startTime: any, endTime: any){
    let [shour, ...srest] = startTime.split(":");
    let [ehour, ...erest] = endTime.split(":");
    let paddedStartTime = `${shour.padStart(2, '0')}:${srest.join(":")}`;
    let paddedEndTime = `${ehour.padStart(2, '0')}:${erest.join(":")}`;
    const startingTime = new Date('1970-01-01T' + `${paddedStartTime}`);
    const endingTime = new Date('1970-01-01T' + `${paddedEndTime}`);
    const timeDiff = endingTime.getTime() - startingTime.getTime();
    let hours = Math.floor(timeDiff / 3600000);
    hours = hours <= 0 ? 1 : hours;
    const minutes = Math.floor((timeDiff % 3600000) / 60000);
    let totalTime: any = hours + minutes / 60;
    totalTime = totalTime.toFixed(2);
    return totalTime;
  }

  downloadCsvFormat(){
    const headings = [[
      "deliveryDate",
      "status",
      "surname",
      "name",
      "fromTime",
      "toTime",
      "pickUpTime",
      "requestExpert",
      "partnerId",
      "expertId",
      "internalNotes",
      "receiverPhone",
      "senderSurname",
      "senderName",
      "senderPhone",
      "notes",
      "email",
      "address",
      "latitude",
      "longitude",
      "payAtDelivery",
      "tryAndReturn",
      "deluxyDelivery",
      "intercom",
      "deliveryProducts",
      "id",
      "startTime",
      "endTime",
      "service",
      "pickUpAddress",
      "additionalPrice",
      "expertServiceId",
      "valetAdditionalPrice",
      "valetStartTime",
      "valetEndTime",
      "billable",
      "payable",
    ]];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.book_append_sheet(wb, ws, 'Deliveries');
    writeFile(wb, 'deliveries.xlsx');
  }

  downloadCsvFormatPartner(){
    const headings = [[
      "deliveryDate",
      "surname",
      "name",
      "fromTime",
      "toTime",
      "pickUpTime",
      "receiverPhone",
      "senderSurname",
      "senderName",
      "senderPhone",
      "notes",
      "email",
      "address",
      "latitude",
      "longitude",
      "payAtDelivery",
      "tryAndReturn",
      "intercom",
      "deliveryProducts",
      "service",
      "pickUpAddress",
    ]];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.book_append_sheet(wb, ws, 'Deliveries');
    writeFile(wb, 'deliveries.xlsx');
  }

  formatTimeShow(fromTime, toTime){
    let trimmedFromTime = fromTime != null ? fromTime?.slice(0, -3) : '';
    let trimmedToTime = toTime != null ? toTime?.slice(0, -3) : '';
    let time = trimmedFromTime + '-' + trimmedToTime;
    return time;
  }

  async deliveryCancellationRequest(id: number){
    this.deliveryCancellationBtn = true;
    if (!confirm("Vuoi inviare richiesta di cancellazione?")){
      this.deliveryCancellationBtn = false;
      return;
    }
    return this.deliveryService.setDeliveryRequestCancellation(id).then(data => {
      this.deliveryCancellationBtn = false;
      this.loadDeliveries();
      this.snack(`Consegna annullata con successo`);
    }).catch(err => {
      this.deliveryCancellationBtn = false;
      this.snack(`consegna non annullata`);
      console.log(err);
    });
  }

  showServiceDetails(incomingDelivery: any){
    let data = this.deliveries.find((delivery) => incomingDelivery.id == delivery.id);
    let service: any;
    if(this.loggedInPartner){
      service = this.loggedInPartner?.partnerServices?.find((item) => item?.service?.id == incomingDelivery.service);
    } else {
      service = data?.partner?.partnerServices.find((item) => item?.service?.id == incomingDelivery.service);
    }
    return service;
  }

  uploadFileOnlyExpert(){

  }


  public async acceptSaleForUniqueProduct(deliveryId: number){
    if (!confirm("Accetti la nuova consegna?"))
      return;
    await this.manageSaleForUniqueProduct(deliveryId, 'accept');
  }

  public async refuseSaleForUniqueProduct(deliveryId: number){
    if (!confirm("Rifiuti la nuova consegna?"))
      return;
    await this.manageSaleForUniqueProduct(deliveryId, 'refuse');
  }

  async manageSaleForUniqueProduct(deliveryId: number, status: string){
    this.saleBtn = true;
    let promise = this.deliveryService.manageSaleForUniqueProduct(deliveryId, status);
    promise.then((res) => {
      this.saleBtn = false;
      this.loadDeliveries();
      this.snack(`Sale ${status}`);
    }).catch((err) => {
      this.saleBtn = false;
      this.snack(`Something went wrong`);
    })

  }

  startInterval() {
    this.intervalId = setInterval(() => {
      this.reloadDeliveries();
    }, 10000); // 10000 milliseconds = 10 seconds
  }

  async reloadDeliveries() {
    await this.loadDeliveries();
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async approveTimings(delivery: Delivery) {
    try {
      let dialogRef  = this.dialog.open(CommanModalComponent, {
        width: '80vw',
        data: {
          component: ApproveTimingsComponent,
          delivery: delivery,
          title: "Approve Timings"
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log('Modal closed with success');
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  async approveService22Timings(delivery: Delivery){

    let dialogRef  = this.dialog.open(CommanModalComponent, {
      width: '80vw',
      data: {
        component: ApproveService22TimingsComponent,
        delivery: delivery,
        title: "Valet Start and End Time"
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Modal closed with success');
      }
    });
  }

  async reviewService22Timings(delivery: Delivery){
    let dialogRef  = this.dialog.open(CommanModalComponent, {
      width: '80vw',
      data: {
        component: ReviewService22TimingsComponent,
        delivery: delivery,
        title: "Review Valet Start and End Time"
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Modal closed with success');
      }
    });
  }

  toggleAccordion(){
    this.isDeliveryMapAccordionOpen = !this.isDeliveryMapAccordionOpen;
  }

  async plotDeliveriesOnMap(){
    const processMap = async (mapId: string, deliveries: Delivery[]) => {
      const map = this.mapHelperService.initializeMap(mapId, { lat: 41.8719, lng: 12.5674 }, 6);
      this.mapHelperService.plotPinsOnMap(map, deliveries);
    }
    try {
      await processMap("deliveriesMap", this.deliveries);
      this.optimized = true;
    } catch (error) {
      this.optimized = false;
    }
  }

  public async accepting(id: number) {
    if (!confirm("Accetti la nuova consegna?"))
      return;
    return this.deliveryService.setDeliveryAccepting(id).then(async (data) => {
      this.snack(`Consegna aggiornata con successo`);
      await this.loadDeliveries();
    }).catch(err => {
      //alert(err);
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
  }
  
  async resetMap(){
    await this.mapHelperService.initializeMap("deliveriesMap", { lat: 41.8719, lng: 12.5674 }, 6);
    this.optimized = false;
  }

}
