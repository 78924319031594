import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpertService } from 'src/app/services/expert.service';
import { SetAvailabilityTimeComponent } from '../set-availability-time/set-availability-time.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Expert, ExpertProvinces } from 'src/app/models/expert.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { UserService } from 'src/app/services/user.service';
import { TeamLeader } from 'src/app/models/team-leader.schema';
import { TeamLeaderService } from 'src/app/services/team-leader.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-valet-own-availability',
  templateUrl: './valet-own-availability.component.html',
  styleUrls: ['./valet-own-availability.component.scss']
})
export class ValetOwnAvailabilityComponent implements OnInit {

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
  };
  expertId: number|string = null;
  expert: any;
  Events: any[] = [];
  eventsLoaded: boolean = false;
  bulkAddForm: any;
  experts: Expert[] = [];
  allDaysCheck: boolean = false;
  monToFriCheck: boolean = false;
  weekendCheck: boolean = false;
  weeklyCheck: boolean = false;
  bulkAddHanldle: boolean = false;
  days: { selected: boolean, startTime: string, endTime: string }[] = [
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' }
  ];
  weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  groups: string[];
  user: any;
  teamLeader: TeamLeader;
  loggedInUserInfo: any;
  loggedInExpert: Expert;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private expertService: ExpertService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private authStatusService: AuthStatusService,
    private userService: UserService,
    private teamLeaderService: TeamLeaderService,
    private spinnerService: SpinnerService,
  ) { 
    this.expertId = this.route.snapshot.paramMap.get('id');
    this.handleAddBulkForm();
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert') && this.loggedInUserInfo){
        this.loggedInExpert = await this.expertService.getOneWithTimeAvailability(this.loggedInUserInfo['extraId']);
      }
      await this.initialize();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong on this page");
    }
  }

  async initialize() {
    this.user = await this.userService.getMyInformation();
    this.eventsLoaded = false;
    if(this.expertId){
      try {
        this.expert = await this.expertService.getOneWithTimeAvailability(this.expertId);
        if (this.groups.includes('expert') && !this.loggedInExpert?.isTeamLeader) {
          if(this.expert?.id != this.user?.expert?.id){
            this.router.navigateByUrl("/404", { skipLocationChange: true });  
          }
        }
        this.expertId = this.expert ? this.expert.id : null;
      } catch (error) {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    await this.setTimeAvailablities();
  }

  async setTimeAvailablities(){
    let Events = this.expert?.timeAvailabilities.map(event => {
      let startTime = event.startTime.slice(0, -3);
      let endTime = event.endTime.slice(0, -3);
      return {
        title: event?.available ? (startTime + ' - ' + endTime) : "N/A",
        start: event.date,
        color: event?.available ? '#c2b55f' : '#e73e3e',
      };
    });
    this.Events = Events;
    this.eventsLoaded = true;
    if (this.Events && this.Events.length > 0) {
      this.calendarOptions.events = Events;
    } else {
      this.calendarOptions.events = [];
    }
  }

  handleDateClick(arg) {

    let data = this.expert?.timeAvailabilities.filter((data: any) => {
      return data?.date == arg.dateStr
    });

    let info = {
      date: arg.dateStr,
      expert: this.expert,
      userType: "expert",
      // createType: data === undefined ? "create" : "update",
      updateAbleData: data,
    }
    let ref = this.dialog.open(SetAvailabilityTimeComponent, { panelClass: 'expert-time-dialog', width: '50vw', data: { info } } );
    ref.afterClosed().subscribe(async (result) => {
      if (result?.success){
        await this.showSnackBar('Time Scheduled Successfully');
        await this.initialize();
      } else if(result?.status === false) {
        this.showSnackBar('Something went wrong');
      }
    });
  }

  handleAddBulkForm() {
    this.bulkAddForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: [''],
      endTime: [''],
      expert: [this.expertId]
    })
  }

  async onSubmit(event: any){
    if(this.bulkAddForm.valid){

      let availabilityCheck = {
        "alldays": this.allDaysCheck,
        "weekend": this.weekendCheck,
        "monToFri": this.monToFriCheck,
        "weekly": this.weeklyCheck
      };

      if(this.bulkAddHanldle){
        this.bulkAddForm.value.days = this.days;
      } else {
        this.bulkAddForm.value.availabilityCheck = availabilityCheck;
      }
      // console.log('this.bulkAddForm.value', this.bulkAddForm.value);
      // return;
      let promise = this.expertService.setTimeAvailabilityInBulk(this.bulkAddForm.value);
      promise.then(async (res) => {
        if(res){
          this.bulkAddHanldle = false;
          await this.showSnackBar("Time Scheduled Successfully");
          await this.initialize();
          this.handleAddBulkForm();
          this.resetDaysCheckAndTime();
          this.resetCheckBoxes();
        }
      }).catch(async (err) => {
        await this.showSnackBar("Something went wrong");
      });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }
  
  checkOnlyOne(checkbox: string) {
    switch (checkbox) {
      case 'allDays':
        this.monToFriCheck = false;
        this.weekendCheck = false;
        this.weeklyCheck = false;
        break;
      case 'monToFri':
        this.allDaysCheck = false;
        this.weekendCheck = false;
        this.weeklyCheck = false;
        break;
      case 'weekend':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        this.weeklyCheck = false;
        break;
      case 'weekly':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        this.weekendCheck = false;
        break;
      default:
        break;
    }
  }

  resetCheckBoxes(){
    this.allDaysCheck = false;
    this.monToFriCheck = false;
    this.weekendCheck = false;
    this.weeklyCheck = false;
  }

  resetDaysCheckAndTime(){
    this.days = [
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' }
    ];
  }
}
