import { Component, OnInit } from '@angular/core';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '../../services/product.service';
import { Product, ProductPartner, ProductVariants, SuperProduct } from '../../models/product.schema';
import { PartnerService } from '../../services/partner.service';
import { AuthStatusService } from '../../services/auth-status.service';
import { plainToClass } from 'class-transformer';
import { ProductCategory } from 'src/app/models/product-category.schema';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AutoGeneratedSkuService } from 'src/app/helpers/autoGeneratedSku.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {
  public groups: string[];
  public partners: any=[];
  public readonly formValidationMsgs;
  public _productForm: any;
  public product: Product;
  public removeFile: boolean;
  public user_extra: {};
  public tmpBtnDisabled: boolean;
  productCategories: ProductCategory[] = [];
  imagePath: string;
  products: Product[] = [];
  selectedProductCategory: ProductCategory;
  productCategoryMetaFields: any[] = [];
  summerNoteConfig = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear', 'heading', 'style']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'link', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private productService: ProductService,
    private partnerService: PartnerService,
    private productCategoryService: ProductCategoryService,
    private spinnerService: SpinnerService,
    private fb: FormBuilder,
    private autoGeneratedSkuService: AutoGeneratedSkuService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.formValidationMsgs = Product.validationMessages;
    this.user_extra = this.authStatusService.getExtra();
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
      if(this.groups.includes('admin') || this.groups.includes('operation')){
        let partners = await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' });
        this.products = (await this.productService.getAllActiveProducts(undefined, undefined, { 'Product.name': 'ASC' })).products;
        this.partners = partners.partners;
      }
      try {
        this.productCategories = await this.productCategoryService.getAllProductCategories();
      } catch (error) {}
      await this.initialize();
      if (this.product) {
        this.products = this.products.filter(product => product.id !== this.product.id);
      }
      // if(this.product['partner']){
      //   this.partners = this.partners.filter(partner => partner.id !== this.product['partner'].id);
      // }
      this.spinnerService.hide();
    }
    catch {
      this.groups = [];
    }
  }

  async initialize() {
    this.tmpBtnDisabled = false;
    this._productForm = new FormGroup(Product.validation());
    const productId: string = this.route.snapshot.paramMap.get('id');
    if (productId) {
      try {
        this.product = await this.productService.getOne(Number(productId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    // if(this.groups.includes('partner')){
    //   this._productForm.controls['productCategory'].setValidators([]);
    //   this._productForm.controls['productCategory'].updateValueAndValidity();
    // }
    if (this.product) {
      this.patch();
    }
    else {
      this.product = new Product();
      if(this.groups.includes('partner')) {
        this._productForm.controls.uniqueProduct.setValue(true);
      }
    }

    if(this.product.sku === '' || this.product.sku === undefined){
      let sku = await this.autoGeneratedSkuService.autoGeneratedSku();
      if(sku){
        this._productForm.controls.sku.setValue(sku);
      }
    }
  }

  async delete() {
    if (!confirm("Sei sicuro di voler cancellare questo prodotto?"))
      return;
    let product: { id: number | string, success: boolean } = await this.productService.delete(this.product.id);
    let message = "Cancellazione del prodotto fallita.";
    if (product.success)
      message = "Cancellazione del prodotto avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    }).afterDismissed().subscribe(result => {
      if (product.success)
        this.router.navigate(['/prodotti']);
    });
  }

  patch() {
    this.removeFile = false;
    [
      'name', 
      'description', 
      'price', 
      'sku',
      'quantity', 
      'unEditable', 
      'uniqueProduct', 
      'visibleToOtherPartners', 
      'productAvailability', 
      'adminApproval', 
      'inStock', 
      'isSuperProduct', 
      'ggDispMin', 
      'productStatus', 
      'isDeluxyProduct', 
      'isCakeProduct', 
      'isFlowersProduct', 
      'shopifyPrice',
      'productAdvantageDesc',
      'cakeDescription',
      'flowersDescription',
      'deluxyDescription',
      'productHasVariants',
      'productOptionTitle'
    ].forEach(param => {
      this._productForm.controls[param].setValue(this.product[param]);
    });

    if (this.product['partner'])
      this._productForm.controls.partner.setValue(this.product['partner'].id);
    if (this.product.category)
      this._productForm.controls.category.setValue(this.product.category.join('; '));
    if (this.product.stock)
      this._productForm.controls.stock.setValue(this.product.stock.join('; '));
    if (this.product.line)
      this._productForm.controls.line.setValue(this.product.line.join('; '));
    if (this.product.productCategory){
      this._productForm.controls.productCategory.setValue(this.product?.productCategory?.id);
      this.selectedProductCategory = this.productCategories.find(c => c.id === this.product?.productCategory?.id);
      if(this.selectedProductCategory.categoryMetaFields){
        this.patchProductCategoryMetaFields(this.product.productCategoryMetaFields);
      }
    }
    if(this.product.image){
      this.imagePath = this.product.image;
    }
    if(this.product.productPartners.length > 0){
      this.patchProductsPartner();
    }
    if(this.product.superProducts.length > 0){
      this.patchSuperProducts();
    }
    if(this.product.productVariants.length > 0){
      this.patchProductVariants(this.product.productVariants);
    }
  } 

  patchProductsPartner(){
    const control = <FormArray>this._productForm.get('productPartners');
    control.clear();
    if (!this.product.productPartners || Array.isArray(this.product.productPartners) && this.product.productPartners.length == 0){
      this.product.productPartners = [new ProductPartner()];
    }
    this.product.productPartners.forEach((f, index) => {
      control.push(this.patchValues(f, index));
    });
  }

  patchValues(productPartners: ProductPartner, index: number) {
    let productPartner = Product.productPartnerFormGroup();
    productPartner.controls.partner.setValue(
      { 
        id: productPartners?.partner?.id, 
      }
    );
        
    return productPartner;
  }

  patchProductCategoryMetaFields(productCategoryMetaFields: any) {
    let metaFields: any[] = productCategoryMetaFields ? JSON.parse(productCategoryMetaFields) : [];
    this.populateMetaFields(metaFields);
  }

  onSubmit() {
    try {
      this.tmpBtnDisabled = true;
      let product: Product = new FormGroupToObject().transform(this._productForm);
      let image = product.image;
      if(this.groups.includes('partner')){
        product['partner'] = this.user_extra['id'];
        
      }
      if (!this.removeFile) delete product.image;
      delete product.image;

      product = plainToClass(Product, product);
      let promiseResult: any;
      let createType: boolean = true;
      // delete product.image;
      if(!this._productForm.get('partner').value){
        delete product.partner
      }

      if(this.groups.includes('partner')){
        product.partner = this.user_extra['id'];
        delete product?.productPartners;
        delete product?.superProducts;
      }
  
      if(product.productCategoryMetaFields){
        product.productCategoryMetaFields = product.productCategoryMetaFields ? JSON.stringify(product.productCategoryMetaFields) : null;
      }
      if(this.groups.includes('admin') || this.groups.includes('operation')){
        if(product?.productPartners[0]?.partner?.id === null || product?.productPartners[0]?.partner?.id === undefined || product?.productPartners[0]?.partner?.id === ''){
          delete product?.productPartners;
        }
      }

      if(this.groups.includes('admin') || this.groups.includes('operation')){
        if(product?.superProducts[0]?.product?.id === null || product?.superProducts[0]?.product?.id === undefined || product?.superProducts[0]?.product?.id === ''){
          delete product?.superProducts;
        }
      }

      /*
      if(this.groups.includes('admin') || this.groups.includes('operation')){
        if(!product?.productVariants.length){
          delete product?.productVariants;
        }
      }
      */
      // console.log("product", product); return;

      if (this.product['id']) {
        promiseResult = this.productService.update(this.product['id'], product);
        createType = false;
      }
      else
        promiseResult = this.productService.create(product);
      promiseResult
      .then(async (data: Product) => {
        let allGood = true;
        this.product = await this.productService.getOne(data?.id);
        if (image){
          this.product = await this.uploadImage(this.product.id).catch((err) => {
            this.errorMessage(createType, err);
            allGood = false;
          });
        } if(this.removeFile) {
          this.product = await this.productService.updateProductPhoto(this.product.id, null).catch((err) => {
            this.errorMessage(createType, err);
            allGood = false;
          });
        }
        if (allGood) {
          const message: string = `Prodotto ${createType ? 'creato' : 'aggiornato'} con successo`;
          this._snackBar.open(message, 'Chiudi', {
            direction: 'ltr',
            duration: 2000,
            horizontalPosition: 'center',
            politeness: 'assertive',
            verticalPosition: 'top',
          }).afterDismissed().subscribe(result => {
            if (createType)
              this.router.navigate(['/prodotti', this.product.id]);
          });
          this.tmpBtnDisabled = false;
          this.patch();
        }
      })
      .catch((error) => {
        this.tmpBtnDisabled = false;
        this.errorMessage(createType, error);
      });
    } catch (error) {
      console.log("ERROR", error?.message)
    }
  }

  errorMessage(createType, error) {
    let message = `Errore ${createType ? 'nella creazione' : "nell'aggiornamento"} del prodotto`;
    if (error.error && error.error.message === 'DUPLICATE.PRODUCT')
      message = `Errore: esiste già un prodotto con questo nome!`;
    if (error.error && error.error.message === 'DUPLICATE.PRODUCT_SKU')
      message = `Errore: esiste già un prodotto con questo sku!`;
    if(error.error && error.error.message === 'PRODUCT.ONLY_EDITABLE_ADMINISTRATION_SIDE'){
      message = `PRODOTTO SOLO LATO AMMINISTRAZIONE MODIFICABILE`
    }
    this._snackBar.open(message,
      'Chiudi',
      {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }
    );
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.removeFile = false;
      const file = event.target.files[0];
      this._productForm.get('image').setValue(file);
    }
  }

  uploadImage(productId: number) {
    return this.productService.updateProductPhoto(
      productId,
      this._productForm.get('image').value
    );
  }

  deleteFile() {
    this.removeFile = true;
  }

  openImage(imageUrl: string) {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }

  addPartner() {
    const control = <FormArray>this._productForm.get('productPartners');
    control.push(Product.productPartnerFormGroup());
  }

  removePartner(index: number) {
    const control = <FormArray>this._productForm.get('productPartners');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
  }

  isPartnerOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const partners of this._productForm.value.productPartners) {
      if (partners.partner.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId;
  }

  patchSuperProducts(){
    const control = <FormArray>this._productForm.get('superProducts');
    control.clear();
    if (!this.product.superProducts || Array.isArray(this.product.superProducts) && this.product.superProducts.length == 0){
      this.product.superProducts = [new SuperProduct()];
    }
    this.product.superProducts.forEach((f, index) => {
      control.push(this.patchSuperProductValues(f, index));
    });
  }

  patchSuperProductValues(superProducts: SuperProduct, index: number) {
    let superProduct = Product.superProductFormGroup();
    superProduct.controls.product.setValue(
      { 
        id: superProducts?.product?.id, 
      }
    );
        
    return superProduct;
  }

  addProduct() {
    const control = <FormArray>this._productForm.get('superProducts');
    control.push(Product.superProductFormGroup());
  }

  removeProduct(index: number) {
    const control = <FormArray>this._productForm.get('superProducts');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
  }

  isProductOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const products of this._productForm.value.superProducts) {
      if (products?.product?.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId;
  }

  handlePartnerChange(partnerId: number){
    // if(partnerId){
    //   this.partners = this.partners.filter(p => p.id != partnerId);
    // }
  }

  handleProductCategoryChange(categoryId: any){
    this.selectedProductCategory = this.productCategories.find(c => c.id == categoryId);
    this.populateMetaFields();
  }

  get metaFields(): FormArray {
    return this._productForm.get('productCategoryMetaFields') as FormArray;
  }

  populateMetaFields(metaFields?: any[]) {
    this.metaFields.clear();
    this.productCategoryMetaFields = this.selectedProductCategory.categoryMetaFields ? JSON.parse(this.selectedProductCategory.categoryMetaFields) : [];
    this.formValidationMsgs['productCategoryMetaFields'] = [];
    this.productCategoryMetaFields.forEach((field: any, index: number) => {
      const value = metaFields && metaFields[index] ? metaFields[index] : '';
      const control = this.fb.control(value, ((field.type === "1" || field.type === "3") && this.groups.includes('admin') ? Validators.required : []));
      this.metaFields.push(control);
      this.formValidationMsgs[field.fieldName] = [{ type: 'required', message: `${field.fieldName} is Obbligatorio.` }];
    });
  }

  patchProductVariants(productVariantsData: ProductVariants[]){
    const formArray = this.productVariantsFields;
    formArray.clear();
    productVariantsData.forEach(field => {
      formArray.push(new FormGroup({
        id: new FormControl(field.id),
        variantName: new FormControl(field.variantName),
        variantSku: new FormControl(field.variantSku),
        variantPrice: new FormControl(field.variantPrice),
        variantShopifyPrice: new FormControl(field.variantShopifyPrice),
        variantGgDispMin: new FormControl(field.variantGgDispMin),
        variantInStock: new FormControl(field.variantInStock),
        variantAvailability: new FormControl(field.variantAvailability, Validators.compose([Validators.min(0)])),
      }));
    });
  }

  get productVariantsFields(): FormArray {
    return this._productForm.get('productVariants') as FormArray;
  }

  addProductVariant() {
    const control = this._productForm.get('productVariants') as FormArray;
    control.push(new FormGroup(ProductVariants.validation()));
  }

  removeProductVariant(index: number) {
    const control = this._productForm.get('productVariants') as FormArray;
    control.removeAt(index);
    if (control.length > 1) {
    }
  }

  handleShopCheckbox(event: any, shopName: string) {
    if (!event.target.checked) return;
  
    const keyPointsMap = {
      deluxy: `
        <li>Inclusi: biglietto scritto a mano e confezione regalo</li>
        <li>Consegna: in guanti bianchi, a casa o dove vuoi tu</li>`,
      cake: `
        <li>Personalizza: scegli forma, gusti e topping</li>
        <li>Consegna dove vuoi tu nel mondo</li>`,
      flowers: `
        <li>Inclusi: biglietto scritto a mano e confezione regalo</li>
        <li>Consegna inclusa dove vuoi tu</li>`,
    };
  
    const key = shopName.toLowerCase();
    if (!keyPointsMap[key]) return;
  
    let text = `
      <ul>
        <li>${this.selectedProductCategory.categoryName || ''}: 
        ${this._productForm.get('productAdvantageDesc').value || ''}</li>
        ${keyPointsMap[key]}
      </ul>
      <h6>DESCRIZIONE</h6>
      <p>${this._productForm.get('description').value || ''}</p>
    `.replace(/\s+/g, ' ').trim();
  
    this.productCategoryMetaFields.forEach((metaField, i) => {
      text += `<h6>${metaField.fieldName}</h6><p>${this._productForm.get('productCategoryMetaFields').value[i]}</p>`;
    });
  
    this._productForm.get(`${key}Description`).setValue(text);
  }

  productHasVariant(event: any) {
    const checked: boolean = event.target.checked;
    const productOptionTitleControl = this._productForm.get('productOptionTitle');
  
    if (checked) {
      productOptionTitleControl?.setValidators([Validators.required]);
    } else {
      productOptionTitleControl?.clearValidators();
    }
  
    productOptionTitleControl?.updateValueAndValidity();
  }
  

}
