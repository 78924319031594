import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Transform } from 'class-transformer';
import { fileType } from 'src/app/helpers/validators/file-type.validator';
import { Partner } from './partner.schema';

export const ProductStatus = [
    { value: '1', ln: { it: 'Attivo' } },
    { value: '0', ln: { it: 'Disattivo' } }
]

export const YesNo = [
    { value: 1, ln: { it: 'Yes' } },
    { value: 0, ln: { it: 'No' } }
]

export class Product {
    public static validation(): { [key: string]: AbstractControl } {
        let numberPattern = /^[0-9]+(\.[0-9]*)?$/;
        let validator: { [key: string]: AbstractControl } = {
            name: new FormControl('', Validators.required),
            sku: new FormControl('', Validators.required),
            partner: new FormControl(''),
            description: new FormControl(''),
            category: new FormControl(''),
            stock: new FormControl(''),
            line: new FormControl(''),
            price: new FormControl(0, Validators.compose([Validators.min(0)])),
            notes: new FormControl(''),
            quantity: new FormControl('', Validators.pattern(numberPattern)),
            image: new FormControl(
                undefined,
                fileType(['png', 'jpg', 'gif', 'jpeg'])
            ),
            unEditable: new FormControl(false),
            productCategory: new FormControl('', Validators.required),
            uniqueProduct: new FormControl(''),
            visibleToOtherPartners: new FormControl(false),
            productPartners: new FormArray([new FormGroup(ProductPartner.validation())]),
            productAvailability: new FormControl(''),
            adminApproval: new FormControl(false),
            inStock: new FormControl(true),
            isSuperProduct: new FormControl(false),
            superProducts: new FormArray([new FormGroup(SuperProduct.validation())]),
            ggDispMin: new FormControl(0),
            isDeluxyProduct: new FormControl(false),
            isCakeProduct: new FormControl(false),
            isFlowersProduct: new FormControl(false),
            productStatus: new FormControl(true),
            productCategoryMetaFields: new FormArray([new FormGroup(CategoryMetaField.validation())]),
            productVariants: new FormArray([]),
            // productVariants: new FormArray([new FormGroup(ProductVariants.validation())]),
            shopifyPrice: new FormControl('', Validators.compose([Validators.min(0)])),
            productAdvantageDesc: new FormControl('', Validators.compose([
                Validators.required,
                Validators.maxLength(80)
            ])),
            deluxyDescription: new FormControl(''),
            flowersDescription: new FormControl(''),
            cakeDescription: new FormControl(''),
            productHasVariants: new FormControl(false),
            productOptionTitle: new FormControl(''),
        };
        return validator;
    }

    public static readonly validationMessages = {
        name: [{ type: 'required', message: 'Obbligatorio' }],
        price: [{ type: 'min', message: 'Deve essere positivo' }],
        shopifyPrice: [{ type: 'min', message: 'Deve essere positivo' }],
        image: [
            { type: 'fileType', message: 'Formati accettati: png, jpg, gif, jpeg' },
        ],
        quantity: [{ type: 'pattern', message: 'La quantità può essere solo numero e float' }],
        productCategory: [{ type: 'required', message: 'Obbligatorio' }],
        productVariants: {
            variantName: [{ type: 'required', message: 'Obbligatorio' }],
            variantSku: [{ type: 'required', message: 'Obbligatorio' }],
            variantPrice: [
                { type: 'required', message: 'Obbligatorio' },
                { type: 'min', message: 'Deve essere positivo' },
            ],
            variantShopifyPrice: [
                { type: 'required', message: 'Obbligatorio' },
                { type: 'min', message: 'Deve essere positivo' },
            ],
            variantGgDispMin: [
                { type: 'required', message: 'Obbligatorio' },
                { type: 'min', message: 'Deve essere positivo' },
            ],
        },
        sku: [{ type: 'required', message: 'Obbligatorio' }],
        productAdvantageDesc: [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'maxlength', message: 'Puoi aggiungere al massimo 80 caratteri' }
        ],
        productOptionTitle: [{ type: 'required', message: 'Obbligatorio' }],
    };

    public static productPartnerFormGroup() {
        return new FormGroup(ProductPartner.validation());
    }

    public static superProductFormGroup() {
        return new FormGroup(SuperProduct.validation());
    }

    constructor(
        public id?: number,
        public name?: string,
        public sku?: string,
        public description?: string,
        price?: number,
        category?: string[],
        line?: string[],
        stock?: string[],
        public image?: any,
        public partner?: any,
        public updatedAt?: Date,
        public createdAt?: Date,
        public quantity?:string,
        public unEditable?: boolean,
        public productCategory?: any,
        public uniqueProduct?: boolean,
        public productStatus?: number,
        public productPartners?: any,
        public productAvailability?: number,
        public adminApproval?: boolean,
        public inStock?: boolean,
        public isSuperProduct?: boolean,
        public superProducts?: any,
        public ggDispMin?: number,
        public isDeluxyProduct?: boolean,
        public isCakeProduct?: boolean,
        public isFlowersProduct?: boolean,
        public productCategoryMetaFields?: string,
        public shopifyPrice?: number,
        public productVariants?: any,
        public productAdvantageDesc?: string,
        public cakeDescription?: string,
        public flowersDescription?: string,
        public deluxyDescription?: string,
        public productHasVariants?: boolean,
        public productOptionTitle?: boolean,
    ) {
        this.price = price;
        this.category = category;
        this.line = line;
        this.stock = stock;

    }

    @Transform(toNumber, { toClassOnly: true }) public price?: number;
    @Transform(toArray, { toClassOnly: true }) public category?: string[];
    @Transform(toArray, { toClassOnly: true }) public line?: string[];
    @Transform(toArray, { toClassOnly: true }) public stock?: string[];
}

export function toArray({ value, key, obj, type }) {
    return value ? value.split(';').map(el => el.trim()) : [];
}

function toNumber({ value, key, obj, type }) {
    if ([undefined, null, ''].includes(value))
        return null;
    return Number(value);
}

export class ProductPartner {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            partner: this.productFormGroup(),
        };
        return validator;
    }

    public static productFormGroup() {
        return new FormGroup({
            id: new FormControl(''),
        });
    }

    constructor(
        public id?: number,
        public partner?: Partner,
    ) { }
}

export class SuperProduct {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            product: this.superProductFormGroup(),
        };
        return validator;
    }

    public static superProductFormGroup() {
        return new FormGroup({
            id: new FormControl(''),
        });
    }

    constructor(
        public id?: number,
        public product?: Product,
    ) { }
}

export class CategoryMetaField {
    public static validation(): { [key: string]: AbstractControl } {
        return {
            fieldName: new FormControl(''),
            type: new FormControl('1'),
        };
    }

    constructor(
        public fieldName?: string,
        public type?: string
    ) { }
}

export class ProductVariants {
    public static validation(): { [key: string]: AbstractControl } {
        return {
            variantName: new FormControl('', Validators.required),
            variantSku: new FormControl('', Validators.required),
            variantPrice: new FormControl(0, Validators.compose([Validators.min(0)])),
            variantShopifyPrice: new FormControl(0, Validators.compose([Validators.min(0)])),
            variantGgDispMin: new FormControl(0, Validators.compose([Validators.min(0)])),
            variantInStock: new FormControl(true),
            variantAvailability: new FormControl('', Validators.compose([Validators.min(0)])),
        };
    }

    constructor(
        public id?: number,
        public variantName?: string,
        public variantSku?: string,
        public variantPrice?: number,
        public variantShopifyPrice?: number,
        public variantGgDispMin?: number,
        public variantInStock?: boolean,
        public variantAvailability?: number,
    ) { }
}
