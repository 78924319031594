import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from 'src/app/services/partner.service';
import { CalendarOptions } from '@fullcalendar/core';
import { MatDialog } from '@angular/material/dialog';
import { SetAvailabilityTimeComponent } from '../set-availability-time/set-availability-time.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { Partner, PartnerProvinces } from 'src/app/models/partner.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { UserService } from 'src/app/services/user.service';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { TeamLeaderService } from 'src/app/services/team-leader.service';
import { Expert, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { ExpertService } from 'src/app/services/expert.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-partner-availability',
  templateUrl: './partner-availability.component.html',
  styleUrls: ['./partner-availability.component.scss']
})
export class PartnerAvailabilityComponent implements OnInit {

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    datesSet: this.onDatesSet.bind(this),
  };
  partnerId: number|string = null;
  partner: any;
  Events: any[] = [];
  eventsLoaded: boolean = false;
  bulkAddForm: any;
  bulkEditForm: any;
  bulkEditAvailabilityTimeForm: any;
  allDateTimeforEdit: { [key: string]: any[] } = {}; // Initialize to an empty object
  partners: Partner[] = [];
  days: { selected: boolean, startTime: string, endTime: string }[] = [
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' }
  ];
  weekDays = ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];
  allDaysCheck: boolean = false;
  monToFriCheck: boolean = false;
  weekendCheck: boolean = false;
  weeklyCheck: boolean = false;
  bulkAddHanldle: boolean = false;
  bulkEditHanldle: boolean = false;
  groups: string[];
  user: any;
  teamLeader: TeamLeader;
  loggedInUserInfo: any;
  loggedInExpert: Expert;
  dateRangeObject: { fromDate: string; toDate: string };
  minDate: string;
  minEndDate: string = '';
  currentYearStart: string = '';
  currentYearEnd: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private partnerService: PartnerService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private authStatusService: AuthStatusService,
    private userService: UserService,
    private teamLeaderService: TeamLeaderService,
    private expertService: ExpertService,
    private spinnerService: SpinnerService,
  ) {
    this.partnerId = this.route.snapshot.paramMap.get('id');
    this.handleAddBulkForm();
    this.handleSearchBulkForm();
    this.handleEditAvailabilityTimeBulkForm();
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
    this.SetMinDate();
  }
  async SetMinDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.minDate = `${year}-${month}-${day}`;

    this.currentYearStart = `${year}-01-01`;
    this.currentYearEnd = `${year}-12-31`;
  }
  async onStartDateChange() {
    const startDate = this.bulkAddForm.get('startDate')?.value;
    if (startDate) {
      this.minEndDate = startDate; // Set the `min` attribute of the `endDate` input
      // Optional: Reset `endDate` if it’s earlier than `startDate`
      const endDate = this.bulkAddForm.get('endDate')?.value;
      if (endDate && new Date(endDate) < new Date(startDate)) {
        this.bulkAddForm.get('endDate')?.setValue('');
      }
    }
  }
  async onStartDateChangeForEdit() {
    const startDate = this.bulkEditForm.get('startDate')?.value;
    if (startDate) {
      this.minEndDate = startDate; // Set the `min` attribute of the `endDate` input
      // Optional: Reset `endDate` if it’s earlier than `startDate`
      const endDate = this.bulkEditForm.get('endDate')?.value;
      if (endDate && new Date(endDate) < new Date(startDate)) {
        this.bulkEditForm.get('endDate')?.setValue('');
      }
    }
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert') && this.loggedInUserInfo){
        this.loggedInExpert = await this.expertService.getOneWithTimeAvailability(this.loggedInUserInfo['extraId']);
      }
      await this.initialize();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something Went Wrong On This Page");
    }
  }

  async initialize() {
    this.user = await this.userService.getMyInformation();
    this.eventsLoaded = false;
    if ((this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader') || this.groups.includes('expert'))) {
      try {
        this.partners = (await this.partnerService.getAllActivePartnersWithTimeAvailabilities(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
        if(this.groups.includes('expert') && this.loggedInExpert?.isTeamLeader){
          if (this.loggedInExpert.expertTeamLeaderProvinces.length > 0) {
            this.partners = this.partners.filter((partner: Partner) => {
              if(partner.partnerProvinces.length > 0){
                return partner.partnerProvinces.some((pProvince: PartnerProvinces) => {
                  if(pProvince.province != null){
                    return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
                      return pProvince.province.provinceCode.toLowerCase() === expertTeamLeaderProvince.province.provinceCode.toLowerCase()
                    });
                  }
                });
              }
            });
          }
        }
      }
      catch { }
    }
    if(this.partnerId){
      try {
        this.partner = await this.partnerService.getOneWithTimeAvailability(this.partnerId);
        if (this.groups.includes('partner')) {
          if(this.partner?.id != this.user?.partner?.id){
            this.router.navigateByUrl("/404", { skipLocationChange: true });
          }
        }
        this.partnerId = this.partner ? this.partner.id : null;
      } catch (error) {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }

    await this.setTimeAvailablities();
  }

  async setTimeAvailablities(){
    let Events = this.partner?.timeAvailabilities.map(event => {
      let startTime = event.startTime.slice(0, -3);
      let endTime = event.endTime.slice(0, -3);
      return {
        title: event?.available ? (startTime + ' - ' + endTime) : "N/A",
        start: event.date,
        color: event?.available ? '#c2b55f' : '#e73e3e',
      };
    });
    this.Events = Events;
    this.eventsLoaded = true;
    if (this.Events && this.Events.length > 0) {
      this.calendarOptions.events = Events;
    } else {
      this.calendarOptions.events = [];
    }
  }

  handleDateClick(arg) {

    let data = this.partner?.timeAvailabilities.filter((data: any) => {
      return data?.date == arg.dateStr
    });

    let info = {
      date: arg.dateStr,
      partner: this.partner,
      userType: "partner",
      updateAbleData: data
    }

    let ref = this.dialog.open(SetAvailabilityTimeComponent, { panelClass: 'partner-time-dialog', width: '50vw', data: { info } });
    ref.afterClosed().subscribe(async (result) => {
      if (result?.success){
        await this.showSnackBar('Time Scheduled Successfully');
        await this.initialize();
      } else if(result?.success === false){
        this.showSnackBar('Something went wrong');
      }
    });
  }

  handleAddBulkForm() {
    this.bulkAddForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: [''],
      endTime: [''],
      partner: [this.partnerId]
    })
  }
  handleSearchBulkForm() {
    this.bulkEditForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      partner: [this.partnerId]
    })
  }
  handleEditAvailabilityTimeBulkForm() {
    this.bulkEditAvailabilityTimeForm = this.fb.group({
      startTime: [''],
      endTime: [''],
      partner: [this.partnerId]
    })
  }

  async onSubmit(event: any){
    if(this.bulkAddForm.valid){

      let availabilityCheck = {
        "alldays": this.allDaysCheck,
        "weekend": this.weekendCheck,
        "monToFri": this.monToFriCheck,
        "weekly": this.weeklyCheck
      };

      if(this.bulkAddHanldle){
        this.bulkAddForm.value.days = this.days;
      } else {
        this.bulkAddForm.value.availabilityCheck = availabilityCheck;
      }

      let promise = this.partnerService.setTimeAvailabilityInBulk(this.bulkAddForm.value);
      promise.then(async (res) => {
        if(res){
          this.bulkAddHanldle = false;
          await this.showSnackBar("Time Scheduled Successfully");
          await this.initialize();
          this.handleAddBulkForm();
          this.resetDaysCheckAndTime();
          this.resetCheckBoxes();
        }
      }).catch(async (err) => {
        await this.showSnackBar("Something went wrong");
      });
    }
  }


  async onSearch(event: any){
    if(this.bulkEditForm.valid){
      this.dateRangeObject = {
        fromDate: this.bulkEditForm.value.startDate,
        toDate: this.bulkEditForm.value.endDate
      }
      let promise = await this.partnerService.getTimeAvailavialityWithinDateRange(this.dateRangeObject, this.partnerId);
      this.allDateTimeforEdit = Object.keys(promise.timeAvailabilities.reduce((acc, current) => {
        const date = current.date;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(current);
        return acc;
      }, {})).sort().reduce((acc, date) => {
        acc[date] = promise.timeAvailabilities.filter((item) => item.date === date);
        return acc;
      }, {});
    }
  }

  get allDateKeys(): string[] {
    return Object.keys(this.allDateTimeforEdit);
  }

  async removeAvailabilityTime(date, index) {
    const dataArray = this.allDateTimeforEdit[date];
    if (dataArray.length > 1) {
      dataArray.splice(index, 1);
    }
  }

  async toggleOnlyOne(id){
    if(id === 'bulkAddHanldeCheckbox'){
      this.bulkAddHanldle = !this.bulkAddHanldle;
      this.bulkEditHanldle= false;
    } else if(id === 'bulkEditHanldeCheckbox'){
      this.bulkEditHanldle = !this.bulkEditHanldle;
      this.bulkAddHanldle = false;
    }
  }

  async onUpdate(event: any){
    // console.log(this.bulkEditAvailabilityTimeForm);
    if(this.bulkEditAvailabilityTimeForm.valid){
      const collectedData = this.allDateKeys.map(date => ({
        partner: this.partnerId,
        date,
        times: this.allDateTimeforEdit[date],
      }));

      $('#updateBlukTime').prop('disabled', true);

      let promise = this.partnerService.UpdateTimeAvailabilityInBulk(collectedData);
      promise.then(async (res) => {
        if(res){
          // this.bulkEditHanldle = false;
          await this.initialize();
          this.handleSearchBulkForm();
          this.resetDaysCheckAndTime();
          this.resetCheckBoxes();
          this.allDateTimeforEdit = {};
          await this.showSnackBar("Time Scheduled Update Successfully");
        }
      }).catch(async (err) => {
        await this.showSnackBar("Something went wrong");
      });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async handlePartnerChange(partnerId: any){
    try {
      this.spinnerService.show();
      this.partner = await this.partnerService.getOneWithTimeAvailability(this.partnerId);
      this.router.navigate(['partner', this.partner?.id, 'availability']);
      this.partnerId = this.partner?.id;
      await this.setTimeAvailablities();
      this.handleAddBulkForm();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong");
    }
  }

  resetDaysCheckAndTime(){
    this.days = [
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' }
    ];
  }

  checkOnlyOne(checkbox: string) {
    switch (checkbox) {
      case 'allDays':
        this.monToFriCheck = false;
        this.weekendCheck = false;
        this.weeklyCheck = false;
        break;
      case 'monToFri':
        this.allDaysCheck = false;
        this.weekendCheck = false;
        this.weeklyCheck = false;
        break;
      case 'weekend':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        this.weeklyCheck = false;
        break;
      case 'weekly':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        this.weekendCheck = false;
        break;
      default:
        break;
    }
  }

  resetCheckBoxes(){
    this.allDaysCheck = false;
    this.monToFriCheck = false;
    this.weekendCheck = false;
    this.weeklyCheck = false;
  }

  async onDatesSet(event) {
    try {
      const { start, end } = event;
      const formattedStart = this.formatDate(start);
      const formattedEnd = this.formatDate(end);
      this.dateRangeObject = {
        fromDate: formattedStart,
        toDate: formattedEnd
      }
      let promise = await this.partnerService.getTimeAvailavialityWithinDateRange(this.dateRangeObject, this.partnerId);
      // console.log("promise", promise)
    } catch (error) {
      console.log("ERROR", error?.message);
    }
  }

  formatDate(date: Date) {
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }


}

/*
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from 'src/app/services/partner.service';
import { CalendarOptions } from '@fullcalendar/core';
import { MatDialog } from '@angular/material/dialog';
import { SetAvailabilityTimeComponent } from '../set-availability-time/set-availability-time.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { Partner, PartnerProvinces } from 'src/app/models/partner.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { UserService } from 'src/app/services/user.service';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { TeamLeaderService } from 'src/app/services/team-leader.service';
import { Expert, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { ExpertService } from 'src/app/services/expert.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-partner-availability',
  templateUrl: './partner-availability.component.html',
  styleUrls: ['./partner-availability.component.scss']
})
export class PartnerAvailabilityComponent implements OnInit {

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    datesSet: this.onDatesSet.bind(this),
  };
  partnerId: number|string = null;
  partner: any;
  Events: any[] = [];
  eventsLoaded: boolean = false;
  bulkAddForm: any;
  partners: Partner[] = [];
  days: { selected: boolean, startTime: string, endTime: string }[] = [
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' }
  ];
  weekDays = ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];
  allDaysCheck: boolean = false;
  monToFriCheck: boolean = false;
  weekendCheck: boolean = false;
  weeklyCheck: boolean = false;
  bulkAddHanldle: boolean = false;
  groups: string[];
  user: any;
  teamLeader: TeamLeader;
  loggedInUserInfo: any;
  loggedInExpert: Expert;
  dateRangeObject: { fromDate: string; toDate: string };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private partnerService: PartnerService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private authStatusService: AuthStatusService,
    private userService: UserService,
    private teamLeaderService: TeamLeaderService,
    private expertService: ExpertService,
    private spinnerService: SpinnerService,
  ) {
    this.partnerId = this.route.snapshot.paramMap.get('id');
    this.handleAddBulkForm();
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert') && this.loggedInUserInfo){
        this.loggedInExpert = await this.expertService.getOneWithTimeAvailability(this.loggedInUserInfo['extraId']);
      }
      await this.initialize();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something Went Wrong On This Page");
    }
  }

  async initialize() {
    try {
      this.user = await this.userService.getMyInformation();
      this.eventsLoaded = false;
      if ((this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('expert') || this.groups.includes('partner'))) {
        if(this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('expert')){
          this.partners = (await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
        }
        if (this.groups.includes('expert') && this.loggedInExpert?.isTeamLeader) {
          this.filterPartnersForExpert();
        }
      }
      await this.setTimeAvailablities(this.partnerId);
    } catch (error) {
      console.log("ERROR", error)
      this.router.navigateByUrl("/404", { skipLocationChange: true });
    }
  }

  filterPartnersForExpert() {
    const provinces = this.loggedInExpert.expertTeamLeaderProvinces;
    this.partners = this.partners.filter((partner: Partner) =>
      partner.partnerProvinces.some((pProvince: PartnerProvinces) =>
        provinces.some((eProvince: ExpertTeamLeaderProvinces) =>
          pProvince.province?.provinceCode.toLowerCase() === eProvince.province?.provinceCode.toLowerCase()
        )
      )
    );
  }

  async setTimeAvailablities(partnerId: string|number){
    try {
      this.partner = await this.partnerService.getTimeAvailavialityWithinDateRange(this.dateRangeObject, this.partnerId);
      if (this.groups.includes('partner') && this.partner?.id !== this.loggedInUserInfo['extraId']) {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
      console.log("this.partner", this.partner);
      this.partnerId = this.partner ? this.partner.id : null;
      let Events = this.partner?.timeAvailabilities.map(event => {
        let startTime = event.startTime.slice(0, -3);
        let endTime = event.endTime.slice(0, -3);
        return {
          title: event?.available ? (startTime + ' - ' + endTime) : "N/A",
          start: event.date,
          color: event?.available ? '#c2b55f' : '#e73e3e',
        };
      });
      this.Events = Events;
      this.eventsLoaded = true;
      if (this.Events && this.Events.length > 0) {
        this.calendarOptions.events = Events;
      } else {
        this.calendarOptions.events = [];
      }
    } catch (error) {
      console.log("Error", error)
    }
  }

  handleDateClick(arg) {

    let data = this.partner?.timeAvailabilities.filter((data: any) => {
      return data?.date == arg.dateStr
    });

    let info = {
      date: arg.dateStr,
      partner: this.partner,
      userType: "partner",
      updateAbleData: data
    }

    let ref = this.dialog.open(SetAvailabilityTimeComponent, { panelClass: 'partner-time-dialog', width: '50vw', data: { info } });
    ref.afterClosed().subscribe(async (result) => {
      if (result?.success){
        await this.showSnackBar('Time Scheduled Successfully');
        await this.initialize();
      } else if(result?.success === false){
        this.showSnackBar('Something went wrong');
      }
    });
  }

  handleAddBulkForm() {
    this.bulkAddForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: [''],
      endTime: [''],
      partner: [this.partnerId]
    })
  }

  async onSubmit(event: any){
    if(this.bulkAddForm.valid){

      let availabilityCheck = {
        "alldays": this.allDaysCheck,
        "weekend": this.weekendCheck,
        "monToFri": this.monToFriCheck,
        "weekly": this.weeklyCheck
      };

      if(this.bulkAddHanldle){
        this.bulkAddForm.value.days = this.days;
      } else {
        this.bulkAddForm.value.availabilityCheck = availabilityCheck;
      }

      let promise = this.partnerService.setTimeAvailabilityInBulk(this.bulkAddForm.value);
      promise.then(async (res) => {
        if(res){
          this.bulkAddHanldle = false;
          await this.showSnackBar("Time Scheduled Successfully");
          await this.initialize();
          this.handleAddBulkForm();
          this.resetDaysCheckAndTime();
          this.resetCheckBoxes();
        }
      }).catch(async (err) => {
        await this.showSnackBar("Something went wrong");
      });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async handlePartnerChange(partnerId: any){
    try {
      this.spinnerService.show();
      this.partner = await this.partnerService.getOneWithTimeAvailability(this.partnerId);
      this.router.navigate(['partner', this.partner?.id, 'availability']);
      this.partnerId = this.partner?.id;
      await this.setTimeAvailablities(this.partnerId);
      this.handleAddBulkForm();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong");
    }
  }

  resetDaysCheckAndTime(){
    this.days = [
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' }
    ];
  }

  checkOnlyOne(checkbox: string) {
    switch (checkbox) {
      case 'allDays':
        this.monToFriCheck = false;
        this.weekendCheck = false;
        this.weeklyCheck = false;
        break;
      case 'monToFri':
        this.allDaysCheck = false;
        this.weekendCheck = false;
        this.weeklyCheck = false;
        break;
      case 'weekend':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        this.weeklyCheck = false;
        break;
      case 'weekly':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        this.weekendCheck = false;
        break;
      default:
        break;
    }
  }

  resetCheckBoxes(){
    this.allDaysCheck = false;
    this.monToFriCheck = false;
    this.weekendCheck = false;
    this.weeklyCheck = false;
  }

  async onDatesSet(event) {
    try {
      const { start, end } = event;
      const formattedStart = this.formatDate(start);
      const formattedEnd = this.formatDate(end);
      this.dateRangeObject = {
        fromDate: formattedStart,
        toDate: formattedEnd
      }
      this.partnerId = this.route.snapshot.paramMap.get('id');
      await this.setTimeAvailablities(this.partnerId);
    } catch (error) {
      console.log("ERROR", error?.message);
    }
  }

  formatDate(date: Date) {
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }


}
*/

