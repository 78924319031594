import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cities-list-pop-up',
  templateUrl: './cities-list-pop-up.component.html',
  styleUrls: ['./cities-list-pop-up.component.scss']
})
export class CitiesListPopUpComponent implements OnInit {

  provinceData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { province: any },
  ) { 
    this.provinceData = this.data.province;
  }

  async ngOnInit() {
  }

}
