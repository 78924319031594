import { Injectable } from "@angular/core";
import { ProductService } from "../services/product.service";
import { Product } from "../models/product.schema";

@Injectable({
    providedIn: 'root'
})

export class AutoGeneratedSkuService { 

    constructor(
        private productService: ProductService,
    ){}
    
    async autoGeneratedSku(): Promise<string> {
        try {
            let sku: string;
            let existingProduct: Product | null;
            
            do {
                sku = await this.autoGeneratedSkuFunction();
                existingProduct = await this.productService.findProduct(sku);
            } while (existingProduct);

            return sku;
        } catch (error) {
            console.error("Error generating SKU:", error);
            throw error;
        }
    }

    async autoGeneratedSkuFunction(): Promise<string> {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        return result;
    }
}