import { SpinnerService } from 'src/app/services/spinner.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Expert, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { ExpertService } from 'src/app/services/expert.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Delivery, DeliveryStatusAux } from 'src/app/models/delivery.schema';
import { ExpertSalaryService } from 'src/app/services/expert-salary.service';
import { AdminValetActivityService } from 'src/app/services/admin-valet-activity.service';
import { AdminValetActivityHelpherService } from 'src/app/helpers/admin-valet-activity-helpher.service';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { ShowNotesComponent } from '../show-notes/show-notes.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-admin-valet-activity',
  templateUrl: './admin-valet-activity.component.html',
  styleUrls: ['./admin-valet-activity.component.scss']
})
export class AdminValetActivityComponent implements OnInit {

  experts: Expert[] = [];
  deliveries: any[] = [];
  activities: any[] = [];
  showingDelivery: Delivery;
  showingActivity: any;
  filtered = {};
  activeAccordionDeliveryIndex: number | null = null;
  activeAccordionActivityIndex: number | null = null;
  selectedValet: Expert = null;
  selectedExpertId: any = null;
  adminValetActivities: any[] = [];
  deliveryStatusAux = DeliveryStatusAux;
  groups: any;
  loggedInExpert: Expert;
  isFilterAccordionOpen: boolean = false;
  activityFilterForm: any;
  isTeamLeader: boolean = false;
  selected: boolean;
  today: any;
  loggedInUserInfo: any;
  defaultActivityOrder = { 'AdminValetActivity.deliveryDate': 'ASC' };
  defaultDeliveryOrder = { 'Delivery.deliveryDate': 'DESC' };
  allActivities: any[] = [];
  addresses: string[] = [];
  unoptimizedData: { distance: string; duration: string; steps: string[] } = {
    distance: "",
    duration: "",
    steps: [],
  };
  optimizedData: { distance: string; duration: string; steps: string[] } = {
    distance: "",
    duration: "",
    steps: [],
  };
  showUnoptimizedSteps: boolean = false;
  showOptimizedSteps: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private expertService: ExpertService,
    private expertSalaryService: ExpertSalaryService,
    private adminValetActivityService: AdminValetActivityService,
    private spinnerService: SpinnerService,
    private adminValetActivityHelpherService: AdminValetActivityHelpherService,
    private authStatusService: AuthStatusService,
    public dialog: MatDialog,
  ) { 
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.selected = this.route.snapshot.data['active'];
  }

  async ngOnInit() {
    // this.selected = !this.route.snapshot.data.data['active'] ? 'inactive' : 'active';
    var today = new Date();
    var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    this.today = tmptoday;
    this.manageActivityFilterForm();
    await this.getAllActivities();
    await this.initialize();
    await this.filter();
    // this.renderStaticMap("unoptimizedMap");
    // this.renderStaticMap("optimizedMap");
  }

  renderStaticMap(elementId: string): void{
    const map = new google.maps.Map(
      document.getElementById(elementId) as HTMLElement,
      {
        zoom: 6,
        center: { lat: 41.8719, lng: 12.5674 },
      }
    );
  }

  manageActivityFilterForm(){
    this.activityFilterForm = new FormGroup({
      'AdminValetActivity.deliveryDate_from': new FormControl(this.today),
      'AdminValetActivity.deliveryDate_to': new FormControl(this.today),
      'AdminValetActivity.completed': new FormControl(),
      'Delivery.deliveryDate_from': new FormControl(this.today),
      'Delivery.deliveryDate_to': new FormControl(this.today),
      'Delivery.status': new FormControl(),
      'AdminValetActivity.customFilter': new FormControl(),
    });
  }

  async resetFilter() {
    this.activityFilterForm = new FormGroup({
      'AdminValetActivity.deliveryDate_from': new FormControl(), 
      'AdminValetActivity.deliveryDate_to': new FormControl(),
      'AdminValetActivity.completed': new FormControl(),
      'Delivery.deliveryDate_from': new FormControl(),
      'Delivery.deliveryDate_to': new FormControl(),
      'Delivery.status': new FormControl(),
      'AdminValetActivity.customFilter': new FormControl(),
    });
    await this.filter();
  }

  async filter() {
    await this.getAllActivities();
    await this.getDeliveriesAndActivities();
  }

  async getDeliveriesAndActivities(){
    if(this.selectedExpertId){
      if(this.selected === true){
        if(this.groups.includes('admin')){
          await this.loadDeliveries('active');
        }
        await this.loadActivities('active');
      } else if(this.selected === false){
        if(this.groups.includes('admin')){
          await this.loadDeliveries('inactive');
        }
        await this.loadActivities('inactive');
      }

      this.deliveries = this.deliveries.filter(delivery => {
        return !this.adminValetActivities.some(activity => activity.delivery.id === delivery.id);
      });
    }
  }

  async loadDeliveries(mode: string){

    let filterForm = (new FormGroupToObject()).transform(this.activityFilterForm);
    this.filtered = {};

    delete this.filtered['AdminValetActivity.expertId'];
    delete this.filtered['AdminValetActivity.completed'];
    delete this.filtered['AdminValetActivity.deliveryDate'];

    this.filtered['Delivery.expertId'] = (this.selectedExpertId);
    this.filtered['Delivery.status'] = { operator: 'in', values: mode === 'active' ? DeliveryStatusAux.active : DeliveryStatusAux.inactive };

    if (filterForm['AdminValetActivity.deliveryDate_from'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['AdminValetActivity.deliveryDate_from']] };
    if (filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'lessThan', values: [filterForm['AdminValetActivity.deliveryDate_to']] };
    if (filterForm['AdminValetActivity.deliveryDate_from'] && filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'between', values: [filterForm['AdminValetActivity.deliveryDate_from'], filterForm['AdminValetActivity.deliveryDate_to']] };

    if (filterForm['AdminValetActivity.customFilter'])
      this.filtered['AdminValetActivity.customFilter'] = (`%${filterForm['AdminValetActivity.customFilter']}%`);

    let deliveries = await this.expertSalaryService.getAllDeliveries(this.selectedExpertId, 1, 1000, {}, this.filtered);
    this.deliveries = deliveries.deliveries;
  }

  async loadActivities(mode: string){

    let filterForm = (new FormGroupToObject()).transform(this.activityFilterForm);
    this.filtered = {};

    delete this.filtered['Delivery.expertId'];
    delete this.filtered['Delivery.status'];
    delete this.filtered['Delivery.deliveryDate'];

    this.filtered['AdminValetActivity.expertId'] = (this.selectedExpertId);
    this.filtered['AdminValetActivity.completed'] = mode === 'active' ? 0 : 1;

    if (filterForm['AdminValetActivity.deliveryDate_from'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['AdminValetActivity.deliveryDate_from']] };
    if (filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'lessThan', values: [filterForm['AdminValetActivity.deliveryDate_to']] };
    if (filterForm['AdminValetActivity.deliveryDate_from'] && filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [filterForm['AdminValetActivity.deliveryDate_from'], filterForm['AdminValetActivity.deliveryDate_to']] };

    if (filterForm['AdminValetActivity.customFilter'])
      this.filtered['AdminValetActivity.customFilter'] = (`%${filterForm['AdminValetActivity.customFilter']}%`);
    
    this.adminValetActivities = await this.adminValetActivityService.getAllWithDeliveryDateOrder(null, null, this.defaultActivityOrder, this.filtered);
  }

  async initialize(){
    try {
      this.selectedExpertId = null;
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert')){
        let teamLeader: Expert = await this.expertService.getOne(this.loggedInUserInfo['extraId']);
        this.isTeamLeader = teamLeader.isTeamLeader;
      }
      await this.getExperts();
    } catch (error) {
      console.log("ERROR====>", error);
    }
  }

  async getExperts(){
    this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
  }

  async getDeliveries(){

  }

  async handleValetChange(valetId: number){
    try {
      this.spinnerService.show();
      this.selectedValet = this.experts.find(v => v.id === Number(valetId));
      this.selectedExpertId = this.selectedValet?.id;
      await this.getAllActivities()
      await this.getDeliveriesAndActivities();
      // var today = new Date();
      // var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
      // this.filtered = {};
      // this.filtered['Delivery.deliveryDate'] = { operator: 'between', values: [tmptoday, tmptoday] };
      // this.filtered['Delivery.status'] = { operator: 'in', values: DeliveryStatusAux.active };
      // let deliveries = await this.expertSalaryService.getAllDeliveries(valetId, 1, 1000, {}, this.filtered);
      // this.deliveries = deliveries.deliveries;
      // await this.getAdminValetActivity();
      this.spinnerService.hide();
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  formatTimeShow(fromTime, toTime){
    let trimmedFromTime = fromTime != null ? fromTime?.slice(0, -3) : '';
    let trimmedToTime = toTime != null ? toTime?.slice(0, -3) : '';
    let time = trimmedFromTime + '-' + trimmedToTime;
    return time;
  }

  toggleDeliveryAccordion(index: number) {
    if (this.activeAccordionDeliveryIndex === index) {
      this.activeAccordionDeliveryIndex = null;
    } else {
      this.activeAccordionDeliveryIndex = index; 
    }
    this.showingDelivery = this.deliveries[index];
  }

  toggleActivityAccordion(index: number){
    if (this.activeAccordionActivityIndex === index) {
      this.activeAccordionActivityIndex = null;
    } else {
      this.activeAccordionActivityIndex = index; 
    }
    this.showingActivity = this.adminValetActivities[index];
  }

  showServiceDetails(incomingDelivery: any){
    return incomingDelivery?.partner?.partnerServices.find((item) => item?.service?.id == incomingDelivery.service);
  }

  async drop(event: CdkDragDrop<any[]>) {
    try {
      if (event.previousContainer === event.container) {
        // This is for reordering within the same list (activity-listing only)
        if (event.container.id === 'activitiesList') {
          this.spinnerService.show();
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
          await this.updateReorderedActivities(event.container.data);
          // await this.getAdminValetActivity();
          await this.getAllActivities();
          await this.getDeliveriesAndActivities();
          this.spinnerService.hide();
        }
      } else {
        // This is for transferring items from deliveries-listing to activity-listing
        if (event.previousContainer.id === 'deliveriesList' && event.container.id === 'activitiesList') {
          this.spinnerService.show();
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
  
          const activity = event.container.data[event.currentIndex]; // get the newly added activity
          // await this.createActivity(activity);
          await this.adminValetActivityHelpherService.createOrUpdateActivities(activity);
          // await this.getAdminValetActivity();
          await this.getAllActivities();
          await this.getDeliveriesAndActivities();
          this.spinnerService.hide();
        }
      }
    } catch (error) {
      this.spinnerService.hide();
      console.log("Error", error);
    }
  }

  showDeliveryAndSaleIds(valetActivity: any): string | undefined {
    if (!valetActivity?.delivery?.ddtNumber) {
      return valetActivity?.delivery?.id;
    }
  
    if (valetActivity.activityType.toLowerCase() === 'delivery') {
      const deliveryActivities = this.allActivities.filter(
        (activity) =>
          activity.activityType.toLowerCase() === 'pickup' &&
          activity.delivery?.ddtNumber === valetActivity.delivery.ddtNumber
      );
      const deliveryIds = deliveryActivities.map((activity) => activity.delivery.id)
      return `${valetActivity.delivery.ddtNumber} (${deliveryIds.join(' + ')})`;
    }
  
    if (valetActivity.activityType.toLowerCase() === 'pickup') {
      return `${valetActivity.ddtNumber} (${valetActivity.delivery.id})`;
    }
  }

  getProducts(valetActivity: any): any[] {
    if (!valetActivity || !valetActivity.delivery) return [];
    
    const activityType = valetActivity.activityType?.toLowerCase();
    const serviceType = valetActivity.delivery.serviceType?.toLowerCase();
  
    if (activityType === 'hourlyservice' || serviceType === 'fixedprice' || (serviceType === 'sales' && !valetActivity.ddtNumber)) {
      return valetActivity.delivery.deliveryProducts || [];
    }
  
    if (serviceType === 'sales' && valetActivity.ddtNumber) {
      return this.showProducts(valetActivity);
    }
  
    return [];
  }  

  showProducts(valetActivity: any) {
    if (valetActivity.activityType.toLowerCase() === 'delivery') {
      const deliveryActivities = this.allActivities.filter(
        (activity) =>
          activity.activityType.toLowerCase() === 'pickup' &&
          activity.delivery?.ddtNumber === valetActivity.delivery.ddtNumber
      );
      const deliveryProducts = deliveryActivities
                                .map((activity) => activity.delivery.deliveryProducts)
                                .reduce((acc, products) => acc.concat(products), []);
      return deliveryProducts;
    } else if(valetActivity.activityType.toLowerCase() === 'pickup') {
      return valetActivity.delivery.deliveryProducts || [];
    } else {
      return [];
    }
  }

  async getAllActivities(){
    if(this.selectedExpertId){
      let filterForm = (new FormGroupToObject()).transform(this.activityFilterForm);
      this.filtered = {};

      delete this.filtered['Delivery.expertId'];
      delete this.filtered['Delivery.status'];
      delete this.filtered['Delivery.deliveryDate'];

      this.filtered['AdminValetActivity.expertId'] = (this.selectedExpertId);

      if (filterForm['AdminValetActivity.deliveryDate_from'])
        this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['AdminValetActivity.deliveryDate_from']] };
      if (filterForm['AdminValetActivity.deliveryDate_to'])
        this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'lessThan', values: [filterForm['AdminValetActivity.deliveryDate_to']] };
      if (filterForm['AdminValetActivity.deliveryDate_from'] && filterForm['AdminValetActivity.deliveryDate_to'])
        this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [filterForm['AdminValetActivity.deliveryDate_from'], filterForm['AdminValetActivity.deliveryDate_to']] };
      this.allActivities = await this.adminValetActivityService.getAllWithDeliveryDateOrder(null, null, {}, this.filtered);
      console.log("ALL ACTIVITIES", this.allActivities);
    }
  }

  async getAdminValetActivity(){
    try {
      let filtered = {};
      const today = new Date();
      const tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
      filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [tmptoday, tmptoday] };
      filtered['AdminValetActivity.expertId'] = Number(this.selectedValet.id);
      filtered['AdminValetActivity.completed'] = 0;
      let activities = await this.adminValetActivityService.getAdminValetActivity(filtered);
      this.adminValetActivities = activities;

      // Update deliveries by removing ones that have been added to activities
      this.deliveries = this.deliveries.filter(delivery => {
        return !this.adminValetActivities.some(activity => activity.delivery.id === delivery.id);
      });
    } catch (error) {
      
    }
  }

  async updateReorderedActivities(activities: any[]) {
    const reorderedActivities = activities.map((activity, index) => ({
      id: activity.id,
      order: index + 1, // This represents the new order
    }));
  
    await this.adminValetActivityService.updateActivityOrder(reorderedActivities);
  }
  
  toggleFilterAccordion() {
    this.isFilterAccordionOpen = !this.isFilterAccordionOpen;
  }

  async showSnackBar(message: string) {
    this.snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async openNotesDialauge(delivery: Delivery){
    try {
      let ref = this.dialog.open(ShowNotesComponent, { width: '80vw', data: { delivery } });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

}
