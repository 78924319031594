import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateLogIn } from './helpers/guards/log-in.guard';
import { LoginComponent } from './components/auth/login/login.component';
import { RegistrationComponent } from './components/auth/registration/registration.component';
import { ResendActivationComponent } from './components/auth/resend-activation/resend-activation.component';
import { PasswordRecoveryComponent } from './components/auth/password-recovery/password-recovery.component';
import { UserLoggedIn } from './helpers/guards/logged-in.guard';
import { LogOut } from './helpers/guards/log-out.guard';
import { DeliveriesComponent } from './components/deliveries/deliveries.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { DeliveryFormComponent } from './components/delivery-form/delivery-form.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ExpertsComponent } from './components/experts/experts.component';
import { PartnersComponent } from './components/partners/partners.component';
import { GroupRestricted } from './helpers/guards/group-restricted.guard';
import { UsersComponent } from './components/users/users.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ExpertFormDecoratorComponent } from './components/expert-form-decorator/expert-form-decorator.component';
import { PartnerFormDecoratorComponent } from './components/partner-form-decorator/partner-form-decorator.component';
import { OffersComponent } from './components/offers/offers.component';
import { OfferFormComponent } from './components/offer-form/offer-form.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { DeliveryCanActivateLogIn } from './helpers/guards/delivery-log-in.guard';
import { SmsTemplatesComponent } from './components/sms-templates/sms-templates.component';
import { SmsTemplatesFormComponent } from './components/sms-templates-form/sms-templates-form.component';
import { AdminSmsTemplatesComponent } from './components/admin-sms-templates/admin-sms-templates.component';
import { AdminSmsTemplateFormComponent } from './components/admin-sms-templates-form/admin-sms-template-form.component';
import { ShopifySaleComponent } from './components/shopify-sale/shopify-sale.component';
import { ShopifySaleFormComponent } from './components/shopify-sale-form/shopify-sale-form.component';
import { PartnerInvoiceComponent } from './components/partner-invoice/partner-invoice.component';
import { ServiceComponent } from './components/service/service.component';
import { ServiceFormComponent } from './components/service-form/service-form.component';
import { DuplicateDeliveryComponent } from './components/duplicate-delivery/duplicate-delivery.component';
import { DuplicatePartnerComponent } from './components/duplicate-partner/duplicate-partner.component';
import { ExpertServiceComponent } from './components/expert-service/expert-service.component';
import { ExpertServiceFormComponent } from './components/expert-service-form/expert-service-form.component';
import { ExpertSalaryComponent } from './components/expert-salary/expert-salary.component';
import { ProductCategoryFormComponent } from './components/product-category-form/product-category-form.component';
import { ProductCategoryComponent } from './components/product-category/product-category.component';
import { TestComponent } from './components/test/test.component';
import { PartnerAvailabilityComponent } from './components/partner-availability/partner-availability.component';
import { ExpertAvailabilityComponent } from './components/expert-availability/expert-availability.component';
import { PartnerAvailabilityListComponent } from './components/partner-availability-list/partner-availability-list.component';
import { ExpertAvailabilityListComponent } from './components/expert-availability-list/expert-availability-list.component';
import { DuplicateProductComponent } from './components/duplicate-product/duplicate-product.component';
import { PartnerPriorityListComponent } from './components/partner-priority-list/partner-priority-list.component';
import { PartnerPriorityListFormComponent } from './components/partner-priority-list-form/partner-priority-list-form.component';
import { ConfirmSaleComponent } from './components/confirm-sale/confirm-sale.component';
import { PartnerSaleActivityComponent } from './components/partner-sale-activity/partner-sale-activity.component';
import { DuplicatePartnerPriorityListComponent } from './components/duplicate-partner-priority-list/duplicate-partner-priority-list.component';
import { SaleDeliveryDetailComponent } from './components/sale-delivery-detail/sale-delivery-detail.component';
import { CakeOrdersDetailsComponent } from './components/cake-orders-details/cake-orders-details.component';
import { ValetPriorityListComponent } from './components/valet-priority-list/valet-priority-list.component';
import { ValetPriorityListFormComponent } from './components/valet-priority-list-form/valet-priority-list-form.component';
import { DuplicateValetPriorityListComponent } from './components/duplicate-valet-priority-list/duplicate-valet-priority-list.component';
import { OperationComponent } from './components/operation/operation.component';
import { OperationFormDecoratorComponent } from './components/operation-form-decorator/operation-form-decorator.component';
import { TeamLeaderComponent } from './components/team-leader/team-leader.component';
import { TeamLeaderFormDecoratorComponent } from './components/team-leader-form-decorator/team-leader-form-decorator.component';
import { ValetOwnAvailabilityComponent } from './components/valet-own-availability/valet-own-availability.component';
import { CakeSaleComponent } from './components/cake-sale/cake-sale.component';
import { BusinessSaleComponent } from './components/business-sale/business-sale.component';
import { DeluxyFlowersSaleComponent } from './components/deluxy-flowers-sale/deluxy-flowers-sale.component';
import { CakeSaleFormComponent } from './components/cake-sale-form/cake-sale-form.component';
import { BusinessSaleFormComponent } from './components/business-sale-form/business-sale-form.component';
import { DeluxyFlowersSaleFormComponent } from './components/deluxy-flowers-sale-form/deluxy-flowers-sale-form.component';
import { AllSalesComponent } from './components/all-sales/all-sales.component';
import { PartnerDeliveryRulesComponent } from './components/partner-delivery-rules/partner-delivery-rules.component';
import { PartnerDeliveryRulesFormComponent } from './components/partner-delivery-rules-form/partner-delivery-rules-form.component';
import { ExpertDeliveryRulesComponent } from './components/expert-delivery-rules/expert-delivery-rules.component';
import { ExpertDeliveryRulesFormComponent } from './components/expert-delivery-rules-form/expert-delivery-rules-form.component';
import { ProductPriorityListFormComponent } from './components/product-priority-list-form/product-priority-list-form.component';
import { ProductPriorityListComponent } from './components/product-priority-list/product-priority-list.component';


const routes: Routes = [
  { path: '', redirectTo: 'consegne', pathMatch: 'full' },

  { path: 'login', component: LoginComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'logout', component: LoginComponent, canActivate: [LogOut], data: { blank: true } },
  { path: 'register', component: RegistrationComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'verify/:token', component: RegistrationComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'resend_activation', component: ResendActivationComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'reset_password', redirectTo: 'reset_password/', pathMatch: 'full', data: { blank: true } },
  { path: 'reset_password/:token', component: PasswordRecoveryComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'activate/:token', component: PasswordRecoveryComponent, canActivate: [UserLoggedIn], data: { blank: true, activate: true } },

  { path: 'consegne', component: DeliveriesComponent, canActivate: [CanActivateLogIn], data: { active: true } },
  { path: 'consegne/storico', component: DeliveriesComponent, canActivate: [CanActivateLogIn], data: { active: false } },
  { path: 'consegne/nuovo', component: DeliveryFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner', 'operation'] } },
  { path: 'consegne/:id', component: DeliveryComponent, canActivate: [DeliveryCanActivateLogIn] },
  { path: 'consegne/:id/edit', component: DeliveryFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation', 'partner'] } },

  { path: 'offerte', component: OffersComponent, canActivate: [CanActivateLogIn], data: { active: true, groups: ['admin', 'operation', 'partner'] } },
  { path: 'offerte/nuovo', component: OfferFormComponent, canActivate: [CanActivateLogIn], data: { active: true, groups: ['admin', 'operation', 'partner'] } },
  { path: 'offerte/:id', component: OfferFormComponent, canActivate: [CanActivateLogIn], data: { active: true, groups: ['admin', 'operation', 'partner'] } },

  { path: 'expert', component: ExpertsComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation', 'teamleader', 'expert'] } },
  { path: 'expert/nuovo/:userId', component: ExpertFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation',] } },
  { path: 'expert/nuovo', component: ExpertFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation', 'teamleader'] } },
  { path: 'expert/:id/edit', component: ExpertFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'expert/:id/availability', component: ExpertAvailabilityComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'expert', 'operation', 'teamleader'] } },
  { path: 'expert/availability/list', component: ExpertAvailabilityListComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation', 'teamleader', 'expert'] } },
  { path: 'expert/delivery/rules', component: ExpertDeliveryRulesComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'expert/delivery/rules/nuovo', component: ExpertDeliveryRulesFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'expert/delivery/rules/edit/:id', component: ExpertDeliveryRulesFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },

  { path: 'partner', component: PartnersComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation', 'teamleader', 'expert'] } },
  { path: 'partner/nuovo/:userId', component: PartnerFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'partner/nuovo', component: PartnerFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation', 'teamleader'] } },
  { path: 'partner/:id/edit', component: PartnerFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'partner/:id/availability', component: PartnerAvailabilityComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner', 'operation', 'teamleader', 'expert'] } },
  { path: 'partner/availability/list', component: PartnerAvailabilityListComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation', 'teamleader', 'expert'] } },
  { path: 'partner/priority/list', component: PartnerPriorityListComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'partner/priority/list/nuovo', component: PartnerPriorityListFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'partner/priority/list/:id/edit', component: PartnerPriorityListFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'partner/vendita', component: PartnerSaleActivityComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: {active: true, groups: ['partner'] } },
  { path: 'partner/vendita/storico', component: PartnerSaleActivityComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: {active: false, groups: ['partner'] } },
  { path: 'partner/delivery/rules', component: PartnerDeliveryRulesComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'partner/delivery/rules/nuovo', component: PartnerDeliveryRulesFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'partner/delivery/rules/edit/:id', component: PartnerDeliveryRulesFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },

  { path: 'prodotti', component: ProductsComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner', 'operation'] } },
  { path: 'prodotti/nuovo', component: ProductFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner', 'operation'] } },
  { path: 'prodotti/:id', component: ProductFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner', 'operation'] } },
  { path: 'product/categoria', component: ProductCategoryComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'product/categoria/nuovo', component: ProductCategoryFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'product/categoria/:id/edit', component: ProductCategoryFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'product/priority-list', component: ProductPriorityListComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'product/priority-list/nuovo', component: ProductPriorityListFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'product/priority-list/edit/:id', component: ProductPriorityListFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },

  { path: 'utenti', component: UsersComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation',] } },
  { path: 'profilo', component: ProfileComponent, canActivate: [CanActivateLogIn] },

  { path: 'smstemplates', component: SmsTemplatesComponent, canActivate: [CanActivateLogIn], data: { groups: ['partner', 'operation',] } },
  { path: 'smstemplates/nuovo', component: SmsTemplatesFormComponent, canActivate: [CanActivateLogIn], data: { groups: ['partner'] } },
  { path: 'smstemplates/:id/edit', component: SmsTemplatesFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['partner'] } },

  { path: 'admin/smstemplates', component: AdminSmsTemplatesComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation',] } },
  { path: 'admin/smstemplates/nuovo', component: AdminSmsTemplateFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'admin/smstemplates/:id/edit', component: AdminSmsTemplateFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },

  { path: 'vendita', component: ShopifySaleComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: true, groups: ['admin', 'operation'] }  },
  { path: 'vendita/storico', component: ShopifySaleComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'operation'] }  },
  { path: 'vendita/edit/:id', component: ShopifySaleFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] }  },
  { path: 'vendita/detail/:id', component: SaleDeliveryDetailComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner'] }  },
  { path: 'confirm/sale/:token', component: ConfirmSaleComponent },

  /* For Partner Invoice */
  { path: 'partner/fattura', component: PartnerInvoiceComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: true, groups: ['admin', 'partner'] }  },
  { path: 'partner/fattura/storico', component: PartnerInvoiceComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'partner'] }  },
  /* For Partner Invoice */


  { path: 'servizi', component: ServiceComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner'] } },
  { path: 'servizi/nuovo', component: ServiceFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'servizi/:id/edit', component: ServiceFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },

  // { path: 'under-maintinance', component: UnderMaintinanceComponent },
  { path: 'consegne/duplica/:id', component: DuplicateDeliveryComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner', 'operation'] } },
  { path: 'partner/duplica/:id', component: DuplicatePartnerComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'product/duplica/:id', component: DuplicateProductComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },
  { path: 'partner/priority/list/duplica/:id', component: DuplicatePartnerPriorityListComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'operation'] } },

  { path: 'valet/servizi', component: ExpertServiceComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'valet/servizi/nuovo', component: ExpertServiceFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'valet/servizi/:id/edit', component: ExpertServiceFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  /* For Partner Invoice */
  { path: 'valet/stipendi', component: ExpertSalaryComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: true, groups: ['admin', 'expert'] }  },
  { path: 'valet/stipendi/storico', component: ExpertSalaryComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'expert'] }  },
  { path: 'valet/priority-list', component: ValetPriorityListComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'valet/priority-list/nuovo', component: ValetPriorityListFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'valet/priority-list/edit/:id', component: ValetPriorityListFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'valet/priority-list/duplica/:id', component: DuplicateValetPriorityListComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  /* For Partner Invoice */

  { path: 'cake/orders', component: CakeOrdersDetailsComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },

  /* NEW ROLE OPERATION */
  { path: 'operation', component: OperationComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'operation/nuovo/:userId', component: OperationFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'operation/nuovo', component: OperationFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'operation/edit/:id', component: OperationFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  /* NEW ROLE OPERATION */


  /* NEW ROLE TEAM LEADER*/
  { path: 'team/leader', component: TeamLeaderComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'team/leader/nuovo/:userId', component: TeamLeaderFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'team/leader/nuovo', component: TeamLeaderFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'team/leader/edit/:id', component: TeamLeaderFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  /* NEW ROLE TEAM LEADER */

  { path: 'expert/availability/:id', component: ValetOwnAvailabilityComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['expert'] } },

  /* New Sales Integration */
  { path: 'cakes/vendita', component: CakeSaleComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: true, groups: ['admin', 'operation'] }  },
  { path: 'cakes/vendita/storico', component: CakeSaleComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'operation'] }  },
  { path: 'cakes/vendita/edit/:id', component: CakeSaleFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'operation'] }  },

  { path: 'business/vendita', component: BusinessSaleComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: true, groups: ['admin', 'operation'] }  },
  { path: 'business/vendita/storico', component: BusinessSaleComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'operation'] }  },
  { path: 'business/vendita/edit/:id', component: BusinessSaleFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'operation'] }  },

  { path: 'flowers/vendita', component: DeluxyFlowersSaleComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: true, groups: ['admin', 'operation'] }  },
  { path: 'flowers/vendita/storico', component: DeluxyFlowersSaleComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'operation'] }  },
  { path: 'flowers/vendita/edit/:id', component: DeluxyFlowersSaleFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'operation'] }  },

  { path: 'all/vendita', component: AllSalesComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: true, groups: ['admin', 'operation'] }  },
  { path: 'all/vendita/storico', component: AllSalesComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { active: false, groups: ['admin', 'operation'] }  },
  /* New Sales Integration */
  
  /*test routes */
  { path: 'test', component: TestComponent  },
  /*test routes */
  { path: '404', component: NotFoundComponent, data: { blank: true } },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
