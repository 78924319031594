import { Component, OnInit } from '@angular/core';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeliveryService } from '../../services/delivery.service';
import { Delivery, DeliveryProduct, DeliveryStatus, DeliveryStatusAux, TimePeriods } from '../../models/delivery.schema';
import { Product } from '../../models/product.schema';
import { Partner } from '../../models/partner.schema';
import { Expert, ExpertServices } from '../../models/expert.schema';
import { PartnerService } from '../../services/partner.service';
import { ExpertService } from '../../services/expert.service';
import { AuthStatusService } from '../../services/auth-status.service';
import { ProductService } from 'src/app/services/product.service';
import { DeliveryLinkDialogComponent } from '../delivery-link-dialog/delivery-link-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { DatePipe } from '@angular/common';
import { User } from 'src/app/models/user.schema';
import { ServiceModel } from 'src/app/models/service.schems';
import { Service } from 'src/app/services/service.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AdminValetActivityHelpherService } from 'src/app/helpers/admin-valet-activity-helpher.service';


@Component({
  selector: 'app-duplicate-delivery',
  templateUrl: './duplicate-delivery.component.html',
  styleUrls: ['./duplicate-delivery.component.scss']
})
export class DuplicateDeliveryComponent implements OnInit {

  public groups: string[];
  public readonly formValidationMsgs;
  public _deliveryForm: any;
  public delivery: Delivery;
  public deliveryStatus = DeliveryStatus;
  public deliveryStatusAux = DeliveryStatusAux;
  public partners: Partner[];
  public selectedPartner: Partner;
  public loggedInPartner: any;
  public experts: Expert[];
  public timePeriods = TimePeriods;
  public products: Product[];
  public removeFile: boolean;
  public tmpBtnDisabled: boolean;
  public user: User;
  public userDetails: any;
  public services: ServiceModel[];
  public showStartEndTime: boolean = false;
  public showPickUpAddress: boolean = false;
  public showProductValue: boolean = false;
  public showDistanceInput: boolean = false;
  public disableAdditionalPriceInput: boolean = false;
  public disableValetAdditionalPriceInput: boolean = false;
  public selectedService: any;
  previousValues: { startTime: string, endTime: string } = { startTime: '07:00', endTime: '08:00' };
  previousValetTimeValues: { valetStartTime: string, valetEndTime: string } = { valetStartTime: '07:00', valetEndTime: '08:00' };
  selectedProducts: any;
  TotalSelectedProPrice: any;
  selectedPartnerServices: any[] = [];
  pickUpFTime: any;
  pickUpTTime: any;
  pickUpFTimeError: boolean = false;
  pickUpTTimeError: boolean = false;
  totalDeliveryTime: any = 1;
  selectedExpert: Expert;
  selectedExpertSalary: any;
  selectedExpertServices: any[] = [];
  pickUpAddressLat: number;
  pickUpAddressLng: number;
  selectedExpertService: ExpertServices;
  selectedValetAddress: string = '';
  valetAddressLat: number;
  valetAddressLng: number;
  totalValetTimeDifference: any = 1;
  showValetStartAndEndTime: boolean = false;
  showProductImage: boolean[] = [];
  productImageUrls: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private deliveryService: DeliveryService,
    private partnerService: PartnerService,
    private expertService: ExpertService,
    private service: Service,
    private productService: ProductService,
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private adminValetActivityHelpherService: AdminValetActivityHelpherService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.formValidationMsgs = Delivery.validationMessages;
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      await this.initialize();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something Went Wrong On This Page");
    }
  }

  async initialize() {

    this.loggedInPartner = JSON.parse(sessionStorage.getItem("currentUser"));
    if(this.groups.includes('partner')){
      this.selectedPartner = await this.partnerService.getOne(this.loggedInPartner?.partner.id);
      this.selectedPartnerServices = this.selectedPartner?.partnerServices;
    }
    this.tmpBtnDisabled = false;
    this.products = (await this.productService.getAll(undefined, undefined, { 'Product.name': 'ASC' })).products;
    if (this.groups.includes('admin') || this.groups.includes('operation')) {
      try {
        this.partners = (await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
      }
      catch { }
      try {
        this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
      }
      catch { }
    }
    try {
      this.services = await this.service.getAllServices();
    }
    catch { }
    this._deliveryForm = new FormGroup(Delivery.validation());
    const deliveryId: string = this.route.snapshot.paramMap.get('id');
    if (deliveryId) {
      try {
        this.delivery = plainToClass(Delivery, await this.deliveryService.getOne(deliveryId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.delivery)
      this.patch();
    else
      this.delivery = new Delivery();
  }

  patch() {

    this.removeFile = false;
    const control = <FormArray>this._deliveryForm.get('deliveryProducts');
    control.clear()
    if (!this.delivery.deliveryProducts || Array.isArray(this.delivery.deliveryProducts) && this.delivery.deliveryProducts.length == 0)
      this.delivery.deliveryProducts = [new DeliveryProduct()];
    this.delivery.deliveryProducts.forEach((f, index) => {
      control.push(this.patchValues(f, index));
    });
    ['status', 'surname', 'name', 'address', 'intercom', 'createdUser' , 'deliveryDate', 'fromTime', 'toTime', 'notes', 'internalNotes',
      'requestExpert', 'receiverPhone', 'senderSurname', 'senderName', 'senderPhone', 'email', 'payAtDelivery', 'deluxyDelivery',
      'tryAndReturn', 'longitude', 'latitude', 'pickUpTime', 'smsPhoneNo', 'ddtNumber', 'price', 'startTime', 'endTime', 'service',
      'productValue', 'pickUpAddress', 'additionalPrice', 'distance', 'isFlexblePickUpTime', 'serviceName', 'expertServiceId','valetStartTime', 'valetEndTime'].forEach(param => {
        if (this.delivery[param] instanceof Date)
          this._deliveryForm.controls[param].setValue(new DatePipe('it-IT').transform(this.delivery[param], 'yyyy-MM-dd'));
        else
          this._deliveryForm.controls[param].setValue(this.delivery[param]);
        if(param === 'startTime'){
          this._deliveryForm.controls.startTime.setValue(this.delivery[param]?.slice(0, -3));
        }
        if(param === 'endTime'){
          this._deliveryForm.controls.endTime.setValue(this.delivery[param]?.slice(0, -3));
        }
        if(param === 'valetStartTime'){
          this._deliveryForm.controls.valetStartTime.setValue(this.delivery[param]?.slice(0, -3));
        }
        if(param === 'valetEndTime'){
          this._deliveryForm.controls.valetEndTime.setValue(this.delivery[param]?.slice(0, -3));
        }
        if(this.delivery?.isFlexblePickUpTime){
          this.patchPickUpFlexbleTime();
        }
        if(this.groups.includes('partner')){
          this._deliveryForm.controls.pickUpAddress.setValue(this.loggedInPartner?.address);
        }
      });
    if (this.delivery.partner){
      this._deliveryForm.controls.partner.controls.id.setValue(this.delivery.partner.id);
      this.selectedPartner = this.partners.find((partner) => {
        return partner.id == this.delivery.partner.id;
      });
    }
    if (this.delivery.expert){
      this._deliveryForm.controls.expert.controls.id.setValue(this.delivery.expert.id);
      this.selectedExpert = this.experts.find((expert) => {
        return expert.id == this.delivery.expert.id;
      });
    }
    this.patchPartnerService();
    this.patchExpertService();
    if (this.delivery.expert)
      this._deliveryForm.controls.expert.controls.id.setValue(this.delivery.expert.id);

    /* RESET VALUE AS DEFAULT */
    /* FOR PARTNER */
    this._deliveryForm.controls.payable.setValue(true);
    this._deliveryForm.controls.price.setValue(0);
    this._deliveryForm.controls.additionalPrice.setValue(0);
    this._deliveryForm.controls.withDailyDeliveryRule.setValue(null);
    this._deliveryForm.controls.withTotalDeliveryRule.setValue(null);
    this._deliveryForm.controls.deliveryRuleId.setValue(null);
    /* FOR PARTNER */

    /* FOR VALET */
    this._deliveryForm.controls.billable.setValue(true);
    this._deliveryForm.controls.expertSalary.setValue(0);
    this._deliveryForm.controls.valetAdditionalPrice.setValue(0);
    /* FOR VALET */
    /* RESET VALUE AS DEFAULT */
  }

  patchValues(deliveryProduct: DeliveryProduct, index: number) {
    let productForm = Delivery.productFormGroup();
    this.showProductImage[index] = deliveryProduct?.product?.image ? true : false;
    this.productImageUrls[index] = deliveryProduct?.product?.image ? deliveryProduct?.product?.image : '';

    if (deliveryProduct?.product?.id){
        productForm.controls.product.setValue({ id: deliveryProduct.product.id, quantity: deliveryProduct.quantity });
    }

    return productForm;
  }

  patchPartnerService(){
    if(this.delivery?.service && this.selectedPartner){ //new Delivery
      this.partnerChange(this.selectedPartner?.id);
      this.selectedService = this.selectedPartnerServices.find((item) => {
        return item?.service?.id == this.delivery?.service;
      })
      this.showStartEndTime = this.selectedService?.service?.pricingModel === 'hourlyrate';
      if(this.showStartEndTime){
        this._deliveryForm.controls.fromTime.setValue(this.delivery?.startTime);
        this._deliveryForm.controls.toTime.setValue(this.delivery?.endTime);
      }
      this.showProductValue = this.selectedService?.service?.pricingModel === 'sales';
      this.showPickUpAddress = this.selectedService?.service?.pricingModel === 'fixedprice';
      this.showDistanceInput = this.selectedService?.service?.pricingModel === 'fixedprice';
      this._deliveryForm.controls.serviceType.setValue(this.selectedService?.service?.pricingModel);
    }else {   // Old Delivery
      this.partnerChange(this.selectedPartner?.id);
    }
  }

  patchExpertService(){
    if(this.delivery?.expertServiceId && this.selectedExpert){
      this.selectedExpert = this.experts.find((expert) => expert?.id == this.delivery.expert.id);
      this.selectedExpertServices = this.selectedExpert?.expertServices;
      this.selectedExpertService = this.selectedExpertServices.find((expertService => expertService?.id == this.delivery.expertServiceId));
      this._deliveryForm.controls.expertServiceId.setValue(this.selectedExpertService.id);
      if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'hourlyratesalary'){
        this.showValetStartAndEndTime = true;
      }
    }
    if(this.selectedExpert){
      this.selectedExpert = this.experts.find((expert) => expert?.id == this.delivery.expert.id);
      this.selectedExpertServices = this.selectedExpert?.expertServices;
    }
    if(this.selectedExpert && this.delivery?.pickUpAddress && this.delivery?.latitude && this.delivery?.longitude && this.selectedExpertService?.service?.serviceType?.toLowerCase() != 'hourlyratesalary'){
      this.calculateFixedPriceValetSalary();
    }
  }

  addProduct() {
    const control = <FormArray>this._deliveryForm.get('deliveryProducts');
    control.push(Delivery.productFormGroup());
  }

  removeProduct(index: number) {
    const control = <FormArray>this._deliveryForm.get('deliveryProducts');
    this.showProductImage[index] = false;
    this.productImageUrls[index] = '';
    control.removeAt(index);
  }

  onDuplicate(event?: any) {
    this.tmpBtnDisabled = true;
    let sendSmsConfirmation: boolean = false;
    let delivery: Delivery = (new FormGroupToObject()).transform(this._deliveryForm);
    let ddtFile = delivery.ddtFile;
    if (!this.removeFile) delete delivery.ddtFile;
    for (let i = delivery.deliveryProducts.length - 1; i >= 0; i--) {
      if (!delivery.deliveryProducts[i].product.id)
        delivery.deliveryProducts.splice(i, 1);
    }
    let promiseResult: any;
    let createType: boolean = true;
    promiseResult = this.deliveryService.create(delivery, sendSmsConfirmation);

    promiseResult.then(async (data: Delivery) => {
      let allGood = true;
      if(data?.expert.id){
        await this.adminValetActivityHelpherService.createOrUpdateActivities(data);
      }
      this.delivery = plainToClass(Delivery, data);
      if (ddtFile)
        this.delivery = await this.uploadDdt(this.delivery.id).catch((err) => {
          this.errorMessage(createType, err);
          allGood = false;
        });
      if (allGood) {
        const message: string = `Consegna duplicata con successo. Nuovo id creato : ` + this.delivery.id;
        this._snackBar.open(message, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
            this.router.navigate(['/consegne', this.delivery.id, 'edit']);
        });
        this.patch();
        this.tmpBtnDisabled = false;
      }
    }).catch(error => {
      console.log(error);
      this.errorMessage(createType, error);
      this.tmpBtnDisabled = false;
    });
  }

  errorMessage(createType, error) {
    this._snackBar.open(`Errore ${createType ? 'nella creazione' : "nell'aggiornamento"} della consegna`,
      'Chiudi',
      {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }
    );
    console.log(error);
  }

  setTime(timePeriod) {

    if (this.timePeriods[timePeriod]) {
      this._deliveryForm.controls.fromTime.setValue(this.timePeriods[timePeriod]['from']);
      this._deliveryForm.controls.toTime.setValue(this.timePeriods[timePeriod]['to']);
    }
    else {
      this._deliveryForm.controls.fromTime.setValue('');
      this._deliveryForm.controls.toTime.setValue('');
    }
  }

  setDeliveryStatus(idExpert){
    this._deliveryForm.controls.status.setValue(this.deliveryStatus.assigned);
    this.selectedExpert = this.experts.find((expert) => expert?.id == idExpert);
    this.selectedExpertServices = this.selectedExpert?.expertServices;
    this.expertServiceAndSalary();
  }

  expertServiceAndSalary(){
    if(["fixedprice", "sales"].includes(this.selectedService?.service?.pricingModel?.toLowerCase())){
      this.selectedExpertService = this.selectedExpert?.expertServices.find((expertService: any) => {
        let data = expertService?.service?.serviceType?.toLowerCase() === "fixedpricesalary";
        return data;
      })
      this.calculateFixedPriceValetSalary();
    } else if(this.selectedService?.service?.pricingModel?.toLowerCase() === "hourlyrate") {
      this.selectedExpertService = this.selectedExpert?.expertServices.find((expertService: any) => {
        let data = expertService?.service?.serviceType?.toLowerCase() === "hourlyratesalary";
        return data;
      });
      this.calculateHourlyRateValetSalary();
    }
    this._deliveryForm.controls.expertServiceId.setValue(this.selectedExpertService?.id);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._deliveryForm.get('ddtFile').setValue(file);
    }
  }

  uploadDdt(deliveryId: number) {
    return this.deliveryService.updateDeliveryDdt(
      deliveryId,
      this._deliveryForm.get('ddtFile').value
    );
  }

  async showDdt() {
    let res = await this.deliveryService.findOneDeliveryDdt(this.delivery.id);
    let anchor = document.createElement('a');
    anchor.download = this.delivery.ddtFile.replace(/-.{4}(\..{3,4})$/, '$1');
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

  deleteFile() {
    this.removeFile = true;
  }

  async showLink(event: Event) {
    event.preventDefault();
    if (!this.delivery.identifier)
      this.delivery = await this.deliveryService.generateIdentifier(this.delivery.id);
    this.dialog.open(DeliveryLinkDialogComponent, { width: '80vw', data: { delivery: this.delivery } });
  }

  getAddress(place: any) {
    if (!this._deliveryForm) return;
    this._deliveryForm.controls.address.touched = true;
    this._deliveryForm.controls.address.setValue(place.formatted_address || place.name || '');
    this._deliveryForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this._deliveryForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');

    if((this.selectedService?.service?.pricingModel == 'fixedprice') || (this.selectedService?.service?.pricingModel == 'corporate')  ){
      let pickUpAddress: string;
      if (this.groups.includes('partner') && this.loggedInPartner) {
        pickUpAddress = this.loggedInPartner?.partner?.address;
      } else if(this.selectedPartner){
        pickUpAddress = this.selectedPartner?.address;
      }
      this._deliveryForm.controls.pickUpAddress.setValue(pickUpAddress);
      this.geocodePickupAddress(pickUpAddress);
    }

    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }
  }

  /* Old function
  async geocodePickupAddress(pickUpAddress: string) {
    try {
      const geocoder = new google.maps.Geocoder();
      const results = await new Promise<any>((resolve, reject) => {
        geocoder.geocode({ 'address': pickUpAddress }, (results, status) => {
          if (status === 'OK') {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });

      if (results && results.length > 0) {
        this.pickUpAddressLat = results[0].geometry?.location?.lat() || '';
        this.pickUpAddressLng = results[0].geometry?.location?.lng() || '';

        const lat = this._deliveryForm.controls.latitude.value || '';
        const lng = this._deliveryForm.controls.longitude.value || '';
        const fixedAddressLatLng = new google.maps.LatLng(this.pickUpAddressLat, this.pickUpAddressLng);
        const selectedAddressLatLng = new google.maps.LatLng(lat, lng);
        const distanceMatrixService = new google.maps.DistanceMatrixService();

        distanceMatrixService.getDistanceMatrix({
          origins: [fixedAddressLatLng],
          destinations: [selectedAddressLatLng],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const distance = response.rows[0].elements[0].distance?.value;
            const distanceInKm = distance / 1000;
            this._deliveryForm.controls.distance.setValue(distanceInKm.toFixed(2));
            this.handleDistanceChange(distanceInKm);
          } else {
            console.error('Error calculating distance:', status);
          }
        });
      }
    } catch (error) {
      console.log('Error', error.message);
    }
  }
  Old fucntion */

  async geocodePickupAddress(pickUpAddress: string) {
    try {
      const geocoder = new google.maps.Geocoder();
      const results = await new Promise<any>((resolve, reject) => {
        geocoder.geocode({ 'address': pickUpAddress }, (results, status) => {
          if (status === 'OK') {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });

      if (results && results.length > 0) {
        this.pickUpAddressLat = results[0].geometry?.location?.lat() || '';
        this.pickUpAddressLng = results[0].geometry?.location?.lng() || '';
        let deliveryAddLat: any;
        let deliveryAddLng: any;

        if (this._deliveryForm.controls.address.value) {
          const deliveryAddressResult = await this.getLatLngByAddress(this._deliveryForm.controls.address.value);
          deliveryAddLat = deliveryAddressResult[0]?.geometry?.location?.lat() || '';
          deliveryAddLng = deliveryAddressResult[0]?.geometry?.location?.lng() || '';
        }

        const fixedAddressLatLng = new google.maps.LatLng(this.pickUpAddressLat, this.pickUpAddressLng);
        const selectedAddressLatLng = new google.maps.LatLng(deliveryAddLat, deliveryAddLng);
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        if (this.pickUpAddressLat && this.pickUpAddressLng && deliveryAddLat && deliveryAddLng) {
          const response: any = await new Promise((resolve, reject) => {
            distanceMatrixService.getDistanceMatrix({
              origins: [fixedAddressLatLng],
              destinations: [selectedAddressLatLng],
              travelMode: google.maps.TravelMode.DRIVING,
            }, (response, status) => {
              if (status === google.maps.DistanceMatrixStatus.OK) {
                resolve(response);
              } else {
                reject(status);
              }
            });
          });

          const distance = response.rows[0].elements[0].distance?.value;
          const distanceInKm = distance / 1000;
          console.log('getAddressdistanceInKm', distanceInKm)
          this._deliveryForm.controls.distance.setValue(distanceInKm.toFixed(2));
          this.handleDistanceChange(distanceInKm);
        } else {
          alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
          return;
        }
      } else {
        alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
        return;
      }
    } catch (error) {
      console.log('Error', error.message);
      alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
      return;
    }
  }


  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this._deliveryForm.controls.address.value}`, '_blank');
  }

  handleServiceChange(event: any){

    this.selectedService = this.selectedPartnerServices.find((item) => {
      return item?.service?.id == event;
    });

    this._deliveryForm.controls.serviceType.setValue(this.selectedService?.service?.pricingModel);
    this._deliveryForm.controls.serviceName.setValue(this.selectedService?.service?.serviceName);

    this._deliveryForm.controls.startTime.setValue(this.previousValues.startTime);
    this._deliveryForm.controls.endTime.setValue(this.previousValues.endTime);
    let pickUpAddress: string = '';
    if (this.groups.includes('partner') && this.loggedInPartner) {
      pickUpAddress = this.loggedInPartner?.partner?.address;
    } else {
      pickUpAddress = (this.selectedPartner ? this.selectedPartner?.address : '');
    }
    if(this.selectedService?.service?.pricingModel === 'fixedprice'){
      this._deliveryForm.controls.pickUpAddress.setValue(pickUpAddress);
      this._deliveryForm.controls.price.setValue(this.selectedService?.price);
      this._deliveryForm.controls.startTime.setValue(null);
      this._deliveryForm.controls.endTime.setValue(null);
      this._deliveryForm.controls.productValue.setValue(null);
      this._deliveryForm.controls.distance.setValue(null);
      this._deliveryForm.controls.hours.setValue(null);
      this._deliveryForm.controls.valetStartTime.setValue(null);
      this._deliveryForm.controls.valetEndTime.setValue(null);
    }

    if(this.selectedService?.service?.pricingModel === 'hourlyrate'){
      this._deliveryForm.controls.distance.setValue(null);
      this._deliveryForm.controls.startTime.setValue(this.previousValues.startTime);
      this._deliveryForm.controls.endTime.setValue(this.previousValues.endTime);
      this._deliveryForm.controls.fromTime.setValue(this.previousValues.startTime);
      this._deliveryForm.controls.toTime.setValue(this.previousValues.endTime);
      this._deliveryForm.controls.price.setValue(this.selectedService?.price);
      this._deliveryForm.controls.productValue.setValue(null);
      this._deliveryForm.controls.pickUpAddress.setValue(pickUpAddress);
      this.totalValetTimeDifference = 1;
      this._deliveryForm.controls.valetStartTime.setValue(this.previousValues.startTime);
      this._deliveryForm.controls.valetEndTime.setValue(this.previousValues.endTime);
      this.previousValetTimeValues.valetStartTime = this._deliveryForm.value.startTime;
      this.previousValetTimeValues.valetEndTime = this._deliveryForm.value.endTime;
    }

    if(this.selectedService?.service?.pricingModel === 'sales'){
      this._deliveryForm.controls.pickUpAddress.setValue(pickUpAddress);
      this._deliveryForm.controls.startTime.setValue(null);
      this._deliveryForm.controls.distance.setValue(null);
      this._deliveryForm.controls.endTime.setValue(null);
      this._deliveryForm.controls.hours.setValue(null);
      this.setProductValue();
      this._deliveryForm.controls.valetStartTime.setValue(null);
      this._deliveryForm.controls.valetEndTime.setValue(null);
    }

    let flexbleDelivery: boolean = this.selectedService?.service?.pricingModel === 'fixedprice';
    let deluxyDelivery: boolean = this.selectedService?.service?.pricingModel === 'sales';
    this._deliveryForm.controls.requestExpert.setValue(flexbleDelivery);
    this._deliveryForm.controls.deluxyDelivery.setValue(deluxyDelivery);
    this.showStartEndTime = this.selectedService?.service?.pricingModel === 'hourlyrate';
    this.showProductValue = this.selectedService?.service?.pricingModel === 'sales';
    this.showPickUpAddress = this.selectedService?.service?.pricingModel === 'fixedprice';
    this.showDistanceInput = this.selectedService?.service?.pricingModel === 'fixedprice';
    this.expertServiceAndSalary();
  }

  reset(){
    this._deliveryForm.controls.pickUpAddress.setValue(null);
    this._deliveryForm.controls.startTime.setValue(null);
    this._deliveryForm.controls.distance.setValue(null);
    this._deliveryForm.controls.endTime.setValue(null);
    this._deliveryForm.controls.price?.setValue(null);
    this._deliveryForm.controls.productValue?.setValue(null);
    this._deliveryForm.controls.service?.setValue(null);
    this._deliveryForm.controls.hours.setValue(null);
    this._deliveryForm.controls.requestExpert.setValue(false);
    this._deliveryForm.controls.deluxyDelivery.setValue(false);
    this.showStartEndTime = false;
    this.showProductValue = false;
    this.showPickUpAddress= false;
    this.showDistanceInput= false;
  }

  handleStartTimeChange(value: string) {
    this._deliveryForm.controls.startTime.setValue(value);
    const startTime = new Date('1970-01-01T' + this._deliveryForm.value?.startTime + ':00');
    const endTime = new Date(startTime.getTime() + 3600000);
    const endTimeString = this.formatTime(endTime);
    this._deliveryForm.controls.endTime.setValue(endTimeString);
    this._deliveryForm.controls.fromTime.setValue(value);
    this._deliveryForm.controls.toTime.setValue(endTimeString);
    this.calculateTimeDifference();
  }

  formatTime(date: Date): string {
    return date.toTimeString().slice(0, 5); // Format as HH:MM
  }

  handleEndTimeChange(value: string) {
    this._deliveryForm.controls.endTime.setValue(value);
    this._deliveryForm.controls.toTime.setValue(value);
    this.calculateTimeDifference();
  }

  calculateTimeDifference() {
    const startTime = new Date('1970-01-01T' + this._deliveryForm.value?.startTime + ':00');
    const endTime = new Date('1970-01-01T' + this._deliveryForm.value?.endTime + ':00');
    const timeDiff = endTime.getTime() - startTime.getTime();
    const hours = Math.floor(timeDiff / 3600000);
    if(hours <= 0){
      alert('Minimum one hour is required');
      this._deliveryForm.controls.startTime.setValue(this.previousValues.startTime);
      this._deliveryForm.controls.endTime.setValue(this.previousValues.endTime);
      this._deliveryForm.controls.fromTime.setValue(this.previousValues.startTime);
      this._deliveryForm.controls.toTime.setValue(this.previousValues.endTime);
      this._deliveryForm.controls.valetStartTime.setValue(this.previousValues.startTime);
      this._deliveryForm.controls.valetEndTime.setValue(this.previousValues.endTime);
      return false;
    }
    const minutes = Math.floor((timeDiff % 3600000) / 60000);
    // let totalTime = `${hours}.${minutes}`;
    const totalTime = hours + minutes / 60;
    this.totalDeliveryTime = totalTime?.toFixed(2);
    this.totalValetTimeDifference = totalTime?.toFixed(2);
    console.log('this.totalDeliveryTime', this.totalDeliveryTime)
    let price = hours !== 0 ? Number(this.selectedService?.price) * Number(this.totalDeliveryTime) : Number(this.selectedService?.price)
    this._deliveryForm.controls.price.setValue(price?.toFixed(2));
    this._deliveryForm.controls.hours.setValue(this.totalDeliveryTime);
    this.previousValues.startTime = this._deliveryForm.value.startTime;
    this.previousValues.endTime = this._deliveryForm.value.endTime;
    this.previousValetTimeValues.valetStartTime = this._deliveryForm.value.startTime;
    this.previousValetTimeValues.valetEndTime = this._deliveryForm.value.endTime;
    this.expertServiceAndSalary();
  }

  partnerChange(partnerId: any){
    if(this.partners){
      this.selectedPartner = this.partners.find((partner) => {
        return partner.id == partnerId;
      });
    }
    this.selectedPartnerServices = this.selectedPartner?.partnerServices;
    let pickUpAddress = (this.selectedPartner ? this.selectedPartner?.address : '');
    this._deliveryForm.controls.pickUpAddress.setValue(pickUpAddress);
    if (this.selectedPartnerServices) {
      this.selectedService = this.selectedPartnerServices.map(service => {
          return { label: service.name, value: service.id };
        });
    }
  }

  handlePartnerChangeDropDown(partnerId: any){
    this._deliveryForm.controls.service.setValue(null);
    this.reset();
    if(this.partners){
      this.selectedPartner = this.partners.find((partner) => {
        return partner.id == partnerId;
      });
    }
    this.selectedPartnerServices = this.selectedPartner?.partnerServices;
    let pickUpAddress = (this.selectedPartner ? this.selectedPartner?.address : '');
    this._deliveryForm.controls.pickUpAddress.setValue(pickUpAddress);
    if (this.selectedPartnerServices) {
      this.selectedService = this.selectedPartnerServices.map(service => {
          return { label: service.name, value: service.id };
        });
    }
  }

  handleProductChange(index?: number, item?: any){
    let selctedProduct = this.products.find(product => product?.id == item?.id);
    this.showProductImage[index] = selctedProduct.image ? true : false;
    this.productImageUrls[index] = selctedProduct.image ? selctedProduct.image : '';
    this.setProductValue();
  }

  handleProductQuantityChange(){
    this.setProductValue();
  }

  setProductValue(){
    if(this.selectedService?.service?.pricingModel === 'sales'){
      this.selectedProducts = this._deliveryForm.controls.deliveryProducts?.value;
      this.TotalSelectedProPrice = this.selectedProducts?.map(item => {
        const product = this.products.find(p => p.id === item.product.id);
        const quantity = Number(item?.product?.quantity);
        const price = Number(product?.price);
        if (!isNaN(price) && !isNaN(quantity)) {
            return price * quantity;
        } else {
            return 0;
          }
      }).reduce((acc, curr) => acc + curr, 0);

      this._deliveryForm.controls.price?.setValue(this.TotalSelectedProPrice ? (this.selectedService?.price * this.TotalSelectedProPrice)/100 : 0);
      this._deliveryForm.controls.productValue?.setValue(this.TotalSelectedProPrice ? this.TotalSelectedProPrice : 0);
    }
  }

  handleDistanceChange(distance: any){
    let price: any = 0;
    if((this.selectedService?.service?.pricingModel?.toLowerCase() === 'fixedprice') || (this.selectedService?.service?.pricingModel.toLowerCase() == 'corporate')){
      if(distance > this.selectedPartner?.kmIncluded && this.selectedPartner?.kmIncluded){
        let extraKm = (distance - this.selectedPartner?.kmIncluded);
        price = parseFloat(this.selectedService?.price) + (extraKm * this.selectedService?.extraKmPrice);
      } else {
        price = parseFloat(this.selectedService?.price)
      }
    }
    this._deliveryForm.controls.price?.setValue(parseFloat(price)?.toFixed(2));
    this._deliveryForm.controls.distance?.setValue(distance.toFixed(2));
  }

  getPickUpAddress(place: any) {
    if(this.selectedService?.service?.pricingModel === 'fixedprice'){
      try {
        if (!this._deliveryForm) return;
        this._deliveryForm.controls.pickUpAddress.touched = true;
        this._deliveryForm.controls.pickUpAddress.setValue(place.formatted_address || place.name || '');
        let lat = place.geometry?.location?.lat() || '';
        let lng = place.geometry?.location?.lng() || '';
        let fixedAddressLatLng = new google.maps.LatLng(lat, lng);
        let selectedAddressLatLng = new google.maps.LatLng(this._deliveryForm.controls.latitude.value, this._deliveryForm.controls.longitude.value)
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        distanceMatrixService.getDistanceMatrix({
          origins: [fixedAddressLatLng],
          destinations: [selectedAddressLatLng],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const distance = response.rows[0].elements[0].distance?.value;
            const distanceInKm = distance / 1000;
            this._deliveryForm.controls.distance.setValue(distanceInKm.toFixed(2));
            this.handleDistanceChange(distanceInKm);
          } else {
            console.error('Error calculating distance:', status);
          }
        });
      } catch (error) {
        console.log('Error', error.message)
      }
    }else {
      this._deliveryForm.controls.pickUpAddress.setValue(place.formatted_address || place.name || '');
    }
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }


  }
  pickUpDirections(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this._deliveryForm.controls.pickUpAddress.value}`, '_blank');
  }


  handlePickUpFlexbleTime(){
    if(this._deliveryForm.controls.isFlexblePickUpTime.value){
      this.pickUpFTimeError = !this.pickUpFTime;
      this.pickUpTTimeError = !this.pickUpTTime;
    }
    if(this.pickUpFTime && this.pickUpTTime){
      let time = this.pickUpFTime + "-" + this.pickUpTTime;
      this._deliveryForm.controls.pickUpTime?.setValue(time);
    }
  }

  patchPickUpFlexbleTime(){
    let [startTime, endTime] = this.delivery.pickUpTime.split("-");
    this.pickUpFTime = startTime;
    this.pickUpTTime = endTime;
  }

  handleFlexbileDelivery(event: any){
    return false;
  }

  handleBillable(event: any){
    this._deliveryForm.controls.bilable?.setValue(event.target.checked);
    this.checkDeliveryIsBillable();
  }

  checkDeliveryIsBillable(){
    if(!this._deliveryForm.controls.billable.value){    // Delivery is not billable
      this._deliveryForm.controls.price.setValue(0);
      this._deliveryForm.controls.additionalPrice.setValue(0);
    }
    this.disableAdditionalPriceInput = !this._deliveryForm.controls.billable.value;
  }

  handleValetServiceChange(valetServiceId: number){
    this.selectedExpertService = this.selectedExpertServices.find(expertService => expertService?.id == valetServiceId);
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'hourlyratesalary'){
      this.calculateHourlyRateValetSalary();
    }
  }

  handlePayable(event: any){
    this._deliveryForm.controls.bilable?.setValue(event.target.checked);
    this.checkDeliveryIsPayable();
  }

  checkDeliveryIsPayable(){
    if(!this._deliveryForm.controls.payable.value){    // Delivery is not payable
      this.setToZeroIfNotPayable();
    }
    this.disableValetAdditionalPriceInput = !this._deliveryForm.controls.payable.value;
  }

  setToZeroIfNotPayable(){
    this._deliveryForm.controls.expertSalary.setValue(0);
    this._deliveryForm.controls.valetAdditionalPrice.setValue(0);
  }

  /* Old Function
  async calculateFixedPriceValetSalary(){
    if(this.selectedExpert){
      this._deliveryForm.controls.valetStartTime.setValue(null);
      this._deliveryForm.controls.valetEndTime.setValue(null);
      this.showValetStartAndEndTime = false;
      let pickUpAddressResult: any[];
      if(this._deliveryForm.controls.pickUpAddress.value){
        pickUpAddressResult = await this.getLatLngByAddress(this._deliveryForm.controls.pickUpAddress.value ? this._deliveryForm.controls.pickUpAddress.value : '');
      }

      if(pickUpAddressResult && pickUpAddressResult.length > 0){
        let delveryAddLat = this._deliveryForm.controls.latitude.value || '';
        let delveryAddLng = this._deliveryForm.controls.longitude.value || '';
        let pickUpAddLat = pickUpAddressResult[0].geometry?.location?.lat() || '';
        let pickUpAddLng = pickUpAddressResult[0].geometry?.location?.lng() || '';
        const pickUpAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
        const deliveryAddressLatLng = new google.maps.LatLng(delveryAddLat, delveryAddLng);
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        distanceMatrixService.getDistanceMatrix({
          origins: [pickUpAddressLatLng],
          destinations: [deliveryAddressLatLng],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const distance = response.rows[0].elements[0].distance?.value;
            const distanceInKm = distance / 1000;
            let valetSalary: any = 0;
            if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
              if(this.selectedExpert?.minimumKmIncluded && distanceInKm > this.selectedExpert?.minimumKmIncluded){
                let extraKm = (distanceInKm - this.selectedExpert?.minimumKmIncluded);
                valetSalary = (this.selectedExpertService?.salary) + (extraKm * Number(this.selectedExpertService?.minimumKmPrice));
              } else {
                valetSalary = this.selectedExpertService?.salary;
              }
            }
            this._deliveryForm.controls.expertSalary?.setValue(parseFloat(valetSalary)?.toFixed(2));
            this._deliveryForm.controls.expertServiceId.setValue(this.selectedExpertService?.id);
            if(!this._deliveryForm.controls.payable.value){
              this.setToZeroIfNotPayable();
            }
            console.log('distanceInKm', distanceInKm);
            console.log('valetSalary', this._deliveryForm.controls.expertSalary?.value)
            console.log('this.selectedExpertService?.service?.serviceType', this.selectedExpertService?.service?.serviceType);
          } else {
            console.error('Error calculating distance:', status);
          }
        });
      }
    }
  }
  Old Function */

  async calculateFixedPriceValetSalary() {
    try {
      if (this.selectedExpert) {
        this._deliveryForm.controls.valetStartTime.setValue(null);
        this._deliveryForm.controls.valetEndTime.setValue(null);
        this.showValetStartAndEndTime = false;

        let pickUpAddLat: any;
        let pickUpAddLng: any;
        let deliveryAddLat: any;
        let deliveryAddLng: any;

        if (this._deliveryForm.controls.pickUpAddress.value) {
          const pickUpAddressResult = await this.getLatLngByAddress(this._deliveryForm.controls.pickUpAddress.value);
          pickUpAddLat = pickUpAddressResult[0]?.geometry?.location?.lat() || '';
          pickUpAddLng = pickUpAddressResult[0]?.geometry?.location?.lng() || '';
        }

        if (this._deliveryForm.controls.address.value) {
          const deliveryAddressResult = await this.getLatLngByAddress(this._deliveryForm.controls.address.value);
          deliveryAddLat = deliveryAddressResult[0]?.geometry?.location?.lat() || '';
          deliveryAddLng = deliveryAddressResult[0]?.geometry?.location?.lng() || '';
        }

        if (pickUpAddLat && pickUpAddLng && deliveryAddLat && deliveryAddLng) {
          const pickUpAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
          const deliveryAddressLatLng = new google.maps.LatLng(deliveryAddLat, deliveryAddLng);
          const distanceMatrixService = new google.maps.DistanceMatrixService();

          const response: any = await new Promise((resolve, reject) => {
            distanceMatrixService.getDistanceMatrix({
              origins: [pickUpAddressLatLng],
              destinations: [deliveryAddressLatLng],
              travelMode: google.maps.TravelMode.DRIVING,
            }, (response, status) => {
              if (status === google.maps.DistanceMatrixStatus.OK) {
                resolve(response);
              } else {
                reject(status);
              }
            });
          });

          const distance = response.rows[0].elements[0].distance?.value;
          const distanceInKm = distance / 1000;
          let valetSalary: number = 0;

          if (this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary') {
            if (this.selectedExpert?.minimumKmIncluded && distanceInKm > this.selectedExpert?.minimumKmIncluded) {
              const extraKm = distanceInKm - this.selectedExpert?.minimumKmIncluded;
              valetSalary = this.selectedExpertService.salary + (extraKm * Number(this.selectedExpertService.minimumKmPrice));
            } else {
              valetSalary = this.selectedExpertService.salary;
            }
          }

          this._deliveryForm.controls.expertSalary?.setValue(valetSalary.toFixed(2));
          this._deliveryForm.controls.expertServiceId.setValue(this.selectedExpertService?.id);

          if (!this._deliveryForm.controls.payable.value) {
            this.setToZeroIfNotPayable();
          }

          console.log('distanceInKm', distanceInKm);
          console.log('valetSalary', this._deliveryForm.controls.expertSalary?.value);
          console.log('this.selectedExpertService?.service?.serviceType', this.selectedExpertService?.service?.serviceType);

        } else {
          alert("Can not calculate distance. Try again with valid address, Check both pickup address and delivery address");
          return;
        }
      }
    } catch (error) {
      console.error('Error calculating distance:', error);
      alert("Can not calculate distance. Try again with valid address, Check both pickup address and delivery address");
    }
  }


  async getLatLngByAddress(address: string){
    const geocoder = new google.maps.Geocoder();
    const results = await new Promise<any>((resolve, reject) => {
      geocoder.geocode({ 'address': address }, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
    return results;
  }

  /*
  handleValetPlusMinusPrice(event: any){
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'hourlyratesalary'){
      this.calculateHourlyRateValetSalary();
    }
  }
  */

  calculateHourlyRateValetSalary(){
    if(this.selectedExpert){
      this.showValetStartAndEndTime = true;
      this._deliveryForm.controls.valetStartTime.setValue(this.previousValetTimeValues.valetStartTime);
      this._deliveryForm.controls.valetEndTime.setValue(this.previousValetTimeValues.valetEndTime);
      let valetSalary = (this.totalValetTimeDifference * this.selectedExpertService?.salary)?.toFixed(2);
      this._deliveryForm.controls.expertSalary.setValue(valetSalary);
      this._deliveryForm.controls.expertServiceId.setValue(this.selectedExpertService?.id);
      if(!this._deliveryForm.controls.payable.value){
        this.setToZeroIfNotPayable();
      }
      console.log('valetSalary', this._deliveryForm.controls.expertSalary?.value)
      console.log('this.selectedExpertService?.service?.serviceType', this.selectedExpertService?.service?.serviceType);
    }
  }

  handleValetStartTimeChange(value: string){
    this._deliveryForm.controls.valetStartTime.setValue(value);
    const startTime = new Date('1970-01-01T' + this._deliveryForm.controls.valetStartTime.value + ':00');
    const endTime = new Date(startTime.getTime() + 3600000);
    const endTimeString = this.formatTime(endTime);
    this._deliveryForm.controls.valetEndTime.setValue(endTimeString);
    this.calculateValetTimeDifference();
  }

  handleValetEndTimeChange(value: string){
    this._deliveryForm.controls.valetEndTime.setValue(value);
    this.calculateValetTimeDifference();
  }

  calculateValetTimeDifference() {
    const startTime = new Date('1970-01-01T' + this._deliveryForm.controls.valetStartTime.value + ':00');
    const endTime = new Date('1970-01-01T' + this._deliveryForm.controls.valetEndTime.value + ':00');
    const timeDiff = endTime.getTime() - startTime.getTime();
    const hours = Math.floor(timeDiff / 3600000);
    if(hours <= 0){
      alert('Minimum one hour is required');
      this._deliveryForm.controls.valetStartTime.setValue(this.previousValetTimeValues.valetStartTime);
      this._deliveryForm.controls.valetEndTime.setValue(this.previousValetTimeValues.valetEndTime);
      return false;
    }
    const minutes = Math.floor((timeDiff % 3600000) / 60000);
    // let totalTime = `${hours}.${minutes}`;
    const totalTime = hours + minutes / 60;
    this.totalValetTimeDifference = totalTime.toFixed(2);
    console.log('this.totalValetTimeDifference', this.totalValetTimeDifference)
    this.previousValetTimeValues.valetStartTime = this._deliveryForm.controls.valetStartTime.value;
    this.previousValetTimeValues.valetEndTime = this._deliveryForm.controls.valetEndTime.value;
    this.calculateHourlyRateValetSalary();
  }

  getErrorMessages(): string[] {
    const errorMessages: string[] = [];

    Object.keys(this._deliveryForm.controls).forEach(key => {
      const controlErrors = this._deliveryForm.get(key)?.errors;
      if (controlErrors) {
        const fieldValidationMessages = Delivery.validationMessages[key];
        if (fieldValidationMessages) {
          Object.keys(controlErrors).forEach(errorKey => {
            const messageObj = fieldValidationMessages.find((msg) => msg.type === errorKey);
            if (messageObj) {
              if (key === 'address' && !errorMessages.includes('address')) {
                errorMessages.push('address');
              } else if (key !== 'address') {
                errorMessages.push(key);
              }
            }
          });
        }
      }
    });

    const deliveryProductsArray = <FormArray>this._deliveryForm.get('deliveryProducts');
    deliveryProductsArray.controls.forEach((productGroup, index) => {
      const productControl: any = productGroup.get('product');
      const quantityControl = productGroup.get('quantity');

      if (productControl) {
        productControl.updateValueAndValidity();
      }

      if (quantityControl) {
        quantityControl.updateValueAndValidity();
      }
      const productErrors = productControl.controls.id.errors;
      const quantityErrors = productControl.controls.quantity.errors;

      if (productErrors) {
        errorMessages.push('product');
      }

      if (quantityErrors) {
        errorMessages.push('quantity');
      }
    });

    return errorMessages;
  }

  translateErrorMessage(error: string): string {
    const errorMessages: { [key: string]: string } = {
      address: 'VALIDARE INDIRIZZO',
      name: 'NOME',
      surname: 'COGNOME',
      intercom: 'CITOFONO',
      deliveryDate: 'DATA',
      pickUpTime: 'ORA RITIRO',
      service: 'SERVIZIO',
      product: 'PRODOTTO',
      receiverPhone: 'VALIDARE DESTINATARIO TELEFONO',
      senderPhone: 'VALIDARE MITTENTE TELEFONO',
      smsPhoneNo: 'VALIDARE SMS TELEFONICI',
    };
    return errorMessages[error] || '';
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}
