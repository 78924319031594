import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Delivery } from 'src/app/models/delivery.schema';
import { ShowImageComponent } from '../show-image/show-image.component';

@Component({
  selector: 'app-sale-info',
  templateUrl: './sale-info.component.html',
  styleUrls: ['./sale-info.component.scss']
})
export class SaleInfoComponent implements OnInit {

  saleDelivery: any;
  delivery: Delivery;
  totalProductPrice: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { saleDelivery: any },
    public dialog: MatDialog,
  ) { 
    this.saleDelivery = data?.saleDelivery;
    this.delivery = JSON.parse(this.saleDelivery?.deliveryInfo);
  }

  async ngOnInit() {
    await this.getTotalOfProduct();
  }

  async getTotalOfProduct(){
    if(this.delivery?.deliveryProducts){
      this.delivery?.deliveryProducts.forEach((product) => {
        this.totalProductPrice += Number(product?.product?.price)
      })
    }
  }
  
  openImage(imageUrl: string) {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }

  showImage(product: any){
    let ref = this.dialog.open(ShowImageComponent, { panelClass: 'partner-time-dialog', width: '50vw', data: { product } });
    ref.afterClosed().subscribe(result => {});
  }

}
