import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { JwtModule } from '@auth0/angular-jwt';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BasicAuthInterceptor } from './helpers/basic-auth.interceptor';
import { GroupRestricted } from './helpers/guards/group-restricted.guard';
import { LogOut } from './helpers/guards/log-out.guard';
import { UserLoggedIn } from './helpers/guards/logged-in.guard';
import { CanActivateLogIn } from './helpers/guards/log-in.guard';
import { DeliveryCanActivateLogIn } from './helpers/guards/delivery-log-in.guard';
import { ApiService } from './services/api.service';
import { ApiAuthService } from './services/api-auth.service';
import { AuthStatusService } from './services/auth-status.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ArrayJoinPipe } from './pipes/array-join.pipe';
import { DurationEnumPipe } from './pipes/duration-enum.pipe';
import { FormGroupToObject } from './pipes/form-group-to-object.pipe';
import { TimePipe } from './pipes/time.pipe';
import { ProductImagePipe } from './pipes/product-image.pipe';
import { FancyDatePipe } from './pipes/fancy-date.pipe';
import { RequiredPipe } from './pipes/required-symbol.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter'

import { ObserverService } from './services/observers/observer.service';
import { DeliveryObserverService } from './services/observers/delivery-observer.service';
import { DeliveryService } from './services/delivery.service';
import { ExpertService } from './services/expert.service';
import { GroupService } from './services/group.service';
import { PartnerService } from './services/partner.service';
import { UserService } from './services/user.service';
import { OfferService } from './services/offer.service';

import { LoginComponent } from './components/auth/login/login.component';
import { NewPasswordFormComponent } from './components/auth/new-password-form/new-password-form.component';
import { RegistrationComponent } from './components/auth/registration/registration.component';
import { ResendActivationComponent } from './components/auth/resend-activation/resend-activation.component';
import { PasswordRecoveryComponent } from './components/auth/password-recovery/password-recovery.component';

import { NavbarComponent } from './components/navbar/navbar.component';
import { DeliveriesComponent } from './components/deliveries/deliveries.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { DeliveryFormComponent } from './components/delivery-form/delivery-form.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ErrorHtmlHandlerComponent } from './components/_error-html-handler/error-html-handler.component';
import { PaginationComponent } from './components/_pagination/pagination.component';
import { ExpertsComponent } from './components/experts/experts.component';
import { ExpertFormComponent } from './components/expert-form/expert-form.component';
import { PartnersComponent } from './components/partners/partners.component';
import { PartnerFormComponent } from './components/partner-form/partner-form.component';
import { DeliveryExpertFormComponent } from './components/delivery-expert-form/delivery-expert-form.component';
import { UsersComponent } from './components/users/users.component';
import { PartnerFormDecoratorComponent } from './components/partner-form-decorator/partner-form-decorator.component';
import { ExpertFormDecoratorComponent } from './components/expert-form-decorator/expert-form-decorator.component';
import { AdminFormComponent } from './components/admin-form/admin-form.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OffersComponent } from './components/offers/offers.component';
import { OfferFormComponent } from './components/offer-form/offer-form.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { DeliveryLinkDialogComponent } from './components/delivery-link-dialog/delivery-link-dialog.component';
import { DeliveryNotDeliveredDialogComponent } from './components/delivery-not-delivered-dialog/delivery-not-delivered-dialog.component';
import { GoogleMapsSearchComponent } from './components/google-maps-search/google-maps-search.component';
import { SmsTemplatesComponent } from './components/sms-templates/sms-templates.component';
import { SmsTemplatesFormComponent } from './components/sms-templates-form/sms-templates-form.component';
import { AdminSmsTemplatesComponent } from './components/admin-sms-templates/admin-sms-templates.component';
import { AdminSmsTemplateFormComponent } from './components/admin-sms-templates-form/admin-sms-template-form.component';
import { ShopifySaleComponent } from './components/shopify-sale/shopify-sale.component';
import { ShopifySaleFormDialogComponent } from './components/shopify-sale-form-dialog/shopify-sale-form-dialog.component';
import { DeliveryImportDialogComponent } from './components/delivery-import-dialog/delivery-import-dialog.component';
import { ShopifySaleFormComponent } from './components/shopify-sale-form/shopify-sale-form.component';
import { UnderMaintinanceComponent } from './components/under-maintinance/under-maintinance.component';
import { PartnerInvoiceComponent } from './components/partner-invoice/partner-invoice.component';
import { PartnerInvoiceFormComponent } from './components/partner-invoice-form/partner-invoice-form.component';
import { ServiceComponent } from './components/service/service.component';
import { ServiceFormComponent } from './components/service-form/service-form.component';
import { DuplicateDeliveryComponent } from './components/duplicate-delivery/duplicate-delivery.component';
import { CommonModule } from '@angular/common';
import { DuplicatePartnerComponent } from './components/duplicate-partner/duplicate-partner.component';
import { ExpertServiceComponent } from './components/expert-service/expert-service.component';
import { ExpertServiceFormComponent } from './components/expert-service-form/expert-service-form.component';
import { ExpertSalaryComponent } from './components/expert-salary/expert-salary.component';
import { ExpertSalaryDialogComponent } from './components/expert-salary-dialog/expert-salary-dialog.component';
import { ProductCategoryComponent } from './components/product-category/product-category.component';
import { ProductCategoryFormComponent } from './components/product-category-form/product-category-form.component';
import { TestComponent } from './components/test/test.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { PartnerAvailabilityComponent } from './components/partner-availability/partner-availability.component';
import { ExpertAvailabilityComponent } from './components/expert-availability/expert-availability.component';
import { SetAvailabilityTimeComponent } from './components/set-availability-time/set-availability-time.component';
import { PartnerAvailabilityListComponent } from './components/partner-availability-list/partner-availability-list.component';
import { ExpertAvailabilityListComponent } from './components/expert-availability-list/expert-availability-list.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { ShowImageComponent } from './components/show-image/show-image.component';
import { DuplicateProductComponent } from './components/duplicate-product/duplicate-product.component';
import { PartnerPriorityListComponent } from './components/partner-priority-list/partner-priority-list.component';
import { PartnerPriorityListFormComponent } from './components/partner-priority-list-form/partner-priority-list-form.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfirmSaleComponent } from './components/confirm-sale/confirm-sale.component';
import { PartnerSaleActivityComponent } from './components/partner-sale-activity/partner-sale-activity.component';
import { DuplicatePartnerPriorityListComponent } from './components/duplicate-partner-priority-list/duplicate-partner-priority-list.component';
import { SaleDeliveryDetailComponent } from './components/sale-delivery-detail/sale-delivery-detail.component';
import { CakeOrdersDetailsComponent } from './components/cake-orders-details/cake-orders-details.component';
import { PartialUpdateDeliveryComponent } from './components/partial-update-delivery/partial-update-delivery.component';
import { OperationComponent } from './components/operation/operation.component';
import { OperationFormComponent } from './components/operation-form/operation-form.component';
import { OperationFormDecoratorComponent } from './components/operation-form-decorator/operation-form-decorator.component';
import { TeamLeaderComponent } from './components/team-leader/team-leader.component';
import { TeamLeaderFormComponent } from './components/team-leader-form/team-leader-form.component';
import { TeamLeaderFormDecoratorComponent } from './components/team-leader-form-decorator/team-leader-form-decorator.component';
import { ValetPriorityListComponent } from './components/valet-priority-list/valet-priority-list.component';
import { ValetPriorityListFormComponent } from './components/valet-priority-list-form/valet-priority-list-form.component';
import { DuplicateValetPriorityListComponent } from './components/duplicate-valet-priority-list/duplicate-valet-priority-list.component';
import { ValetOwnAvailabilityComponent } from './components/valet-own-availability/valet-own-availability.component';
import { CakeSaleComponent } from './components/cake-sale/cake-sale.component';
import { BusinessSaleComponent } from './components/business-sale/business-sale.component';
import { DeluxyFlowersSaleComponent } from './components/deluxy-flowers-sale/deluxy-flowers-sale.component';
import { CakeSaleFormComponent } from './components/cake-sale-form/cake-sale-form.component';
import { CakeSaleFormDialogComponent } from './components/cake-sale-form-dialog/cake-sale-form-dialog.component';
import { BusinessSaleFormDialogComponent } from './components/business-sale-form-dialog/business-sale-form-dialog.component';
import { BusinessSaleFormComponent } from './components/business-sale-form/business-sale-form.component';
import { DeluxyFlowersSaleFormComponent } from './components/deluxy-flowers-sale-form/deluxy-flowers-sale-form.component';
import { DeluxyFlowersSaleFormDialogComponent } from './components/deluxy-flowers-sale-form-dialog/deluxy-flowers-sale-form-dialog.component';
import { AllSalesComponent } from './components/all-sales/all-sales.component';
import { PartnerDeliveryRulesComponent } from './components/partner-delivery-rules/partner-delivery-rules.component';
import { PartnerDeliveryRulesFormComponent } from './components/partner-delivery-rules-form/partner-delivery-rules-form.component';
import { ExpertDeliveryRulesComponent } from './components/expert-delivery-rules/expert-delivery-rules.component';
import { ExpertDeliveryRulesFormComponent } from './components/expert-delivery-rules-form/expert-delivery-rules-form.component';
import { ProductPriorityListComponent } from './components/product-priority-list/product-priority-list.component';
import { ProductPriorityListFormComponent } from './components/product-priority-list-form/product-priority-list-form.component';
import { AiCakeOrderInfoModalComponent } from './components/ai-cake-order-info-modal/ai-cake-order-info-modal.component';
import { ProductStatusMultipleComponent } from './components/product-status-multiple/product-status-multiple.component';
import { SaleInfoComponent } from './components/sale-info/sale-info.component'; 
import { ValetActivitiesComponent } from './components/valet-activities/valet-activities.component';
import { AdminValetActivityComponent } from './components/admin-valet-activity/admin-valet-activity.component'; 
import { ServiceNamePipe } from './pipes/service-name.pipe';
import { SaleNamePipe } from './pipes/sale-name.pipe';
import { CommanModalComponent } from './components/comman-modal/comman-modal.component';
import { ApproveTimingsComponent } from './components/approve-timings/approve-timings.component';
import { ApproveTimingsAdminComponent } from './components/approve-timings-admin/approve-timings-admin.component';
import { StartEndTimeEditComponent } from './components/start-end-time-edit/start-end-time-edit.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ActvitiesComponent } from './components/actvities/actvities.component';
import { ShowNotesComponent } from './components/show-notes/show-notes.component';
import { ActivityRedirection } from './helpers/guards/activity-redirection.guard';
import { ShowMapDirectionComponent } from './components/show-map-direction/show-map-direction.component';
import { ApproveService22TimingsComponent } from './components/approve-service22-timings/approve-service22-timings.component';
import { ReviewService22TimingsComponent } from './components/review-service22-timings/review-service22-timings.component';
import { CustomersComponent } from './components/customers/customers.component';
import { CustomerFormComponent } from './components/customer-form/customer-form.component';
import { CustomerFormDecoratorComponent } from './components/customer-form-decorator/customer-form-decorator.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ProvinceCitiesComponent } from './components/province-cities/province-cities.component';
import { ProvinceCitiesFormComponent } from './components/province-cities-form/province-cities-form.component';
import { CitiesListPopUpComponent } from './components/cities-list-pop-up/cities-list-pop-up.component';
import { GoogleMapCitiesComponent } from './components/google-map-cities/google-map-cities.component';

export function tokenGetter() {
  return localStorage.getItem('token');
}

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);
@NgModule({
  declarations: [
    EnumToArrayPipe,
    DateAgoPipe,
    TimePipe,
    ArrayJoinPipe,
    DurationEnumPipe,
    ProductImagePipe,
    FancyDatePipe,
    RequiredPipe,
    ReplacePipe,
    FormGroupToObject,

    LoginComponent,
    NewPasswordFormComponent,
    RegistrationComponent,
    ResendActivationComponent,
    PasswordRecoveryComponent,

    ErrorHtmlHandlerComponent,
    PaginationComponent,
    AppComponent,
    NavbarComponent,

    DeliveriesComponent,
    DeliveryComponent,
    DeliveryFormComponent,
    NotFoundComponent,
    ExpertsComponent,
    ExpertFormComponent,
    PartnersComponent,
    PartnerFormComponent,
    DeliveryExpertFormComponent,
    UsersComponent,
    PartnerFormDecoratorComponent,
    ExpertFormDecoratorComponent,
    AdminFormComponent,
    ProfileComponent,
    OffersComponent,
    OfferFormComponent,
    ProductsComponent,
    ProductFormComponent,
    DeliveryLinkDialogComponent,
    DeliveryNotDeliveredDialogComponent,
    GoogleMapsSearchComponent,
    SmsTemplatesComponent,
    SmsTemplatesFormComponent,
    AdminSmsTemplatesComponent,
    AdminSmsTemplateFormComponent,
    ShopifySaleComponent,
    ShopifySaleFormDialogComponent,
    DeliveryImportDialogComponent,
    ShopifySaleFormComponent,
    UnderMaintinanceComponent,
    PartnerInvoiceComponent,
    PartnerInvoiceFormComponent,
    ServiceComponent,
    ServiceFormComponent,
    DuplicateDeliveryComponent,
    DuplicatePartnerComponent,
    ExpertServiceComponent,
    ExpertServiceFormComponent,
    ExpertSalaryComponent,
    ExpertSalaryDialogComponent,
    ProductCategoryComponent,
    ProductCategoryFormComponent,
    TestComponent,
    PartnerAvailabilityComponent,
    ExpertAvailabilityComponent,
    SetAvailabilityTimeComponent,
    PartnerAvailabilityListComponent,
    ExpertAvailabilityListComponent,
    ReceiptComponent,
    ShowImageComponent,
    DuplicateProductComponent,
    PartnerPriorityListComponent,
    PartnerPriorityListFormComponent,
    ConfirmSaleComponent,
    PartnerSaleActivityComponent,
    DuplicatePartnerPriorityListComponent,
    SaleDeliveryDetailComponent,
    CakeOrdersDetailsComponent,
    PartialUpdateDeliveryComponent,
    OperationComponent,
    OperationFormComponent,
    OperationFormDecoratorComponent,
    TeamLeaderComponent,
    TeamLeaderFormComponent,
    TeamLeaderFormDecoratorComponent,
    ValetPriorityListComponent,
    ValetPriorityListFormComponent,
    DuplicateValetPriorityListComponent,
    ValetOwnAvailabilityComponent,
    CakeSaleComponent,
    BusinessSaleComponent,
    DeluxyFlowersSaleComponent,
    CakeSaleFormComponent,
    CakeSaleFormDialogComponent,
    BusinessSaleFormDialogComponent,
    BusinessSaleFormComponent,
    DeluxyFlowersSaleFormComponent,
    DeluxyFlowersSaleFormDialogComponent,
    AllSalesComponent,
    PartnerDeliveryRulesComponent,
    PartnerDeliveryRulesFormComponent,
    ExpertDeliveryRulesComponent,
    ExpertDeliveryRulesFormComponent,
    ProductPriorityListComponent,
    ProductPriorityListFormComponent,
    AiCakeOrderInfoModalComponent,
    ProductStatusMultipleComponent,
    ValetActivitiesComponent,
    AdminValetActivityComponent,
    SaleInfoComponent,
    ServiceNamePipe,
    SaleNamePipe,
    CommanModalComponent,
    ApproveTimingsComponent,
    ApproveTimingsAdminComponent,
    StartEndTimeEditComponent,
    ActvitiesComponent,
    ShowNotesComponent,
    ShowMapDirectionComponent,
    ApproveService22TimingsComponent,
    ReviewService22TimingsComponent,
    CustomersComponent,
    CustomerFormComponent,
    CustomerFormDecoratorComponent,
    ProvinceCitiesComponent,
    ProvinceCitiesFormComponent,
    CitiesListPopUpComponent,
    GoogleMapCitiesComponent,
  ],
  imports: [
    FullCalendarModule,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    Ng2SearchPipeModule,
    DragDropModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200", 'localhost:3000']
      }
    }),
    MatTabsModule,
    NgxSummernoteModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    ApiService,
    AuthStatusService,
    ApiAuthService,
    DeliveryService,
    ExpertService,
    ExpertService,
    GroupService,
    PartnerService,
    UserService,
    OfferService,

    ObserverService,
    DeliveryObserverService,
    CanActivateLogIn,
    DeliveryCanActivateLogIn,
    UserLoggedIn,
    LogOut,
    GroupRestricted,
    CommonModule,
    ActivityRedirection,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
