import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Delivery, DeliveryStatusAux } from 'src/app/models/delivery.schema';
import { Expert } from 'src/app/models/expert.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ExpertSalaryService } from 'src/app/services/expert-salary.service';
import { ExpertService } from 'src/app/services/expert.service';
import { PaginationComponent } from '../_pagination/pagination.component';
import { Service } from 'src/app/services/service.service';
import { ServiceModel } from 'src/app/models/service.schems';
import { MatDialog } from '@angular/material/dialog';
import { ExpertSalaryDialogComponent } from '../expert-salary-dialog/expert-salary-dialog.component';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-expert-salary',
  templateUrl: './expert-salary.component.html',
  styleUrls: ['./expert-salary.component.scss']
})
export class ExpertSalaryComponent implements OnInit {

  groups: any;
  limit: number;
  page: number;
  count: number = 0;
  maxPage: number;
  order: {};
  selected = "";
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'Delivery.deliveryDate': 'DESC', 'Delivery.fromTime': 'ASC', 'Delivery.toTime': 'ASC' };
  filtered = {};
  today: any;
  experts: Expert[];
  selectedDeliveries: Delivery[] = [];
  deliveries: Delivery[] = [];
  selectedExpert: any;
  selectedExpertId: any = null;
  _deliveryFilterForm;
  deliveryStatusAux = DeliveryStatusAux;
  services: ServiceModel[] = [];
  expertDialogOpen: number;

  settings = {
    active: {
      title: "Stipendi",
    },
    inactive: {
      title: "Storico Stipendi",
    }
  };

  private readonly tableSpacing = {
    admin: {
      id: 5,
      deliveryDate: 10,
      time: 10,
      partner: 15,
      expert: 10,
      address: 30,
      status: 5,
      detail: 5,
      edit: 5,
      assignExpert: 5,
      historyStatus: 5,
    },
    partner: {
      id: 5,
      deliveryDate: 10,
      time: 10,
      address: 20,
      status: 5,
      detail: 10,
      edit: 10,
      historyStatus: 5,
    },
    expert: {
      id: 5,
      deliveryDate: 10,
      time: 15,
      partner: 10,
      address: 20,
      status: 10,
      edit: 20,
      detail: 10,
      historyStatus: 5,
    },
  };
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;

  constructor(
    private authStatusService: AuthStatusService,
    private expertService: ExpertService,
    private route: ActivatedRoute,
    private expertSalaryService: ExpertSalaryService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public service: Service,
    public dialog: MatDialog,
    public spinnerService: SpinnerService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      if(this.groups.includes('expert')){
        let loggedInExpert = JSON.parse(sessionStorage.getItem("currentUser"));
        this.selectedExpert = await this.expertService.getOne(loggedInExpert?.expert?.id);
      }
      this.handleFilterForm();
      var today = new Date();
      var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
      this.today = tmptoday;
      this.expertDialogOpen = 0;
      this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
      await this.filter();
      await this.initialize();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong on this page");
    }
  }

  async initialize(){
    if(this.groups.includes('admin') || this.groups.includes('operation')){
      await this.getAllExperts();
    }
    await this.getAllService();
    // try {
    //   this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
    // }
    // catch { }
  }

  async getAllService(){
    try {
      this.services = await this.service.getAllServices();
    }
    catch { }
  }

  async getAllExperts(){
    try {
      this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
    }
    catch { }
  }

  public groupWhitelisted(groups: string | string[]): boolean {
    if (!Array.isArray(groups))
      return this.groups.includes(groups);
    for (let group of groups)
      if (this.groups.includes(group))
        return true;
    return false;
  }

  handleFilterForm(){
    this._deliveryFilterForm = new FormGroup({
      'Delivery.id': new FormControl(''),
      'Delivery.deliveryDate_from': new FormControl(''),
      'Delivery.deliveryDate_to': new FormControl(''),
      'Delivery.fromTime': new FormControl(''),
      'Delivery.toTime': new FormControl(''),
      'Partner.businessName': new FormControl(''),
      'UserExpert.surname': new FormControl(''),
      'Delivery.status': new FormControl(''),
      'Delivery.address': new FormControl(''),
      'Delivery.partner.address': new FormControl(''),
      'Delivery.pickUpTime': new FormControl(''),
      'Delivery.price': new FormControl(''),
      'Service.pricingModel': new FormControl(''),
      'Service.serviceName': new FormControl(''),
      'Delivery.invoiced': new FormControl(''),
      'Delivery.serviceType': new FormControl(''),
      'Delivery.billable': new FormControl(''),
      'Delivery.payable': new FormControl(''),
      'Delivery.expertSalary': new FormControl(''),
      'Delivery.valetAdditionalPrice': new FormControl(''),
      'Delivery.additionalPrice': new FormControl(''),
      'Delivery.orderId': new FormControl(''),
    });
  }

  async fnReset() {
    this._deliveryFilterForm = new FormGroup({
      'Delivery.id': new FormControl(''),
      'Delivery.deliveryDate_from': new FormControl(),
      'Delivery.deliveryDate_to': new FormControl(),
      'Delivery.fromTime': new FormControl(''),
      'Delivery.toTime': new FormControl(''),
      'Partner.businessName': new FormControl(''),
      'UserExpert.surname': new FormControl(''),
      'Delivery.status': new FormControl(''),
      'Delivery.address': new FormControl(''),
      'Delivery.receiverPhone': new FormControl(''),
      'Delivery.deliveryDate': new FormControl(''),
      'Delivery.time': new FormControl(''),
      'Delivery.pickUpTime': new FormControl(''),
      'Delivery.partner.address': new FormControl(''),
      'Delivery.price': new FormControl(''),
      'Service.pricingModel': new FormControl(''),
      'Service.serviceName': new FormControl(''),
      'Delivery.invoiced': new FormControl(''),
      'Delivery.serviceType': new FormControl(''),
      'Delivery.billable': new FormControl(''),
      'Delivery.payable': new FormControl(''),
      'Delivery.expertSalary': new FormControl(''),
      'Delivery.valetAdditionalPrice': new FormControl(''),
      'Delivery.additionalPrice': new FormControl(''),
      'Delivery.orderId': new FormControl(''),
    });
    this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
    this.expertDialogOpen = 0;
    this.deliveries = [];
    this.count = 0;
    this.selectedExpert = null;
    this.selectedExpertId = null;
  }

  async filter() {

    let filterForm = (new FormGroupToObject()).transform(this._deliveryFilterForm);
    this.filtered = {};

    if (filterForm['Delivery.id'])
      this.filtered['Delivery.id'] = filterForm['Delivery.id'];
    if (filterForm['Delivery.deliveryDate_from'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.deliveryDate_from']] };
    if (filterForm['Delivery.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'lessThan', values: [filterForm['Delivery.deliveryDate_to']] };
    if (filterForm['Delivery.deliveryDate_from'] && filterForm['Delivery.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'between', values: [filterForm['Delivery.deliveryDate_from'], filterForm['Delivery.deliveryDate_to']] };
    if (filterForm['Delivery.fromTime'])
      this.filtered['Delivery.fromTime'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.fromTime']] };
    if (filterForm['Delivery.toTime'])
      this.filtered['Delivery.toTime'] = { operator: 'lessThan', values: [filterForm['Delivery.toTime']] };
    if (filterForm['Delivery.pickUpTime'])
      this.filtered['Delivery.pickUpTime'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.pickUpTime']] };
      if (filterForm['Delivery.status'])
      this.filtered['Delivery.status'] = filterForm['Delivery.status'];
    if (filterForm['Delivery.address'])
       this.filtered['Delivery.address'] = { operator: 'like', values: [`%${filterForm['Delivery.address']}%`]};
      this.filtered['Delivery.receiverPhone'] = filterForm['Delivery.receiverPhone'];
    if (filterForm['Delivery.deliveryDate'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'like', values: [`%${filterForm['Delivery.deliveryDate']}%`] };
    if (filterForm['Delivery.time'])
      this.filtered['Delivery.time'] = { operator: 'like', values: [`%${filterForm['Delivery.time']}%`] };
    if (filterForm['Partner.businessName'])
      this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
    if (filterForm['UserExpert.surname'])
      this.filtered['UserExpert.surname'] = { operator: 'like', values: [`%${filterForm['UserExpert.surname']}%`], concat: [{ string: ' ' }, { column: 'UserExpert.name' }] };
    if (filterForm['Delivery.address'])
      this.filtered['Delivery.address'] = { operator: 'like', values: [`%${filterForm['Delivery.address']}%`]};
    if (filterForm['Service.serviceName'])
      this.filtered['Service.serviceName'] = { operator: 'like', values: [`%${filterForm['Service.serviceName']}%`]};
    if (filterForm['Service.pricingModel'])
      this.filtered['Service.pricingModel'] = { operator: 'like', values: [`%${filterForm['Service.pricingModel']}%`]};
    if (filterForm['Delivery.partner.address'])
      this.filtered['Delivery.partner.address'] = { operator: 'like', values: [`%${filterForm['Delivery.partner.address']}%`], concat: [{ string: ' ' }, { column: 'Delivery.partner.address' }] };
    if (filterForm['Delivery.price'])
      this.filtered['Delivery.price'] = { operator: 'like', values: [`%${filterForm['Delivery.price']}%`]};
    if (filterForm['Delivery.serviceType'])
      this.filtered['Delivery.serviceType'] = { operator: 'like', values: [`%${filterForm['Delivery.serviceType']}%`]};
    if (filterForm['Delivery.billable'])
      this.filtered['Delivery.billable'] = { operator: 'like', values: [`%${filterForm['Delivery.billable']}%`]};
    if (filterForm['Delivery.payable'])
      this.filtered['Delivery.payable'] = { operator: 'like', values: [`%${filterForm['Delivery.payable']}%`]};
    if (filterForm['Delivery.expertSalary'])
      this.filtered['Delivery.expertSalary'] = { operator: 'like', values: [`%${filterForm['Delivery.expertSalary']}%`]};
    if (filterForm['Delivery.valetAdditionalPrice'])
      this.filtered['Delivery.valetAdditionalPrice'] = { operator: 'like', values: [`%${filterForm['Delivery.valetAdditionalPrice']}%`]};
    if (filterForm['Delivery.additionalPrice'])
      this.filtered['Delivery.additionalPrice'] = { operator: 'like', values: [`%${filterForm['Delivery.additionalPrice']}%`]};
    if (filterForm['Delivery.orderId'])
      this.filtered['Delivery.orderId'] = { operator: 'like', values: [`%${filterForm['Delivery.orderId']}%`]};
    this.loadDeliveries();
  }

  async loadDeliveries(limit?: number, page?: number, order?: {}) {
    let filterForm = (new FormGroupToObject()).transform(this._deliveryFilterForm);
    if(filterForm['Delivery.deliveryDate_to'] && filterForm['Delivery.deliveryDate_from']){
      if((this.today != filterForm['Delivery.deliveryDate_from'])){
        this.defaultOrder = { 'Delivery.deliveryDate': 'ASC', 'Delivery.fromTime': 'ASC', 'Delivery.toTime': 'ASC' };
      }
    }

    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;

    let deliveries: any;
    if(this.selected == 'active'){
      this.filtered['Delivery.sendToExpert'] = { operator: 'equal', values: [0]};
      deliveries = await this.expertSalaryService.getAllDeliveries(this.selectedExpert?.id, this.page, this.limit, this.order, this.filtered);
    } else {
      this.filtered['Delivery.sendToExpert'] = { operator: 'equal', values: [1]};
      deliveries = await this.expertSalaryService.getAllDeliveries(this.selectedExpert?.id, this.page, this.limit, this.order, this.filtered);
    }
    this.deliveries = deliveries.deliveries;
    this.count = deliveries.count;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  public getSpacing(property) {
    if (this.groups.includes('expert'))
      return this.tableSpacing['expert'][property] || 0;
    if (this.groups.includes('partner'))
      return this.tableSpacing['partner'][property] || 0;
    if (this.groups.includes('admin'))
      return this.tableSpacing['admin'][property] || 0;
  }

  navigate(event) {
    this.loadDeliveries(event.limit, event.page, this.order);
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    if(this.order){
      for (let attr of attribute) {
        switch (this.order[attr]) {
          case 'ASC':
            return 'fa-sort-up';
          case 'DESC':
            return 'fa-sort-down';
          default:
            return 'fa-sort';
        }
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    if(this.order){
      delete this.order['Delivery.updatedAt'];
    }
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    if(this.order){
      for (let attr of attribute) {
        switch (this.order[attr]) {
          case 'ASC':
            this.order[attr] = 'DESC';
            break;
          case 'DESC':
            this.order[attr] = undefined;
            break;
          default:
            this.order = {}
            this.order[attr] = 'ASC';
            break;
        }
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  snack(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadDeliveries();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }


  formatTimeShow(fromTime, toTime){
    let trimmedFromTime = fromTime != null ? fromTime?.slice(0, -3) : '';
    let trimmedToTime = toTime != null ? toTime?.slice(0, -3) : '';
    let time = trimmedFromTime + '-' + trimmedToTime;
    return time;
  }

  isSelected(delivery: any): boolean {
    return this.selectedDeliveries.includes(delivery);
  }

  toggleSelection(delivery: any) {
    if (this.isSelected(delivery)) {
        this.selectedDeliveries = this.selectedDeliveries.filter(item => item !== delivery);
    } else {
        this.selectedDeliveries.push(delivery);
    }
  }

  selectAllDeliveries() {
    const allDeliveriesSelected = this.selectedDeliveries.length === this.deliveries.length;
    this.selectedDeliveries = allDeliveriesSelected ? [] : [...this.deliveries];
  }

  showServiceDetails(serviceId: number){
    if(serviceId){
      const service = this.services.find((service) => service.id == serviceId);
      return service;
    }
  }

  async handleExpertChange(expertId: any){
    try {
      this.spinnerService.show();
      this.selectedDeliveries = [];
      this.selectedExpert = this.experts.find((expert) => {
        return expert?.id == expertId;
      });
      this.selectedExpertId = this.selectedExpert?.id;
      await this.loadDeliveries();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong fetching details of selected valet");
    }
  }

  expertSalaryModel(){
    if(this.selectedExpert === null || this.selectedExpert == undefined ){
      alert('Please select valet first');
      return false;
    }

    let ref = this.dialog.open(ExpertSalaryDialogComponent, {maxWidth: '95%', data: {
      deliveries: this.selectedDeliveries,
      expert: this.selectedExpert,
      historyPage: this.selected
    } });
    ref.afterClosed().subscribe(async (result) => {
      if(result?.success){
        this.selectedDeliveries = [];
        this.expertDialogOpen = 0;
        this.selectedExpert = null;
        this.selectedExpertId = null;
        await this.loadDeliveries();
      }
    });
  }

  showSnackBar(message: string){
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}
