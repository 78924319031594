import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Expert, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ExpertService } from 'src/app/services/expert.service';
import { PartnerService } from 'src/app/services/partner.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TeamLeaderService } from 'src/app/services/team-leader.service';

@Component({
  selector: 'app-partner-availability-list',
  templateUrl: './partner-availability-list.component.html',
  styleUrls: ['./partner-availability-list.component.scss']
})
export class PartnerAvailabilityListComponent implements OnInit {

  availabilityData: any[] = [];
  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'PartnerTimeAvailability.date': 'DESC', 'PartnerTimeAvailability.startTime': 'ASC', 'PartnerTimeAvailability.endTime': 'ASC' };
  today: any;
  partnerAvailablitiesForm: any;
  groups: any;
  teamLeader: TeamLeader;
  loggedInUserInfo: any;
  loggedInExpert: Expert;

  constructor(
    private partnerService: PartnerService,
    private router: Router,
    private route: ActivatedRoute,
    private authStatusService: AuthStatusService,
    private teamLeaderService: TeamLeaderService,
    private expertService: ExpertService,
    private spinnerService: SpinnerService,
    private _snackBar: MatSnackBar,
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }

  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.manageFilterForm();
      await this.filter();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong on this page");
    }
  }

  manageFilterForm(){
    var today = new Date();
    var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2); //DATA CORRENTE
    this.today = tmptoday;
    this.partnerAvailablitiesForm = new FormGroup({
      'PartnerTimeAvailability.id': new FormControl(''),
      'PartnerTimeAvailability.date_from': new FormControl(tmptoday), 
      'PartnerTimeAvailability.date_to': new FormControl(tmptoday),   
      'PartnerTimeAvailability.startTime': new FormControl(''),
      'PartnerTimeAvailability.endTime': new FormControl(''),
      'PartnerTimeAvailability.available': new FormControl(''),
      'Partner.businessName': new FormControl(''),
    });
  }

  async initialize() {
    await this.getPartnerAvailabilities();
  }

  async getPartnerAvailabilities(limit?: number, page?: number, order?: {}){
    let filterForm = (new FormGroupToObject()).transform(this.partnerAvailablitiesForm);
    if(filterForm['PartnerTimeAvailability.date_to'] && filterForm['PartnerTimeAvailability.date_from']){
      if((this.today != filterForm['PartnerTimeAvailability.date_from'])){
        this.defaultOrder = { 'PartnerTimeAvailability.date': 'ASC', 'PartnerTimeAvailability.startTime': 'ASC', 'PartnerTimeAvailability.endTime': 'ASC' };
      }
    }
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
    if(this.groups.includes('expert') && this.loggedInUserInfo){
      this.loggedInExpert = await this.expertService.getOne(this.loggedInUserInfo['extraId']);
    }
    let availabilities = await this.partnerService.getPartnerAvailabilities(this.page, this.limit, this.order, this.filtered);
    this.availabilityData = availabilities;
    if(this.groups.includes('teamleader') && this.loggedInUserInfo){
      try {
        this.teamLeader = await this.teamLeaderService.getOne(this.loggedInUserInfo['extraId']);
        if (this.teamLeader?.teamLeaderProvinces.length > 0) {
          this.availabilityData = this.availabilityData?.filter((availability) => {
            return availability?.partner?.partnerProvinces.some((pProvince) => {
              if(pProvince.province != null){
                return this.teamLeader.teamLeaderProvinces.some((teamLeaderProvince: TeamLeaderProvinces) => {
                  return pProvince.province.provinceCode.toLowerCase() === teamLeaderProvince.province.provinceCode.toLowerCase()
                });
              }
            });
          });
        }
      } catch (error) {
        console.log("error", error)
      }
    }
    if(this.groups.includes('expert') && this.loggedInExpert){
      if (this.loggedInExpert.expertTeamLeaderProvinces.length > 0) {
        this.availabilityData = this.availabilityData?.filter((availability) => {
          return availability?.partner?.partnerProvinces.some((pProvince) => {
            if(pProvince.province != null){
              return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
                return pProvince.province.provinceCode.toLowerCase() === expertTeamLeaderProvince.province.provinceCode.toLowerCase()
              });
            }
          });
        });
      }
    }
    this.count = this.availabilityData.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.partnerAvailablitiesForm);
    this.filtered = {};
    if (filterForm['PartnerTimeAvailability.id'])
      this.filtered['PartnerTimeAvailability.id'] = filterForm['PartnerTimeAvailability.id'];  
    if (filterForm['PartnerTimeAvailability.date_from'])
      this.filtered['PartnerTimeAvailability.date'] = { operator: 'moreThanOrEqual', values: [filterForm['PartnerTimeAvailability.date_from']] };
    if (filterForm['PartnerTimeAvailability.date_to'])
      this.filtered['PartnerTimeAvailability.date'] = { operator: 'lessThan', values: [filterForm['PartnerTimeAvailability.date_to']] };
    if (filterForm['PartnerTimeAvailability.date_from'] && filterForm['PartnerTimeAvailability.date_to'])
      this.filtered['PartnerTimeAvailability.date'] = { operator: 'between', values: [filterForm['PartnerTimeAvailability.date_from'], filterForm['PartnerTimeAvailability.date_to']] };
    if (filterForm['Partner.businessName'])
      this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
    if (filterForm['PartnerTimeAvailability.startTime'])
      this.filtered['PartnerTimeAvailability.startTime'] = { operator: 'moreThanOrEqual', values: [filterForm['PartnerTimeAvailability.startTime']] };
    if (filterForm['PartnerTimeAvailability.endTime'])
      this.filtered['PartnerTimeAvailability.endTime'] = { operator: 'lessThan', values: [filterForm['PartnerTimeAvailability.endTime']] };
    if (filterForm['PartnerTimeAvailability.available'])
      this.filtered['PartnerTimeAvailability.available'] = { operator: 'like', values: [`%${filterForm['PartnerTimeAvailability.available']}%`]};
    
    await this.getPartnerAvailabilities();
  }

  fnReset(){
    this.managePartnerAvailablitiesForm();
  }

  managePartnerAvailablitiesForm(){
    this.partnerAvailablitiesForm = new FormGroup({
      'PartnerTimeAvailability.id': new FormControl(''),
      'PartnerTimeAvailability.date_from': new FormControl(''), 
      'PartnerTimeAvailability.date_to': new FormControl(''),   
      'PartnerTimeAvailability.startTime': new FormControl(''),
      'PartnerTimeAvailability.endTime': new FormControl(''),
      'PartnerTimeAvailability.available': new FormControl(''),
      'Partner.businessName': new FormControl(''),
    });
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['PartnerTimeAvailability.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.getPartnerAvailabilities();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  navigate(event) {
    this.getPartnerAvailabilities(event.limit, event.page, this.order);
  }

  formatTimeShow(fromTime, toTime){
    let trimmedFromTime = fromTime != null ? fromTime?.slice(0, -3) : '';
    let trimmedToTime = toTime != null ? toTime?.slice(0, -3) : '';
    let time = trimmedFromTime + '-' + trimmedToTime;
    return time;
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }
}
