import { ActivatedRoute, Router } from '@angular/router';
import { ProvincesService } from './../../services/provinces.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Cities, ProvinceCities } from 'src/app/models/province-cities.schema';
import { ProvinceCitiesService } from 'src/app/services/province-cities.service';

@Component({
  selector: 'app-province-cities-form',
  templateUrl: './province-cities-form.component.html',
  styleUrls: ['./province-cities-form.component.scss']
})
export class ProvinceCitiesFormComponent implements OnInit {

  provinces: any[] = [];
  provinceCitiesForm: any;
  formValidationMsgs: any;
  groups: string[];
  provinceId: string|number;
  provinceData: any;
  isDisabled = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private provincesService: ProvincesService,
    private provinceCitiesService: ProvinceCitiesService,
    private authStatusService: AuthStatusService,
  ) { 
    try {
      this.handleProvinceCitiesForm();
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  handleProvinceCitiesForm(){
    this.provinceCitiesForm = new FormGroup(ProvinceCities.validation());
    this.formValidationMsgs = ProvinceCities.validationMessages;
  }

  async ngOnInit() {
    try {
      this.provinceId = this.route.snapshot.paramMap.get('id');
      if (this.provinceId) {
        try {
          this.provinceData = await this.provincesService.getProvinceCitiesById(Number(this.provinceId));
        }
        catch {
          this.router.navigateByUrl("/404", { skipLocationChange: true });
        }
      }

      if (this.provinceData){
        this.patch();
      }
      await this.getAllProvinces();
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  patch(){
    if (this.provinceData.province){
      this.provinceCitiesForm.controls.province.setValue(this.provinceData.id);
    }
    if(this.provinceData.provinceCities.length > 0) {
      this.patchCities(this.provinceData.provinceCities);
    }
  }

  get provinceCitiesField(): FormArray {
    return this.provinceCitiesForm.get('cities') as FormArray;
  }

  patchCities(cities: any[]) {
    const formArray = this.provinceCitiesField;
    formArray.clear();
    cities.forEach(field => {
      formArray.push(new FormGroup({
        id: new FormControl(field.id),
        cityName: new FormControl(field.cityName)
      }));
    });
  }

  async getAllProvinces(){
    try {
      this.provinces = await this.provincesService.getAllProvinces();
    } catch (error) {
      console.log("ERROR_IN_getAllProvinces");
    }
  }

  addProvinceCity() {
    const control = this.provinceCitiesForm.get('cities') as FormArray;
    control.push(new FormGroup(Cities.validation()));
  }

  removeProvinceCity(index: number) {
    const control = this.provinceCitiesForm.get('cities') as FormArray;
    control.removeAt(index);
    if (control.length > 1) {
    }
  }

  async onSubmit(){
    try {
      if(this.provinceCitiesForm.valid){
        let createData = await this.provinceCitiesService.createOrUpdateProvinceCityRecord(this.provinceCitiesForm.value);
        if(createData){
          this.provinceData = createData;
          this.patch();
        }
        this.showSnackBar("Province Cities updated successfully");
      }
    } catch (error) {
      this.showSnackBar("Something Went Wrong");
      console.log("ERROR_IN_onSubmit");
    }
  }

  async getCityName(data: any, index: number) {
  
    const citiesArray = this.provinceCitiesForm.get('cities') as FormArray;
    const cityGroup = citiesArray.at(index) as FormGroup;
  
    if (data && data.name) {
      cityGroup.get('cityName')?.setValue(data.name);
    } else {
      cityGroup.get('cityName')?.setErrors({ invalidAddress: true });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
