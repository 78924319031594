/*
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Delivery } from 'src/app/models/delivery.schema';
import { Partner } from 'src/app/models/partner.schema';
import { UserService } from 'src/app/services/user.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ServiceModel } from 'src/app/models/service.schems';
import { Service } from 'src/app/services/service.service';
import { PartnerInvoiceService } from 'src/app/services/partner-invoice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-partner-invoice-form',
  templateUrl: './partner-invoice-form.component.html',
  styleUrls: ['./partner-invoice-form.component.scss']
})
export class PartnerInvoiceFormComponent implements OnInit {
  
  @ViewChild('htmlPdf') htmlPdfRef!: ElementRef;
  public deliveries: Delivery[] = [];
  public partner: Partner;
  public user: any;
  public TotalAmount: any = 0;
  public totalToPay: any = 0;
  public totalToReceive: any = 0;
  currentDate: Date = new Date();
  public services: ServiceModel[] = [];
  public totalWorkingHours: any = 0;
  public totalPrice: any = 0;
  public totalPlusPrice: any = 0;
  public totalProductValue: any = 0;
  public totalValue: any = 0;
  public totalValuePlusVat: any = 0;
  public totalToRecieve: any = 0;
  public totalToRecieveAmount: any = 0;
  public totalToPayAmount: any = 0;
  public lastTotal: any = 0;
  public disableBtn: boolean = false;
  public historyPage: string = "";
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveries: Delivery[],
      partner: Partner,
      historyPage: any
    },
    private partnerInvoiceService: PartnerInvoiceService,
    private userService: UserService,
    private dialogRef: MatDialogRef<PartnerInvoiceFormComponent>,
    public service: Service,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private deliveryService: DeliveryService,
  ) {
    this.historyPage = data.historyPage;
    this.deliveries = data.deliveries;
    this.partner = data.partner;
    this.calculateTotalPayAndReceive();
    this.calculateTotals();
  }

  async ngOnInit() {
    this.user = await this.userService.getMyInformation();
    await this.getAllService();
  }

  async getAllService(){
    try {
      this.services = await this.service.getAllServices();
    }
    catch { }
  }

  showServiceDetails(serviceId: number){
    if(serviceId){
      const service = this.partner?.partnerServices.find((item) => {
        return item?.service?.id == serviceId;
      });
      return service;
    }
  }

  calculateTotalPayAndReceive(){
    let totalToRecieveAmount: number = 0;
    this.deliveries.forEach((delivery: any) => {
      if(delivery?.billable){
        let serviceDetails = this.showServiceDetails(Number(delivery?.service));
        if(serviceDetails?.service?.pricingModel.toLowerCase() === 'sales'){
          let amount = delivery?.productValue;
          totalToRecieveAmount = Number(delivery?.price) + (Number(delivery?.price) * 22)/100;
          this.totalToRecieveAmount += Number(amount) - Number(totalToRecieveAmount);
        }
        if(serviceDetails?.service?.pricingModel.toLowerCase() === 'hourlyrate' || serviceDetails?.service?.pricingModel.toLowerCase() === 'fixedprice'){
          let value: any = (delivery?.price) + (delivery?.additionalPrice);
          let valuePlusVat: any = value + (value * 22)/100;
          this.totalToPayAmount += valuePlusVat;
        }
        if(serviceDetails?.service?.pricingModel.toLowerCase() === 'corporate'){
          let value: any = (delivery?.price) + (delivery?.additionalPrice);
          this.totalToPayAmount += value + (parseFloat(delivery?.deliveryProducts[0].price));
        }
      }
    });
  }

  showValuePrice(delivery: any){
    return delivery?.price + (delivery?.additionalPrice ? delivery?.additionalPrice : 0);
  }

  showValuePlusVat(delivery: any){
    if(delivery.serviceType?.toLowerCase() === 'corporate'){
      let value = delivery?.price + (delivery?.additionalPrice ? delivery?.additionalPrice : 0);
      return value + parseFloat(delivery?.deliveryProducts[0].price);
      let valuePlusVat = value + (value * 22)/100;
    } else {
      let value = delivery?.price + (delivery?.additionalPrice ? delivery?.additionalPrice : 0);
      let valuePlusVat = value + (value * 22)/100;
      return valuePlusVat;
    }
  }

  showTotalForSales(delivery: any){
    if(delivery.additionalPrice < 0) {
      return (delivery?.price + (delivery?.price * 22)/100) - delivery?.productValue;
    } else {
      let value = delivery?.price + (delivery?.additionalPrice ? delivery?.additionalPrice : 0);
      let valuePlusVat = value + (value * 22)/100;
      return valuePlusVat;
    }
  }

  calculateTotals(){
    if(this.deliveries.length){
      this.deliveries.forEach((delivery: any) => {
        this.totalWorkingHours += parseFloat(delivery?.hours ? delivery?.hours : 0);
        this.totalPrice += parseFloat(delivery?.price ? delivery?.price : 0);
        this.totalPlusPrice += parseFloat(delivery?.additionalPrice ? delivery?.additionalPrice : 0);
        this.totalProductValue += delivery?.serviceType?.toLowerCase() === 'corporate' ? delivery?.deliveryProducts[0].price ? parseFloat(delivery?.deliveryProducts[0].price) : 0 : parseFloat(delivery?.productValue ? delivery?.productValue : 0);
        this.totalValue += (delivery?.price) + (delivery?.additionalPrice);
        let value = (delivery?.price) + (delivery?.additionalPrice);
        let valuePlusVat = value + (value * 22)/100;
        this.totalValuePlusVat += (this.showServiceDetails(delivery?.service)?.service?.pricingModel?.toLowerCase() === "corporate" ? (value += parseFloat(delivery?.deliveryProducts[0].price) ) : valuePlusVat);
        if(this.showServiceDetails(delivery?.service)?.service?.pricingModel?.toLowerCase() === "fixedprice" || this.showServiceDetails(delivery?.service)?.service?.pricingModel?.toLowerCase() === "hourlyrate"){
          let value = (delivery?.price) + (delivery?.additionalPrice);
          let valuePlusVat = value + (value * 22)/100;
          this.lastTotal += valuePlusVat;
        } 
        if(this.showServiceDetails(delivery?.service)?.service?.pricingModel?.toLowerCase() === "corporate"){
          let value = (delivery?.price) + (delivery?.additionalPrice);
          this.lastTotal += (value + parseFloat(delivery?.deliveryProducts[0].price));
        } 
        if(this.showServiceDetails(delivery?.service)?.service?.pricingModel?.toLowerCase() === "sales"){
          if(delivery.additionalPrice >= 0) {
            let value = delivery?.price + (delivery?.additionalPrice ? delivery?.additionalPrice : 0);
            let valuePlusVat = value + (value * 22)/100;
            this.lastTotal += valuePlusVat;
          }
          if(delivery?.additionalPrice < 0){
            this.lastTotal += (delivery?.price + (delivery?.price * 22)/100) - delivery?.productValue;
          }
        }
      });
    }
  }

  downloadInvoice() {
    this.generatePdf('download');
  }

  viewInvoice() {
    this.generatePdf('view');
  }

  sendInvoice() {
    this.generatePdf('send');
  }
  
  generatePdf(action: string) {
    const DATA: any = this.htmlPdfRef.nativeElement;
    html2canvas(DATA).then((canvas) => {
      const pdfHeight = 297;
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const totalPages = Math.ceil(fileHeight / pdfHeight);
      let PDF = new jsPDF('p', 'mm', 'a4');
      for (let i = 0; i < totalPages; i++) {
        if (i > 0) {
          PDF.addPage();
        }
        let position = -i * pdfHeight;
        PDF.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          0,
          position,
          fileWidth,
          fileHeight
        );
      }

      switch(action) {
        case 'download':
          PDF.save('partner-invoice.pdf');
          break;
        case 'view':
          const pdfUrl = URL.createObjectURL(PDF.output('blob'));
          const newWindow = window.open('', '_blank');
          if (newWindow) {
            newWindow.location.href = pdfUrl;
          }
          break;
        case 'send':
          this.disableBtn = true;
          const pdfBlob = PDF.output('blob');
          const formData = new FormData();
          formData.append('invoice', pdfBlob, 'partner-invoice.pdf');
          formData.append('deliveries', JSON.stringify(this.deliveries));
          let promise = this.partnerInvoiceService.sendInvoiceToPartner(formData, this.partner?.id);
          promise.then((res: any)=> {
            if(res){
              this.disableBtn = false;
              this.openSnackBar('Fattura inviata con successo');
              this.dialogRef.close({ success: true });
              this.deliveries = [];
              this.TotalAmount = 0;
            }
          }).catch((err: any)=> {
            this.disableBtn = false;
            this.openSnackBar(`${err.error.message}`);
          })
          break;
        default:
          break;
      }
    });
  }


  openSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

  async exportInvoiceInfo(){
    let res = await this.deliveryService.exportInvoiceInfo(this.deliveries);
    let anchor = document.createElement('a');
    anchor.download = 'pro_forma_partner.xlsx';
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

}
*/


import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Delivery } from 'src/app/models/delivery.schema';
import { Partner } from 'src/app/models/partner.schema';
import { UserService } from 'src/app/services/user.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ServiceModel } from 'src/app/models/service.schems';
import { Service } from 'src/app/services/service.service';
import { PartnerInvoiceService } from 'src/app/services/partner-invoice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-partner-invoice-form',
  templateUrl: './partner-invoice-form.component.html',
  styleUrls: ['./partner-invoice-form.component.scss']
})
export class PartnerInvoiceFormComponent implements OnInit {
  
  @ViewChild('htmlPdf') htmlPdfRef!: ElementRef;
  public deliveries: Delivery[] = [];
  public partner: Partner;
  public user: any;
  public TotalAmount: any = 0;
  public totalToPay: any = 0;
  public totalToReceive: any = 0;
  currentDate: Date = new Date();
  public services: ServiceModel[] = [];
  public totalWorkingHours: any = 0;
  public totalPrice: any = 0;
  public totalPlusPrice: any = 0;
  public totalProductValue: any = 0;
  public totalValue: any = 0;
  public totalValuePlusVat: any = 0;
  public totalToRecieve: any = 0;
  public totalToRecieveAmount: any = 0;
  public totalToPayAmount: any = 0;
  public lastTotal: any = 0;
  public disableBtn: boolean = false;
  public historyPage: string = "";
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveries: Delivery[],
      partner: Partner,
      historyPage: any
    },
    private partnerInvoiceService: PartnerInvoiceService,
    private userService: UserService,
    private dialogRef: MatDialogRef<PartnerInvoiceFormComponent>,
    public service: Service,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private deliveryService: DeliveryService,
  ) {
    this.historyPage = data.historyPage;
    this.deliveries = data.deliveries;
    this.partner = data.partner;
    this.calculateTotalPayAndReceive();
    this.calculateTotals();
  }

  async ngOnInit() {
    this.user = await this.userService.getMyInformation();
    await this.getAllService();
  }

  async getAllService(){
    try {
      this.services = await this.service.getAllServices();
    }
    catch { }
  }

  showServiceDetails(serviceId: number){
    if(serviceId){
      const service = this.partner?.partnerServices.find((item) => {
        return item?.service?.id == serviceId;
      });
      return service;
    }
  }

  calculateTotalPayAndReceive(){
    this.deliveries.forEach((delivery: any) => {
      if(delivery?.billable){
        let serviceDetails = this.showServiceDetails(Number(delivery?.service));
        if(serviceDetails?.service?.pricingModel.toLowerCase() === 'sales'){
          this.totalToRecieveAmount += this.showTotalForSales(delivery);
        }
        if(serviceDetails?.service?.pricingModel.toLowerCase() === 'hourlyrate' || serviceDetails?.service?.pricingModel.toLowerCase() === 'fixedprice'){
          let value: any = (delivery?.price) + (delivery?.additionalPrice);
          let valuePlusVat: any = value + (value * 22)/100;
          this.totalToPayAmount += valuePlusVat;
        }
        if(serviceDetails?.service?.pricingModel.toLowerCase() === 'corporate'){
          let value: any = (delivery?.price) + (delivery?.additionalPrice);
          this.totalToPayAmount += value + (parseFloat(delivery?.deliveryProducts[0].price));
        }
      }
    });
  }

  showValuePrice(delivery: any){
    return delivery?.price + (delivery?.additionalPrice ? delivery?.additionalPrice : 0);
  }

  showValuePlusVat(delivery: any){
    if(delivery?.serviceType?.toLowerCase() === 'sales') {
      return (Number(delivery?.price) || 0) + (Number(delivery?.price) * 22) / 100;
    } else if(delivery.serviceType?.toLowerCase() === 'corporate'){
      let value = delivery?.price + (delivery?.additionalPrice ? delivery?.additionalPrice : 0);
      return value + parseFloat(delivery?.deliveryProducts[0].price);
    } else {
      let value = delivery?.price + (delivery?.additionalPrice ? delivery?.additionalPrice : 0);
      let valuePlusVat = value + (value * 22)/100;
      return valuePlusVat;
    }
  }

  showTotalForSales(delivery: any){
    let pricePlusVat = (Number(delivery?.price) || 0) + (Number(delivery?.price) * 22) / 100;
    let valueMinusPriceVat = Number(delivery?.productValue) - Number(pricePlusVat);
    return valueMinusPriceVat + (Number(delivery?.additionalPrice));
  }

  calculateTotals(){
    if(this.deliveries.length){
      this.deliveries.forEach((delivery: any) => {
        let serviceDetails = this.showServiceDetails(Number(delivery?.service));
        this.totalWorkingHours += parseFloat(delivery?.hours ? delivery?.hours : 0);
        this.totalPrice += parseFloat(delivery?.price ? delivery?.price : 0);
        this.totalPlusPrice += parseFloat(delivery?.additionalPrice ? delivery?.additionalPrice : 0);
        this.totalProductValue += delivery?.serviceType?.toLowerCase() === 'corporate' ? delivery?.deliveryProducts[0].price ? parseFloat(delivery?.deliveryProducts[0].price) : 0 : parseFloat(delivery?.productValue ? delivery?.productValue : 0);
        this.totalValue += (delivery?.price);
        let value = (delivery?.price);
        let valuePlusVat = value + (value * 22)/100;
        this.totalValuePlusVat += (serviceDetails?.service?.pricingModel.toLowerCase() === "corporate" ? (value += parseFloat(delivery?.deliveryProducts[0].price) ) : valuePlusVat);
        if(serviceDetails?.service?.pricingModel.toLowerCase() === "fixedprice" || serviceDetails?.service?.pricingModel.toLowerCase() === "hourlyrate"){
          let value = (delivery?.price) + (delivery?.additionalPrice);
          let valuePlusVat = value + (value * 22)/100;
          this.lastTotal += valuePlusVat;
        } 
        if(serviceDetails?.service?.pricingModel.toLowerCase() === "corporate"){
          let value = (delivery?.price) + (delivery?.additionalPrice);
          this.lastTotal += (value + parseFloat(delivery?.deliveryProducts[0].price));
        } 
        if(serviceDetails?.service?.pricingModel.toLowerCase() === "sales"){
          this.lastTotal += this.showTotalForSales(delivery);
        }
      });
    }
  }

  downloadInvoice() {
    this.generatePdf('download');
  }

  viewInvoice() {
    this.generatePdf('view');
  }

  sendInvoice() {
    this.generatePdf('send');
  }
  
  generatePdf(action: string) {
    const DATA: any = this.htmlPdfRef.nativeElement;
    html2canvas(DATA).then((canvas) => {
      const pdfHeight = 297;
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const totalPages = Math.ceil(fileHeight / pdfHeight);
      let PDF = new jsPDF('p', 'mm', 'a4');
      for (let i = 0; i < totalPages; i++) {
        if (i > 0) {
          PDF.addPage();
        }
        let position = -i * pdfHeight;
        PDF.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          0,
          position,
          fileWidth,
          fileHeight
        );
      }

      switch(action) {
        case 'download':
          PDF.save('partner-invoice.pdf');
          break;
        case 'view':
          const pdfUrl = URL.createObjectURL(PDF.output('blob'));
          const newWindow = window.open('', '_blank');
          if (newWindow) {
            newWindow.location.href = pdfUrl;
          }
          break;
        case 'send':
          this.disableBtn = true;
          const pdfBlob = PDF.output('blob');
          const formData = new FormData();
          formData.append('invoice', pdfBlob, 'partner-invoice.pdf');
          formData.append('deliveries', JSON.stringify(this.deliveries));
          let promise = this.partnerInvoiceService.sendInvoiceToPartner(formData, this.partner?.id);
          promise.then((res: any)=> {
            if(res){
              this.disableBtn = false;
              this.openSnackBar('Fattura inviata con successo');
              this.dialogRef.close({ success: true });
              this.deliveries = [];
              this.TotalAmount = 0;
            }
          }).catch((err: any)=> {
            this.disableBtn = false;
            this.openSnackBar(`${err.error.message}`);
          })
          break;
        default:
          break;
      }
    });
  }


  openSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

  async exportInvoiceInfo(){
    let res = await this.deliveryService.exportInvoiceInfo(this.deliveries);
    let anchor = document.createElement('a');
    anchor.download = 'pro_forma_partner.xlsx';
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

}

