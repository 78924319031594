import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

declare var google: any;

@Component({
  selector: 'app-google-map-cities',
  templateUrl: './google-map-cities.component.html',
  styleUrls: ['./google-map-cities.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoogleMapCitiesComponent),
      multi: true
    }
  ]
})
export class GoogleMapCitiesComponent implements ControlValueAccessor {
  @ViewChild('cityInput') cityInput!: ElementRef;
  @Output() citySelected = new EventEmitter<string>();

  cityName: string = '';

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  @Input() addressType: string = 'geocode';
  @Input() initialAddress: string = '';
  @Input() control: FormControl;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addressText') addressText: any;
  
  private autocomplete: google.maps.places.Autocomplete;

  constructor() {}

  ngAfterViewInit() {
    this.initAutocomplete();
  }

  private initAutocomplete() {
    if (!this.addressText) return;

    this.autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
      types: ['(cities)'],
      componentRestrictions: { country: ['it', 'sm', 'va', 'mc', 'ch', 'at', 'si'] }
    });

    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();

      if (place && place.address_components) {
        const city = this.extractCityName(place);

        if (city) {
          this.addressText.nativeElement.value = city;  // Immediately update input field
          this.setAddress.emit({ name: city });
          this.control.setErrors(null);  // Clear any errors
        } else {
          this.setInvalidAddress();
        }
      } else {
        this.setInvalidAddress();
      }
    });
  }

  private extractCityName(place: google.maps.places.PlaceResult): string | null {
    for (const component of place.address_components) {
      if (component.types.includes('locality')) {
        return component.long_name;
      }
      if (component.types.includes('administrative_area_level_2')) {
        return component.long_name; // Fallback to county/district
      }
    }
    return null;
  }

  async focusOut() {
    this.control.markAsTouched();
    const addressValue = this.addressText.nativeElement.value;

    if (!addressValue) {
      this.control.setErrors({ required: true });
      return;
    }

    const place = this.autocomplete.getPlace();
    if (!place || !place.geometry) {
      try {
        const prediction = await this.getPlacePrediction(addressValue);
        if (prediction) {
          const placeDetails = await this.getPlaceDetails(prediction.place_id);
          if (placeDetails) {
            const city = this.extractCityName(placeDetails);
            if (city) {
              this.addressText.nativeElement.value = city;
              this.setAddress.emit({ name: city });
              this.control.setErrors(null);
            } else {
              this.setInvalidAddress();
            }
          } else {
            this.setInvalidAddress();
          }
        } else {
          this.setInvalidAddress();
        }
      } catch (error) {
        this.setInvalidAddress();
      }
    }
  }

  private getPlacePrediction(input: string): Promise<google.maps.places.AutocompletePrediction | null> {
    return new Promise((resolve, reject) => {
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        { input, componentRestrictions: { country: ['it', 'sm', 'va', 'mc', 'ch', 'at', 'si'] }, types: ['(cities)'] },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions?.length) {
            resolve(predictions[0]);
          } else {
            resolve(null);
          }
        }
      );
    });
  }

  private getPlaceDetails(placeId: string): Promise<google.maps.places.PlaceResult | null> {
    return new Promise((resolve, reject) => {
      const placesService = new google.maps.places.PlacesService(this.addressText.nativeElement);
      placesService.getDetails({ placeId }, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private setInvalidAddress() {
    this.control.setErrors({ invalidAddress: true });
    this.setAddress.emit(null);
  }

  // **ControlValueAccessor methods**
  writeValue(value: string): void {
    this.cityName = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
