import { Pipe, PipeTransform } from '@angular/core';
import { APP_CONSTANTS } from '../constants/constants';

@Pipe({
  name: 'serviceName'
})
export class ServiceNamePipe implements PipeTransform {

  transform(serviceType: string | undefined): string {
    if (!serviceType) return '---';

    switch (serviceType.toLowerCase()) {
      case 'fixedprice':
        return APP_CONSTANTS.FIXEDPRICE;
      case 'hourlyrate':
        return APP_CONSTANTS.HOURLYRATE;
      case 'corporate':
        return APP_CONSTANTS.CORPORATE;
      case 'sales':
        return APP_CONSTANTS.SALES;
      default:
        return '';
    }
  }

}
