import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpertService } from 'src/app/services/expert.service';
import { SetAvailabilityTimeComponent } from '../set-availability-time/set-availability-time.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Expert, ExpertProvinces, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { UserService } from 'src/app/services/user.service';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { TeamLeaderService } from 'src/app/services/team-leader.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-expert-availability',
  templateUrl: './expert-availability.component.html',
  styleUrls: ['./expert-availability.component.scss']
})
export class ExpertAvailabilityComponent implements OnInit {

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
  };
  expertId: number|string = null;
  expert: any;
  Events: any[] = [];
  eventsLoaded: boolean = false;
  bulkAddForm: any;
  bulkEditForm: any;
  bulkEditAvailabilityTimeForm: any;
  allDateTimeforEdit: { [key: string]: any[] } = {}; // Initialize to an empty object
  experts: Expert[] = [];
  allDaysCheck: boolean = false;
  monToFriCheck: boolean = false;
  weekendCheck: boolean = false;
  weeklyCheck: boolean = false;
  bulkAddHanldle: boolean = false;
  bulkEditHanldle: boolean = false;
  dateRangeObject: { fromDate: string; toDate: string };
  days: { selected: boolean, startTime: string, endTime: string }[] = [
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' },
    { selected: false, startTime: '', endTime: '' }
  ];
  weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  groups: string[];
  user: any;
  teamLeader: TeamLeader;
  loggedInUserInfo: any;
  loggedInExpert: Expert;
  minDate: string;
  minEndDate: string = '';
  currentYearStart: string = '';
  currentYearEnd: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private expertService: ExpertService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private authStatusService: AuthStatusService,
    private userService: UserService,
    private teamLeaderService: TeamLeaderService,
    private spinnerService: SpinnerService,
  ) {
    this.expertId = this.route.snapshot.paramMap.get('id');
    this.handleAddBulkForm();
    this.handleSearchBulkForm();
    this.handleEditAvailabilityTimeBulkForm();
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }

    this.SetMinDate();
  }

  async SetMinDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.minDate = `${year}-${month}-${day}`;

    this.currentYearStart = `${year}-01-01`;
    this.currentYearEnd = `${year}-12-31`;
  }
  async onStartDateChange() {
    const startDate = this.bulkAddForm.get('startDate')?.value;
    if (startDate) {
      this.minEndDate = startDate; // Set the `min` attribute of the `endDate` input
      // Optional: Reset `endDate` if it’s earlier than `startDate`
      const endDate = this.bulkAddForm.get('endDate')?.value;
      if (endDate && new Date(endDate) < new Date(startDate)) {
        this.bulkAddForm.get('endDate')?.setValue('');
      }
    }
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert') && this.loggedInUserInfo){
        this.loggedInExpert = await this.expertService.getOneWithTimeAvailability(this.loggedInUserInfo['extraId']);
      }
      await this.initialize();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong on this page");
    }
  }

  async initialize() {
    this.user = await this.userService.getMyInformation();
    this.eventsLoaded = false;
    if ((this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader') || this.groups.includes('expert'))) {
      try {
        // this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
        this.experts = (await this.expertService.getAllActiveExpertsWithTimeAvailabilities(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
        if(this.groups.includes('teamleader')){
          this.teamLeader = await this.teamLeaderService.getOneWithTimeAvailability(this.loggedInUserInfo['extraId']);
          if (this.teamLeader?.teamLeaderProvinces.length > 0) {
            this.experts = this.experts?.filter((expert) => {
              return expert?.expertProvinces.some((eProvince) => {
                if(eProvince.province != null){
                  return this.teamLeader.teamLeaderProvinces.some((teamLeaderProvince: TeamLeaderProvinces) => {
                    return eProvince.province.provinceCode.toLowerCase() === teamLeaderProvince.province.provinceCode.toLowerCase()
                  });
                }
                });
            });
          }
        }
        if(this.groups.includes('expert') && this.loggedInExpert && this.loggedInExpert.expertTeamLeaderProvinces && this.loggedInExpert.isTeamLeader){
          if (this.loggedInExpert.expertTeamLeaderProvinces.length > 0) {
            this.experts = this.experts.filter((expert: Expert) => {
              if(expert.expertProvinces.length > 0){
                return expert.expertProvinces.some((eProvince: ExpertProvinces) => {
                  if(eProvince.province != null){
                    return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
                      return eProvince.province.provinceCode.toLowerCase() === expertTeamLeaderProvince.province.provinceCode.toLowerCase()
                    });
                  }
                });
              }
            });
          }
        }
      }
      catch { }
    }
    if(this.expertId){
      try {
        this.expert = await this.expertService.getOneWithTimeAvailability(this.expertId);
        if (this.groups.includes('expert') && !this.loggedInExpert?.isTeamLeader) {
          if(this.expert?.id != this.user?.expert?.id){
            this.router.navigateByUrl("/404", { skipLocationChange: true });
          }
        }
        this.expertId = this.expert ? this.expert.id : null;
      } catch (error) {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    await this.setTimeAvailablities();
  }

  async setTimeAvailablities(){
    let Events = this.expert?.timeAvailabilities.map(event => {
      let startTime = event.startTime.slice(0, -3);
      let endTime = event.endTime.slice(0, -3);
      return {
        title: event?.available ? (startTime + ' - ' + endTime) : "N/A",
        start: event.date,
        color: event?.available ? '#c2b55f' : '#e73e3e',
      };
    });
    this.Events = Events;
    this.eventsLoaded = true;
    if (this.Events && this.Events.length > 0) {
      this.calendarOptions.events = Events;
    } else {
      this.calendarOptions.events = [];
    }
  }

  handleDateClick(arg) {

    let data = this.expert?.timeAvailabilities.filter((data: any) => {
      return data?.date == arg.dateStr
    });

    let info = {
      date: arg.dateStr,
      expert: this.expert,
      userType: "expert",
      // createType: data === undefined ? "create" : "update",
      updateAbleData: data,
    }
    let ref = this.dialog.open(SetAvailabilityTimeComponent, { panelClass: 'expert-time-dialog', width: '50vw', data: { info } } );
    ref.afterClosed().subscribe(async (result) => {
      if (result?.success){
        await this.showSnackBar('Time Scheduled Successfully');
        await this.initialize();
      } else if(result?.status === false) {
        this.showSnackBar('Something went wrong');
      }
    });
  }

  handleAddBulkForm() {
    this.bulkAddForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: [''],
      endTime: [''],
      expert: [this.expertId]
    })
  }

  async onSubmit(event: any){
    if(this.bulkAddForm.valid){

      let availabilityCheck = {
        "alldays": this.allDaysCheck,
        "weekend": this.weekendCheck,
        "monToFri": this.monToFriCheck,
        "weekly": this.weeklyCheck
      };

      if(this.bulkAddHanldle){
        this.bulkAddForm.value.days = this.days;
      } else {
        this.bulkAddForm.value.availabilityCheck = availabilityCheck;
      }
      // console.log('this.bulkAddForm.value', this.bulkAddForm.value);
      // return;
      let promise = this.expertService.setTimeAvailabilityInBulk(this.bulkAddForm.value);
      promise.then(async (res) => {
        if(res){
          this.bulkAddHanldle = false;
          await this.showSnackBar("Time Scheduled Successfully");
          await this.initialize();
          this.handleAddBulkForm();
          this.resetDaysCheckAndTime();
          this.resetCheckBoxes();
        }
      }).catch(async (err) => {
        await this.showSnackBar("Something went wrong");
      });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async handleExpertChange(expertId: any){
    // this.expert = this.experts.find((partner) => {
    //   return partner?.id == expertId;
    // });
    try {
      this.spinnerService.show();
      this.expert = await this.expertService.getOneWithTimeAvailability(this.expertId);
      this.router.navigate(['expert', this.expert?.id, 'availability']);
      this.expertId = this.expert?.id;
      await this.setTimeAvailablities();
      this.handleAddBulkForm();
      this.resetCheckBoxes();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong for getting selected valet details");
    }
  }

  checkOnlyOne(checkbox: string) {
    switch (checkbox) {
      case 'allDays':
        this.monToFriCheck = false;
        this.weekendCheck = false;
        this.weeklyCheck = false;
        break;
      case 'monToFri':
        this.allDaysCheck = false;
        this.weekendCheck = false;
        this.weeklyCheck = false;
        break;
      case 'weekend':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        this.weeklyCheck = false;
        break;
      case 'weekly':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        this.weekendCheck = false;
        break;
      default:
        break;
    }
  }

  resetCheckBoxes(){
    this.allDaysCheck = false;
    this.monToFriCheck = false;
    this.weekendCheck = false;
    this.weeklyCheck = false;
  }

  resetDaysCheckAndTime(){
    this.days = [
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' },
      { selected: false, startTime: '', endTime: '' }
    ];
  }

  async toggleOnlyOne(id){
    if(id === 'bulkAddHanldeCheckbox'){
      this.bulkAddHanldle = !this.bulkAddHanldle;
      this.bulkEditHanldle= false;
    } else if(id === 'bulkEditHanldeCheckbox'){
      this.bulkEditHanldle = !this.bulkEditHanldle;
      this.bulkAddHanldle = false;
    }
  }

  handleSearchBulkForm() {
    this.bulkEditForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      expertId: [this.expertId]
    })
  }
  handleEditAvailabilityTimeBulkForm() {
    this.bulkEditAvailabilityTimeForm = this.fb.group({
      startTime: [''],
      endTime: [''],
      expertId: [this.expertId]
    })
  }

  async onStartDateChangeForEdit() {
    const startDate = this.bulkEditForm.get('startDate')?.value;
    if (startDate) {
      this.minEndDate = startDate; // Set the `min` attribute of the `endDate` input
      // Optional: Reset `endDate` if it’s earlier than `startDate`
      const endDate = this.bulkEditForm.get('endDate')?.value;
      if (endDate && new Date(endDate) < new Date(startDate)) {
        this.bulkEditForm.get('endDate')?.setValue('');
      }
    }
  }

  async onSearch(event: any){
    if(this.bulkEditForm.valid){
      console.log(this.bulkEditForm);

      this.dateRangeObject = {
        fromDate: this.bulkEditForm.value.startDate,
        toDate: this.bulkEditForm.value.endDate
      }
      let promise = await this.expertService.getTimeAvailavialityWithinDateRange(this.dateRangeObject, this.expertId);
      console.log(promise);

      this.allDateTimeforEdit = Object.keys(promise.timeAvailabilities.reduce((acc, current) => {
        const date = current.date;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(current);
        return acc;
      }, {})).sort().reduce((acc, date) => {
        acc[date] = promise.timeAvailabilities.filter((item) => item.date === date);
        return acc;
      }, {});

      console.log(this.allDateTimeforEdit);

    }
  }

  get allDateKeys(): string[] {
    return Object.keys(this.allDateTimeforEdit);
  }

  async removeAvailabilityTime(date, index) {
    const dataArray = this.allDateTimeforEdit[date];
    if (dataArray.length > 1) {
      dataArray.splice(index, 1);
    }
  }

  async onUpdate(event: any){
    // console.log(this.bulkEditAvailabilityTimeForm);
    if(this.bulkEditAvailabilityTimeForm.valid){
      const collectedData = this.allDateKeys.map(date => ({
        expertId: this.expertId,
        date,
        times: this.allDateTimeforEdit[date],
      }));

      $('#updateBlukTime').prop('disabled', true);

      let promise = this.expertService.UpdateTimeAvailabilityInBulk(collectedData);
      promise.then(async (res) => {
        if(res){
          await this.initialize();
          this.handleSearchBulkForm();
          this.resetDaysCheckAndTime();
          this.resetCheckBoxes();
          this.allDateTimeforEdit = {};
          await this.showSnackBar("Time Scheduled Successfully");
        }
      }).catch(async (err) => {
        await this.showSnackBar("Something went wrong");
      });
    }
  }

}
