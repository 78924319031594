import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-comman-modal',
  templateUrl: './comman-modal.component.html',
  styleUrls: ['./comman-modal.component.scss']
})
export class CommanModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CommanModalComponent>
  ) { }

  async ngOnInit() {}

}
