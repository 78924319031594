import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ProvincesService } from 'src/app/services/provinces.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { read, utils, writeFile } from 'xlsx';
import { ProvinceCitiesService } from 'src/app/services/province-cities.service';
import { CitiesListPopUpComponent } from '../cities-list-pop-up/cities-list-pop-up.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-province-cities',
  templateUrl: './province-cities.component.html',
  styleUrls: ['./province-cities.component.scss']
})
export class ProvinceCitiesComponent implements OnInit {

  provinceCities: any[] = [];
  provinceCitiesFilterForm: any;
  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 50;
  defaultOrder = { 'Provinces.province': 'ASC' };
  groups: string[];
  importProvinceCities: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private provincesService: ProvincesService,
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private provinceCitiesService: ProvinceCitiesService,
    public dialog: MatDialog,
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  
  async ngOnInit() {
    try {
      this.manageProvincesCitiesFilterForm();
      await this.loadProvinceCities();
    } catch (error) {
      console.error("ERROR_IN_ngOnInit", error);
    }
  }
  
  manageProvincesCitiesFilterForm(){
    this.provinceCitiesFilterForm = new FormGroup({
      'Provinces.id': new FormControl(''),
      'Provinces.province': new FormControl(''),
      'Provinces.provinceCode': new FormControl(''),
    });
  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.provinceCitiesFilterForm);
    this.filtered = {};

    if (filterForm['Provinces.id'])
      this.filtered['Provinces.id'] = filterForm['Provinces.id'];    
    if (filterForm['Provinces.province'])
      this.filtered['Provinces.province'] = { operator: 'like', values: [`%${filterForm['Provinces.province']}%`] };
    if (filterForm['Provinces.provinceCode'])
      this.filtered['Provinces.provinceCode'] = { operator: 'like', values: [`%${filterForm['Provinces.provinceCode']}%`] };

    this.loadProvinceCities();
  }

  async resetFilter(){
    this.manageProvincesCitiesFilterForm();
    this.filter();
  }


  async loadProvinceCities(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let productCategories = await this.provincesService.getAllProvinceCities(this.page, this.limit, this.order, this.filtered);
    this.provinceCities = productCategories;
    console.log("this.provinceCities", this.provinceCities);
    this.count = productCategories.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadProvinceCities();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  import(){
    let el = document.getElementById("importProvinceCities");
    el.click();
  }

  handleProvinceCitiesImport($event: any) {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log(rows);
          this.importProvinceCities = rows;
          let promiseResult: any;
          promiseResult = this.provinceCitiesService.importProvinceCities(this.importProvinceCities);
          promiseResult.then(async (res: any) => {
            await this.loadProvinceCities();
            this.showToastMessage("Province Cities Updated Successfullly.");
          }).catch((error: any) => {
            this.showToastMessage("Something went wrong");
          });
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  downloadCsvFormat(){
    const headings = [[
      "provinceId",
      "cityName",
    ]];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.book_append_sheet(wb, ws, 'ProvinceCities');
    writeFile(wb, 'provinceCities.xlsx');
  }

  showCities(province: any){
    if(!province.provinceCities.length){
      alert("There no cities available for this province"); return;
    }
    let ref = this.dialog.open(CitiesListPopUpComponent, { width: '80vw', data: { province } });

  }

  async showToastMessage(message: string){
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
