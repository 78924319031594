import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export class ProvinceCities {
    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            province: new FormControl('', Validators.required),
            cities: new FormArray([new FormGroup(Cities.validation())]),
            
        };
        return validator;
    }

    public static readonly validationMessages = {
        'province': [{ type: 'required', message: 'Obbligatorio' }],
        'cities': {
            'cityName': [
                { type: 'required', message: 'Obbligatorio' },
                { type: 'invalidAddress', message: 'Indirizzo non valido' }
            ],
        },
    };

    constructor(
        public id?: number,
        public cities?: string,
        public province?: any,
    ) { }
}

export class Cities {
    public static validation(): { [key: string]: AbstractControl } {
        return {
            cityName: new FormControl('', Validators.required),
        };
    }

    constructor(
        public id?: number,
        public cityName?: string,
    ) { }
}