import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Delivery } from 'src/app/models/delivery.schema';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-approve-timings-admin',
  templateUrl: './approve-timings-admin.component.html',
  styleUrls: ['./approve-timings-admin.component.scss']
})
export class ApproveTimingsAdminComponent implements OnInit {

  delivery: Delivery;
  adminTimingForm: any;
  btnDisabled: boolean = false;
  approvedPickUpFTime: any;
  approvedPickUpTTime: any;
  approvedPickUpFTimeError: boolean = false;
  approvedPickUpTTimeError: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deliveryService: DeliveryService,
    private _snackBar: MatSnackBar
  ) { 
    this.manageAdminTimingForm();
  }

  async ngOnInit() {
    try {
      let deliveryId = this.route.snapshot.paramMap.get('id');
      if(deliveryId){
        this.delivery = await this.deliveryService.getOne(deliveryId);
        this.patch();
      }
    } catch (error) {
      console.log("ERROR", error);
      this.router.navigateByUrl("/404", { skipLocationChange: true });
    }
  }

  manageAdminTimingForm(){
    this.adminTimingForm = new FormGroup({
      'pickUpTime': new FormControl(''),
      'approvedTimings': new FormControl(''),
    });
  }

  patch(){
    this.adminTimingForm.patchValue({
      pickUpTime: this.delivery.pickUpTime,
      approvedTimings: this.delivery.approvedTimings,
    });
  }

  handlePickUpFlexbleTime(){
    this.approvedPickUpFTimeError = !this.approvedPickUpFTime;
    this.approvedPickUpTTimeError = !this.approvedPickUpTTime;
    if(this.approvedPickUpFTime && this.approvedPickUpTTime){
      let time = this.approvedPickUpFTime + "-" + this.approvedPickUpTTime;
      this.adminTimingForm.controls.approvedTimings?.setValue(time);
    }
  }

  async onSubmit(){
    try {
      if(this.adminTimingForm.valid){
        let promiseResult = this.deliveryService.approveTimings(this.delivery.id, this.adminTimingForm.value);
        if(promiseResult){
          await this.showSnackBar('Pick Time has been approved');
          this.router.navigate(['consegne', this.delivery.id, 'edit']);
        } else {
          this.showSnackBar('Something went wrong');
        }
      }
    } catch (error) {
      this.showSnackBar('Something went wrong');
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}
