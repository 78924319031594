import { async } from '@angular/core/testing';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Delivery } from 'src/app/models/delivery.schema';
import { Expert } from 'src/app/models/expert.schema';
import { Partner } from 'src/app/models/partner.schema';
import { Product } from 'src/app/models/product.schema';
import { ServiceModel } from 'src/app/models/service.schems';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ExpertService } from 'src/app/services/expert.service';
import { PartnerService } from 'src/app/services/partner.service';
import { ProductService } from 'src/app/services/product.service';
import { Service } from 'src/app/services/service.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-partial-update-delivery',
  templateUrl: './partial-update-delivery.component.html',
  styleUrls: ['./partial-update-delivery.component.scss']
})
export class PartialUpdateDeliveryComponent implements OnInit {

  delivery: Delivery;
  partialUpdateDeliveryForm: any;
  experts: Expert[] = [];
  partners: Partner[] = [];
  services: ServiceModel[] = [];
  selectedService: any;
  products: Product[] = [];
  disableBtn: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { updatedDelivery: Delivery },
    private dialogRef: MatDialogRef<PartialUpdateDeliveryComponent>,
    private deliveryService: DeliveryService,
    private _snackBar: MatSnackBar,
    private service: Service,
    private expertService: ExpertService,
    private partnerService: PartnerService,
    private productService: ProductService,
    private spinnerService: SpinnerService
  ) { 
    this.delivery = this.data.updatedDelivery;
    this.partialUpdateDeliveryForm = new FormGroup(Delivery.validation());
  }

  async ngOnInit(){
    try {
      this.disableBtn = true;
      this.spinnerService.show();
      this.partners = (await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
      this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
      this.services = await this.service.getAllServices();
      this.products = (await this.productService.getAllActiveProducts(undefined, undefined, { 'Product.name': 'ASC' })).products;
      this.patch();
      this.spinnerService.hide();
      this.disableBtn = false;
    }
    catch { }
  }

  async patch(){
    [ 'billable', 'payable', 'additionalPrice', 'valetAdditionalPrice' ].forEach((param) => {
      this.partialUpdateDeliveryForm.controls[param].setValue(this.delivery[param]);
    })
  }

  handleBillable(event: any){
    this.partialUpdateDeliveryForm.controls.bilable?.setValue(event.target.checked);
    this.delivery.billable = event.target.checked;
    if(!this.partialUpdateDeliveryForm.controls.billable.value){    // Delivery is not billable
      this.partialUpdateDeliveryForm.controls.price.setValue(0);
      this.partialUpdateDeliveryForm.controls.additionalPrice.setValue(0);
      this.delivery.price = '0';
      this.delivery.additionalPrice = '0';
    } else {
      this.setPartnerPrices();
    }
  }

  async setPartnerPrices(){
    let assignedPartner = this.partners.find((partner) => {
      return partner?.id === this.delivery.partner?.id;
    })
    this.selectedService = assignedPartner.partnerServices.find((item) => {
      return item?.service?.id == this.delivery?.service;
    });
    if(this.selectedService?.service?.pricingModel === 'fixedprice'){
      this.partialUpdateDeliveryForm.controls.price.setValue(this.selectedService?.price);
      this.delivery.price = this.selectedService?.price;
      let pickUpAddressResult = await this.getLatLngByAddress(this.delivery.pickUpAddress ? this.delivery.pickUpAddress : '');
      let deliveryAddressResult = await this.getLatLngByAddress(this.delivery.address ? this.delivery.address : '');
      let pickUpAddLat = pickUpAddressResult[0].geometry?.location?.lat() || '';
      let pickUpAddLng = pickUpAddressResult[0].geometry?.location?.lng() || '';
      let deliveryAddressLat = deliveryAddressResult[0].geometry?.location?.lat() || '';
      let deliveryAddressLng = deliveryAddressResult[0].geometry?.location?.lng() || '';
      let fixedAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
      let selectedAddressLatLng = new google.maps.LatLng(deliveryAddressLat, deliveryAddressLng)
      const distanceMatrixService = new google.maps.DistanceMatrixService();
      distanceMatrixService.getDistanceMatrix({
        origins: [fixedAddressLatLng],
        destinations: [selectedAddressLatLng],
        travelMode: google.maps.TravelMode.DRIVING,
      }, (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          const distance = response.rows[0].elements[0].distance?.value;
          const distanceInKm = distance / 1000;
          this.partialUpdateDeliveryForm.controls.distance.setValue(distanceInKm.toFixed(2));
          this.delivery.distance = distanceInKm.toFixed(2);
          let price: any = 0;
          if(distanceInKm > assignedPartner?.kmIncluded && assignedPartner?.kmIncluded){
            let extraKm = (distanceInKm - assignedPartner?.kmIncluded);
            price = parseFloat(this.selectedService?.price) + (extraKm * this.selectedService?.extraKmPrice);
          } else {
            price = this.selectedService?.price;
          }
          this.partialUpdateDeliveryForm.controls.price?.setValue(parseFloat(price)?.toFixed(2));
          this.partialUpdateDeliveryForm.controls.distance?.setValue(distance.toFixed(2));
          this.delivery.price = parseFloat(price)?.toFixed(2);
          this.delivery.distance = distance.toFixed(2);
        } else {
          console.error('Error calculating distance:', status);
        }
      });
    }
    if(this.selectedService?.service?.pricingModel === 'hourlyrate'){
      const startTime = new Date('1970-01-01T' + this.delivery?.startTime);
      const endTime = new Date('1970-01-01T' + this.delivery?.endTime);
      const timeDiff = endTime.getTime() - startTime.getTime();
      const hours = Math.floor(timeDiff / 3600000);
      const minutes = Math.floor((timeDiff % 3600000) / 60000);
      const totalTime = hours + minutes / 60;
      let totalDeliveryTime = totalTime.toFixed(2);
      let price = hours !== 0 ? Number(this.selectedService?.price) * Number(totalDeliveryTime) : Number(this.selectedService?.price)
      this.partialUpdateDeliveryForm.controls.price.setValue(price?.toFixed(2));
      this.partialUpdateDeliveryForm.controls.hours.setValue(totalDeliveryTime);
      this.delivery.price = price?.toFixed(2);
      this.delivery.hours = Number(totalDeliveryTime);
    }
    if(this.selectedService?.service?.pricingModel === 'sales'){
      let selectedProducts = this.delivery?.deliveryProducts;
      let TotalSelectedProPrice = selectedProducts?.map(item => {
        const product = this.products.find(p => p.id === item.product.id);
        const quantity = Number(item?.quantity);
        const price = Number(product?.price);
        if (!isNaN(price) && !isNaN(quantity)) {
          return price * quantity;
        } else {
          return 0;
        }
      }).reduce((acc, curr) => acc + curr, 0);
      
      this.partialUpdateDeliveryForm.controls.price?.setValue(TotalSelectedProPrice ? (this.selectedService?.price * TotalSelectedProPrice)/100 : 0);  
      this.partialUpdateDeliveryForm.controls.productValue?.setValue(TotalSelectedProPrice ? TotalSelectedProPrice : 0); 
      this.delivery.price = TotalSelectedProPrice ? (this.selectedService?.price * TotalSelectedProPrice)/100 : 0;
      this.delivery.productValue = TotalSelectedProPrice ? TotalSelectedProPrice : 0;
    }
  }

  async handlePayable(event: any){
    this.partialUpdateDeliveryForm.controls.payable?.setValue(event.target.checked);
    this.delivery.payable = event.target.checked;
    if(!this.partialUpdateDeliveryForm.controls.payable.value){    // Delivery is not payable
      this.partialUpdateDeliveryForm.controls.expertSalary.setValue(0);
      this.partialUpdateDeliveryForm.controls.valetAdditionalPrice.setValue(0);
      this.delivery.expertSalary = '0';
      this.delivery.valetAdditionalPrice = '0';
    } else{
      await this.setExpertPrices();
    }
  }

  async setExpertPrices(){

    let assignedPartner = this.partners.find((partner) => {
      return partner?.id === this.delivery.partner?.id;
    })
    let selectedPartnerService = assignedPartner.partnerServices.find((item) => {
      return item?.service?.id == this.delivery?.service;
    });

    let assignedExpert = this.experts.find((expert) => {
      return expert?.id === this.delivery.expert?.id;
    });

    let selectedExpertService = assignedExpert.expertServices.find(expertService => expertService?.id == this.delivery.expertServiceId);
    if(["fixedprice", "sales"].includes(selectedPartnerService?.service?.pricingModel?.toLowerCase())){ 
      if(selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
        let pickUpAddressResult: any[];
        if(this.delivery.pickUpAddress){
          pickUpAddressResult = await this.getLatLngByAddress(this.delivery.pickUpAddress ? this.delivery.pickUpAddress : '');
        }
  
        if(pickUpAddressResult && pickUpAddressResult.length > 0){
          let delveryAddLat: any = this.delivery.latitude || '';
          let delveryAddLng: any = this.delivery.longitude || '';
          let pickUpAddLat = pickUpAddressResult[0].geometry?.location?.lat() || '';
          let pickUpAddLng = pickUpAddressResult[0].geometry?.location?.lng() || '';
          const pickUpAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
          const deliveryAddressLatLng = new google.maps.LatLng(delveryAddLat, delveryAddLng);
          const distanceMatrixService = new google.maps.DistanceMatrixService();
          distanceMatrixService.getDistanceMatrix({
            origins: [pickUpAddressLatLng],
            destinations: [deliveryAddressLatLng],
            travelMode: google.maps.TravelMode.DRIVING,
          }, (response, status) => {
            if (status === google.maps.DistanceMatrixStatus.OK) {
              const distance = response.rows[0].elements[0].distance?.value;
              const distanceInKm = distance / 1000;
              let valetSalary: any = 0;
              if(selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
                if(assignedExpert?.minimumKmIncluded && distanceInKm > assignedExpert?.minimumKmIncluded){
                  let extraKm = (distanceInKm - assignedExpert?.minimumKmIncluded);
                  valetSalary = (selectedExpertService?.salary) + (extraKm * Number(selectedExpertService?.minimumKmPrice));
                } else {
                  valetSalary = selectedExpertService?.salary;
                }
              }
              this.partialUpdateDeliveryForm.controls.expertSalary?.setValue(parseFloat(valetSalary)?.toFixed(2));
              this.delivery.expertSalary = parseFloat(valetSalary)?.toFixed(2);
            } else {
              console.error('Error calculating distance:', status);
            }
          });
        }
      }
    }
    if(selectedExpertService?.service?.serviceType?.toLowerCase() === 'hourlyratesalary'){
      const startTime = new Date('1970-01-01T' + this.delivery.valetStartTime);
      const endTime = new Date('1970-01-01T' + this.delivery.valetEndTime);
      const timeDiff = endTime.getTime() - startTime.getTime();
      const hours = Math.floor(timeDiff / 3600000);
      const minutes = Math.floor((timeDiff % 3600000) / 60000);
      const totalTime = hours + minutes / 60;
      let totalValetTimeDifference: any = totalTime.toFixed(2);
      let valetSalary = (totalValetTimeDifference * selectedExpertService?.salary)?.toFixed(2);
      this.partialUpdateDeliveryForm.controls.expertSalary.setValue(valetSalary);
      this.delivery.expertSalary = valetSalary;
    }
  }

  async getLatLngByAddress(address: string){
    const geocoder = new google.maps.Geocoder();
    const results = await new Promise<any>((resolve, reject) => {
      geocoder.geocode({ 'address': address }, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
    return results;
  }

  async onSubmit(){
    if (this.delivery['id']) {
      this.delivery.additionalPrice = this.partialUpdateDeliveryForm.controls.additionalPrice.value;
      this.delivery.valetAdditionalPrice = this.partialUpdateDeliveryForm.controls.valetAdditionalPrice.value;
      let delivery: Delivery = (new FormGroupToObject()).transform(this.partialUpdateDeliveryForm);
      let promiseResult = this.deliveryService.partialUpdate(this.delivery['id'], this.delivery);
      promiseResult.then((res) => {
        this.delivery = res;
        this.dialogRef.close({ delivery: this.delivery });
      }).catch((error) => {
        this.dialogRef.close({ delivery: null });
      })
    }
  }

  showSanckBar(message: string){
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
